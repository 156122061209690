import type { RegistryComponentProps } from '@boost-sd/components-registry/react-component';
import { registryComponent } from '@boost-sd/components-registry/registry';
import SearchResultPagination from '@components/SearchResultPagination';
import SearchResultPanelContent from '@components/SearchResultPanelContent';
import SearchResultToolbar from '@components/SearchResultToolbar';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import { useSearchSettings, useSearchState } from '@providers/SearchProvider';
import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import { useEffect, useState } from 'react';

export type SearchResultElement = 'product' | 'collection' | 'page';

export type SearchResultPanelsProps = RegistryComponentProps<{
  productTab: React.ReactElement;
  orders?: SearchResultElement[];
}>;

const SearchResultPanels = ({
  productTab,
  orders = ['product', 'collection', 'page'],
}: SearchResultPanelsProps) => {
  const { totalProducts } = useAdditionalElementState();
  const {
    settings: {
      searchPanelBlocks: { collections: collectionSettings, pages: pagesSetting },
    },
  } = useSearchSettings();

  const {
    pagination: { number: numberSettings, button: buttonSettings },
  } = useAdditionalElementThemeSettings();

  const {
    getSearchResultPanelData,
    searchResultPanelCollections,
    searchResultPanelPages,
    currentSearchPanelCollectionsPage,
    currentSearchPanelPagesPage,
    initSearchResultPanelData,
  } = useSearchState();

  const renderOrders = orders.filter((panel) => {
    if (
      (panel === 'collection' && !collectionSettings.active) ||
      (panel === 'page' && !pagesSetting.active)
    ) {
      return false;
    }

    return panel;
  });

  const [searchResultSelectedPanel, setSearchResultSelectedPanel] = useState<SearchResultElement>(
    renderOrders[0]
  );

  useEffect(() => {
    initSearchResultPanelData('collection');
    initSearchResultPanelData('page');
  }, []);

  const renderSearchResultPanel = () => {
    switch (searchResultSelectedPanel) {
      case 'collection': {
        const totalPages = Math.ceil(
          searchResultPanelCollections.total_collection / Number(collectionSettings.pageSize)
        );
        const onChangePage = (page: number) => getSearchResultPanelData(page, 'collection');

        return (
          <SearchResultPanelContent
            content={searchResultPanelCollections.collections}
            showImage={collectionSettings.displayImage}
            showDescription={collectionSettings.displayDescription}
            type='collection'
            pagination={
              <SearchResultPagination
                totalPages={totalPages}
                onChange={onChangePage}
                currentPage={currentSearchPanelCollectionsPage}
                numberSettings={numberSettings}
                buttonSettings={buttonSettings}
              />
            }
          />
        );
      }

      case 'page': {
        const totalPages = Math.ceil(
          searchResultPanelPages.total_page / Number(pagesSetting.pageSize)
        );
        const onChangePage = (page: number) => getSearchResultPanelData(page, 'page');

        return (
          <SearchResultPanelContent
            content={searchResultPanelPages.pages}
            showImage={pagesSetting.displayImage}
            showDescription={pagesSetting.displayExcerpt}
            type='page'
            pagination={
              <SearchResultPagination
                totalPages={totalPages}
                onChange={onChangePage}
                currentPage={currentSearchPanelPagesPage}
                numberSettings={numberSettings}
                buttonSettings={buttonSettings}
              />
            }
          />
        );
      }

      default:
        return productTab;
    }
  };

  return (
    <>
      <SearchResultToolbar
        totalProducts={totalProducts}
        totalCollections={searchResultPanelCollections.total_collection}
        totalPages={searchResultPanelPages.total_page}
        orders={renderOrders}
        searchResultSelectedPanel={searchResultSelectedPanel}
        setSearchResultSelectedPanel={setSearchResultSelectedPanel}
      />

      {renderSearchResultPanel()}
    </>
  );
};

export default registryComponent('SearchResultPanels', SearchResultPanels);
