import type { PropsWithChildren } from 'react';

import type { ProductListStateProviderProps } from './ProductListState';
import { ProductListStateProvider } from './ProductListState';

export type ProductListProviderProps = PropsWithChildren<ProductListStateProviderProps>;

export const ProductListProvider = ({ children, productsStore }: ProductListProviderProps) => {
  return (
    <ProductListStateProvider productsStore={productsStore}>{children}</ProductListStateProvider>
  );
};

export { useProductListState } from './ProductListState';
