import type { AdditionalElementStateProviderProps } from '../types';
import { AdditionalElementStateProvider } from './AdditionalElementState';

export const AdditionalElementProvider = ({
  children,
  additionalElementStore,
}: AdditionalElementStateProviderProps) => {
  return (
    <AdditionalElementStateProvider additionalElementStore={additionalElementStore}>
      {children}
    </AdditionalElementStateProvider>
  );
};

export * from './AdditionalElementState';
