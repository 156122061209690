import './PlaceHolderItem.scss';

import type { ModifiersType } from '@utils';
import { createClsNameMap, mergeModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  modifiers: [
    'width1',
    'width2',
    'width3',
    'width4',
    'in-collection',
    'product-count',
    'sorting',
    'text-toggle',
    'limit',
  ],
})('placeholder-item');

export type PlaceholderProps = {
  modifiers?: ModifiersType<typeof clsNameMap>;
};

const PlaceholderItem = ({ modifiers }: PlaceholderProps) => {
  return <span className={mergeModifiers(clsNameMap, [modifiers])}></span>;
};

export default PlaceholderItem;
