import type { Dict } from '@types';
import { isBadUrl } from '@utils';

const RECENT_SEARCHES_KEY = 'boostPFSRecentSearches';

const getOnClickRecentSearches = (isMax?: number) => {
  let recentSearches;
  try {
    const value = localStorage.getItem(RECENT_SEARCHES_KEY);
    recentSearches = value && JSON.parse(value);
  } catch {
    recentSearches = [];
  }

  if (!Array.isArray(recentSearches)) {
    recentSearches = [];
  } else {
    const maxRecentSearch = Number(isMax) || 10;
    if (maxRecentSearch > 0) {
      recentSearches = recentSearches.slice(0, maxRecentSearch);
    }
  }
  const filterRecentSearches: Array<Dict> = [];
  recentSearches.forEach((recentSearch) => {
    if (recentSearch.term !== '' && !isBadUrl(recentSearch.term)) {
      filterRecentSearches.push(recentSearch);
    }
  });

  // Set storage
  try {
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(filterRecentSearches));
  } catch {}

  return filterRecentSearches;
};

const setOnClickRecentSearches = (item: string, extraParam?: string, scope?: string) => {
  // Get current recent searches
  if (typeof item != 'string' || item.trim() === '' || isBadUrl(item)) return;
  item = item.trim();
  let recentSearches = getOnClickRecentSearches();
  const maxInLocalStorage = 10;
  // Add or move the new search to top
  const index = recentSearches.findIndex(
    (recentSearch) => recentSearch.title === item && recentSearch.extraParam === extraParam
  );
  if (index >= 0) {
    recentSearches.splice(index, 1);
    recentSearches.unshift({ title: item, extraParam, scope });
  } else {
    recentSearches.unshift({ title: item, extraParam, scope });
    recentSearches = recentSearches.slice(0, maxInLocalStorage);
  }
  // Set storage
  try {
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches));
  } catch {}
};

export { getOnClickRecentSearches, setOnClickRecentSearches };
