import { registryModule } from '@boost-sd/components-registry/registry';
import { enableProductFilterFallback } from '@boost-sd/fallback-theme';
import { DEFAULT_LOCALE } from '@constants/locale';
import useGeneralSettings from '@hooks/useGeneralSettings';
import type { Dict } from '@types';
import {
  addParamsLocale,
  buildInfoWithRequestId,
  buildRequestIdForProducts,
  getBoostPFSSectionId,
  getCurrentPage,
  isSearchPage,
  shouldKeepScrollRightAfterFiltering,
  stripHtml,
} from '@utils';

const { suggestionUrl, productsUrl, searchUrl } = window.boostSDAppConfig?.api || {};

const PATH = suggestionUrl || 'https://services.mybcapps.com/bc-sf-filter/search/suggest';
const PRODUCT_PATH = productsUrl || 'https://services.mybcapps.com/bc-sf-filter/search/products';
const IN_COLLECTION_SEARCH_PATH = searchUrl || 'https://services.mybcapps.com/bc-sf-filter/search';

const HTTP_STATUS_NEED_FALLBACK = [404, 403, 500];

type ExtraParams = {
  page?: number;
  limit?: number;
  sort?: string;
  subRoute?: '/collections' | '/pages';
  customizeSearchParams?: Dict;
};

export const SearchAPI = registryModule('SearchAPI', {
  get: async (searchParams: string, searchSettings: Dict, customizeSearchParams?: Dict) => {
    const {
      suggestionDymLimit,
      enableDefaultResult,
      enableFuzzySearch,
      productAvailable,
      suggestionMode,
      skipFields,
      reduceMinMatch,
      fullMinMatch,
      enablePlusCharacterSearch,
      suggestionBlocks,
      showVariantsAsProduct,
      locale,
      translateSearchParams,
    } = searchSettings;

    const {
      generalSettings: { termKey },
    } = useGeneralSettings();

    let defaultParams: Dict = {
      t: Date.now(),
      shop: window.Shopify?.shop,
      locale: locale ?? DEFAULT_LOCALE,
      [termKey]: stripHtml(translateSearchParams) ?? stripHtml(searchParams),
      sid: getBoostPFSSectionId(),
      re_run_if_typo: true, // field did you mean improve
      event_type: 'suggest',
      pg: getCurrentPage(),
    };

    defaultParams = addParamsLocale(defaultParams);

    // limit
    if (Array.isArray(suggestionBlocks)) {
      // { type: 'suggestions', label: 'Suggestions', status: 'active', number: 3 },
      suggestionBlocks.forEach((block) => {
        const id = block['type'].slice(0, -1);
        defaultParams[`${id}_limit`] = block['number'];
      });
    }

    if (suggestionMode) defaultParams.suggestionMode = suggestionMode;
    if (skipFields && skipFields.length > 0) defaultParams.skipFields = skipFields;
    if (suggestionDymLimit) defaultParams['dym_limit'] = suggestionDymLimit;
    if (enableFuzzySearch !== true) defaultParams.fuzzy = enableFuzzySearch;
    if (reduceMinMatch !== false) defaultParams.reduce_min_match = reduceMinMatch;
    if (fullMinMatch) defaultParams.full_min_match = true;
    if (enablePlusCharacterSearch) defaultParams.enable_plus_character_search = true;
    if (!enableDefaultResult) defaultParams.enable_default_result = false;
    // Show variants as products
    if (showVariantsAsProduct) defaultParams.variants_as_products = true;
    // Get product/variant available or not field. ! of Fields Out of stock products in instant search
    if (productAvailable) defaultParams.product_available = true;

    // allow customize search params with any params
    if (customizeSearchParams && Object.keys(customizeSearchParams)?.length > 0) {
      defaultParams = {
        ...defaultParams,
        ...customizeSearchParams,
      };
    }

    const params = new URLSearchParams(defaultParams);
    const response = await fetch(`${PATH}?${params}`, {
      method: 'GET',
    });

    // save requestId
    const requestId = response.headers.get('X-Request-ID') || '';
    const dataResponse = await response.json();
    try {
      buildRequestIdForProducts(requestId, dataResponse.products, 'suggest', true);
      buildInfoWithRequestId(requestId, {
        query_string: `${params?.toString()}`,
        searchTerm: defaultParams?.q || '',
        action: 'suggest',
      });
    } catch {}

    return dataResponse;
  },

  getProductsByIds: async (ids: Array<number | string>, searchSettings: Dict) => {
    const { productAvailable } = searchSettings;

    let defaultParams: Dict = {
      shop: window.Shopify?.shop,
      locale: DEFAULT_LOCALE,
      event_type: 'init',
    };

    if (productAvailable) {
      defaultParams.product_available = productAvailable;
    }

    defaultParams = addParamsLocale(defaultParams);

    const params = new URLSearchParams(defaultParams);

    let optionParams = '';
    ids?.forEach((id: number | string) => {
      optionParams += `&ids=${id}`;
    });

    const response = await fetch(`${PRODUCT_PATH}?${params}${optionParams}`, { method: 'GET' });
    // save requestId
    const requestId = response.headers.get('X-Request-ID') || '';
    const dataResponse = await response.json();
    // NOTE dataResponse = products
    try {
      buildRequestIdForProducts(requestId, dataResponse, 'suggest', true);
      buildInfoWithRequestId(requestId, {
        query_string: `${params?.toString()}`,
        action: 'suggest',
      });
    } catch {}
    return dataResponse;
  },

  getProductByHandle: async (
    handle: Array<string | number>,
    searchQuery: string,
    searchSettings: { productAvailable: boolean }
  ) => {
    const { productAvailable } = searchSettings;
    const params: Dict = {
      shop: window.Shopify?.shop,
      handle,
      locale: DEFAULT_LOCALE,
      event_type: 'init',
    };

    if (productAvailable) {
      params.product_available = productAvailable;
    }

    const response = await fetch(`${PRODUCT_PATH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    // save requestId
    const requestId = response.headers.get('X-Request-ID') || '';
    const dataResponse = await response.json();
    // NOTE dataResponse = products
    try {
      buildRequestIdForProducts(requestId, dataResponse, 'suggest', true);
      buildInfoWithRequestId(requestId, {
        query_string: `q=${searchQuery}`,
        action: 'suggest',
      });
    } catch {}
    return dataResponse;
  },

  searchInCollection: async (searchParams: Dict, extraParams: ExtraParams) => {
    let defaultParams: Dict = {
      t: Date.now(),
      shop: window.Shopify?.shop,
      page: extraParams.page ?? 0,
      limit: extraParams.limit ?? 23,
      sort: extraParams.sort ?? 'best-selling',
      locale: DEFAULT_LOCALE,
      build_filter_tree: true,
      sid: getBoostPFSSectionId(),
      pg: getCurrentPage(),
    };

    const {
      generalSettings: { disabledScrollToTopAfterFilter },
    } = useGeneralSettings();

    if (!searchParams?.event_type) searchParams.event_type = 'init';

    defaultParams = addParamsLocale(defaultParams);

    if (
      extraParams.customizeSearchParams &&
      Object.keys(extraParams.customizeSearchParams)?.length > 0
    ) {
      searchParams = {
        ...searchParams,
        ...extraParams.customizeSearchParams,
      };
    }

    // Cover case collection multi level has multi tag on search page
    if (isSearchPage()) {
      Object.keys(searchParams).forEach((filterKey) => {
        if (filterKey.endsWith('ct_collection')) {
          searchParams.tag = searchParams[filterKey];
          delete searchParams[filterKey];
        }
      });
    }

    const params = new URLSearchParams(defaultParams);

    if (searchParams) {
      Object.keys(searchParams).forEach((optionId) => {
        const values = searchParams[optionId];
        if (values == null) return;
        if (Array.isArray(values)) {
          values.forEach((value) => {
            // Convert value to TRUE/FALSE for stock status filter option
            if (optionId.indexOf('pf_st_') === 0) {
              value = value === 'in-stock' ? true : false;
            }
            params.append(`${optionId}[]`, value);
          });
        } else {
          params.append(optionId, values);
        }
      });
    }

    const subRoute = extraParams.subRoute || '';

    const response = await fetch(`${IN_COLLECTION_SEARCH_PATH}${subRoute}?${params}`, {
      method: 'GET',
    }).then((res) => {
      const { status } = res;
      if (HTTP_STATUS_NEED_FALLBACK.includes(status)) {
        enableProductFilterFallback();
      }

      return res;
    });

    // save requestId
    const requestId = response.headers.get('X-Request-ID') || '';
    const dataResponse = await response.json();

    try {
      buildRequestIdForProducts(requestId, dataResponse.products, 'search', false);
      buildInfoWithRequestId(requestId, {
        query_string: `${params?.toString()}`,
        searchTerm: searchParams?.q || '',
        action: 'search',
      });
    } catch {}

    // update number product when search api of our app
    if (isSearchPage() && dataResponse?.total_product) {
      const title = document.querySelector('title');
      const numberMatch = title?.innerHTML?.match(/\d+/);

      if (numberMatch && numberMatch[0] && title?.innerHTML) {
        title.innerHTML = title.innerHTML?.replace(numberMatch[0], dataResponse?.total_product);
      }
    }

    // only scroll when page === 0 or 1
    if ([0, 1].includes(extraParams.page || 0) && !disabledScrollToTopAfterFilter)
      shouldKeepScrollRightAfterFiltering();

    return dataResponse;
  },

  redirects: async (terms: Array<string>) => {
    const defaultParams: Dict = {
      t: Date.now(),
      shop: window.Shopify?.shop,
      locale: DEFAULT_LOCALE,
      build_filter_tree: true,
      sid: getBoostPFSSectionId(),
      pg: getCurrentPage(),
    };

    const params = new URLSearchParams(defaultParams);

    terms.forEach((term) => {
      params.append('terms[]', term);
    });

    const response = await fetch(`${IN_COLLECTION_SEARCH_PATH}/redirects?${params}`, {
      method: 'GET',
    });

    return await response.json();
  },
});
