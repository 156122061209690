import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import type { AdditionalElementThemeSettings } from '@providers/ThemeProvider/types';
import { useMemo } from 'react';

export type ToolbarSortingListPropsMapSettings =
  AdditionalElementThemeSettings['toolbar']['sortingList'];

export const useConnectToolbarSortingList = () => {
  const additionalElementSettings = useAdditionalElementThemeSettings();
  const { sortingList } = additionalElementSettings.toolbar;

  const props: ToolbarSortingListPropsMapSettings = useMemo(() => {
    return sortingList;
  }, [sortingList]);

  return {
    props,
  };
};
