import './HeaderMain1.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ProductCountProps } from '@components/ProductCount';
import { createClsNameMap, mergeModifiers } from '@utils';

import type { Positions } from '@/widgets/CollectionHeader';

import type { HeaderDescriptionProps } from '../HeaderDescription';
import type { HeaderImageProps } from '../HeaderImage';
import type { HeaderTitleProps } from '../HeaderTitle';

export const clsNameMap = createClsNameMap({
  elements: {
    container: createClsNameMap({
      modifiers: ['middle-left', 'middle-right'],
    }),
    content: createClsNameMap(),
    image: createClsNameMap(),
  },
})('header-main-1');

export type CollectionHeaderSetting = {
  backgroundColor?: string;
};

export type HeaderMain1Props = {
  elements?: {
    productCount?: React.ReactElement<ProductCountProps>;
    headerTitle?: React.ReactElement<HeaderTitleProps>;
    headerDescription?: React.ReactElement<HeaderDescriptionProps>;
    headerImage?: React.ReactElement<HeaderImageProps>;
  };
  contentPosition?: Positions;
  backgroundColor?: string;
};

const HeaderMain1 = ({ elements, backgroundColor, contentPosition }: HeaderMain1Props) => {
  return (
    <div className={clsNameMap.root()} style={{ backgroundColor }}>
      <div
        className={mergeModifiers(clsNameMap.container, [
          {
            [contentPosition as string]: true,
          },
        ])}
      >
        <div className={clsNameMap.elm('content')}>
          {elements?.headerTitle}
          {elements?.productCount}
          {elements?.headerDescription}
        </div>

        {elements?.headerImage}
      </div>
    </div>
  );
};

export default registryComponent('HeaderMain1', HeaderMain1);
