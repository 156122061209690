import './NoSearchResultPage.scss';

import { SearchAPI } from '@apis/search';
import { getQueryParamByKey } from '@boost-sd/core-js/history';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import type { ProductItemProps } from '@components/ProductItem';
import Slider from '@components/Slider';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import { optionIdsOfRefineBy, useFilterState } from '@providers/FilterProvider';
import type { ProductListItem } from '@providers/ProductListProvider';
import { useSearchSettings, useSearchState } from '@providers/SearchProvider';
import {
  createClsNameMap,
  generateSearchPageHref,
  getTermValueFromUrl,
  isMobileWidth,
  stripHtml,
} from '@utils';
import classnames from '@utils/classnames';
import { useEffect, useState } from 'react';

import ProductItem from '../ProductItem';

export const clsNameMap = createClsNameMap({
  elements: {
    empty: createClsNameMap(),
    'search-result': createClsNameMap(),
    title: createClsNameMap(),
    'box-heading': createClsNameMap(),
    heading: createClsNameMap(),
    'search-tip': createClsNameMap(),
    'search-term-suggestion': createClsNameMap(),
    'suggestion-list': createClsNameMap(),
    'suggestion-item': createClsNameMap(),
    'popular-products': createClsNameMap(),
    active: createClsNameMap(),
  },
})('no-search-result-page');

type NoSearchResultPageProps = {
  onClick?: () => unknown;
  onRender?: (props: ProductItemProps) => React.JSX.Element;
};

const NoSearchResultPage = (props: NoSearchResultPageProps) => {
  const { t } = useTranslation();

  const {
    settings: {
      searchPanelBlocks: {
        searchEmptyResultMessages,
        searchTips,
        searchTermSuggestions,
        mostPopularProducts,
      },
      productAvailable,
    },
  } = useSearchSettings();

  const {
    generalSettings: { termKey },
  } = useGeneralSettings();

  const { isLoading } = useSearchState();
  const [isLoadingMostProduct, setIsLoadingMostProduct] = useState(true);
  const [mostProducts, setMostProducts] = useState<ProductListItem[]>([]);

  const {
    filterParams,
    sharedAdditionalElementData: { loadingAdditional },
  } = useFilterState();

  useEffect(() => {
    if (mostPopularProducts.active) {
      SearchAPI.getProductByHandle(
        mostPopularProducts.productList,
        getQueryParamByKey(termKey) as string,
        { productAvailable }
      ).then((res: Array<ProductListItem>) => {
        setMostProducts(res);
      });
    }

    setIsLoadingMostProduct(false);
  }, [loadingAdditional]);

  if (isLoading || isLoadingMostProduct || loadingAdditional) return <></>;

  const isExistedFilterParams =
    optionIdsOfRefineBy(filterParams, 'pf_').length > 0 ||
    getQueryParamByKey('collection') ||
    getQueryParamByKey('tag');

  // Filter in search page and no product result
  if (isExistedFilterParams) {
    document.body.classList.remove(clsNameMap.elm('active'));
    return <div className={classnames('g-text-italic-center')}>{t('error.noFilterResult')}</div>;
  } else {
    document.body.classList.add(clsNameMap.elm('active'));
  }

  // Popular products block on search no products result
  const renderPopularProducts = () => {
    if (mostPopularProducts.active && mostProducts && mostProducts?.length > 0) {
      let slidesToShow = isMobileWidth() ? 2 : 4;
      if (mostProducts.length < slidesToShow) {
        slidesToShow = mostProducts.length;
      }

      return (
        <div className={clsNameMap.elm('popular-products')}>
          <div className={clsNameMap.elm('box-heading')}>
            <h2 className={clsNameMap.elm('heading')}>{t('search.noSearchResultProductsLabel')}</h2>
          </div>
          <Slider
            slidesToShow={slidesToShow}
            enableButtons={mostProducts.length > slidesToShow}
            slides={mostProducts}
            onSlideRender={(product) =>
              props?.onRender ? (
                props.onRender({ product })
              ) : (
                <ProductItem
                  onClick={props.onClick ? props.onClick : undefined}
                  product={product}
                  {...props}
                />
              )
            }
          />
        </div>
      );
    }
  };

  return (
    <div className={clsNameMap.root()}>
      {searchEmptyResultMessages.active && (
        <InnerHTMLRenderer
          className={clsNameMap.elm('empty')}
          html={t('search.resultEmpty')
            .replace(
              '{{ terms }}',
              `<strong class=${clsNameMap.elm('search-result')}>${stripHtml(
                getTermValueFromUrl(termKey)
              )}</strong>`
            )
            .replaceAll(/{{ breakline }}/g, '<br />')}
        />
      )}

      {searchTips.active && (
        <div className={clsNameMap.elm('search-tip')}>
          <h2 className={clsNameMap.elm('title')}>{t('search.searchTipsTitle')}</h2>
          <ul>
            {t('search.searchTipsContent')
              .split('{{ breakline }}')
              ?.map((item, index) => (
                <li key={`${item}_${index}`}>{item}</li>
              ))}
          </ul>
        </div>
      )}

      {searchTermSuggestions.active && searchTermSuggestions.searchTermList?.length > 0 && (
        <div className={clsNameMap.elm('search-term-suggestion')}>
          <h2 className={clsNameMap.elm('title')}>{t('search.noSearchResultSearchTermLabel')}</h2>
          <ul className={clsNameMap.elm('suggestion-list')}>
            {searchTermSuggestions.searchTermList.map((term, index) => {
              return (
                <li key={`${term}_${index}`}>
                  <a
                    className={clsNameMap.elm('suggestion-item')}
                    href={generateSearchPageHref(term)}
                  >
                    {term}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {renderPopularProducts()}
    </div>
  );
};

export default NoSearchResultPage;
