import './PaginationInfiniteScroll.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { PaginationButtonProps } from '@components/PaginationButton';
import type { ProductCountProps } from '@components/ProductCount';
import { COLLECTION_SELECTED, NEXT_PAGE } from '@constants/pagination';
import type { CurrentCollectionFilterState } from '@providers/FilterProvider';
import {
  createClsNameMap,
  getSessionStorage,
  resetPaginationSessionStorage,
  setSessionStorage,
} from '@utils';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect } from 'react';

const clsNameMap = createClsNameMap({
  elements: {
    target: createClsNameMap(),
    button: createClsNameMap(),
  },
})('pagination-infinite-scroll-container');

export type PaginationInfiniteScrollProps = {
  totalPage: number;
  onChangePage?: (
    page: number,
    currentCollectionSelected?: CurrentCollectionFilterState
  ) => unknown;
  productCount?: React.ReactElement<ProductCountProps>;
  loadPreviousButton?: React.ReactElement<PaginationButtonProps>;
  currentCollectionSelected?: CurrentCollectionFilterState;
};

const PaginationInfiniteScroll = ({
  totalPage,
  onChangePage,
  productCount,
  loadPreviousButton,
  children,
  currentCollectionSelected,
}: PropsWithChildren<PaginationInfiniteScrollProps>) => {
  const options = {
    rootMargin: '0px',
    threshold: 1.0,
  };

  useEffect(() => {
    setSessionStorage(COLLECTION_SELECTED, currentCollectionSelected);
    return () => setSessionStorage(COLLECTION_SELECTED, null);
  }, [currentCollectionSelected]);

  useEffect(() => {
    window.addEventListener('beforeunload', resetPaginationSessionStorage);

    return () => {
      window.removeEventListener('beforeunload', resetPaginationSessionStorage);
    };
  });

  const handleOnChangePage = () => {
    const curr = getSessionStorage(NEXT_PAGE);
    const collection = getSessionStorage(COLLECTION_SELECTED);

    if (onChangePage) {
      setSessionStorage(NEXT_PAGE, curr + 1);
      onChangePage(curr + 1, collection);
    }
  };

  const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;

    if (entry.isIntersecting) {
      handleOnChangePage();
    }
  }, options);

  const refCallBack = useCallback((node: HTMLDivElement) => {
    if (node !== null) observer.observe(node);
  }, []);

  return (
    <div className={clsNameMap.root()}>
      <div className={clsNameMap.elm('button')}>{loadPreviousButton}</div>

      {productCount}
      {children}
      {totalPage > getSessionStorage(NEXT_PAGE) && (
        <div
          className={clsNameMap.elm('target')}
          ref={refCallBack}
          style={{ textAlign: 'center', opacity: 0, visibility: 'hidden' }}
        >
          Loading...
        </div>
      )}
    </div>
  );
};

export default registryComponent('PaginationInfiniteScroll', PaginationInfiniteScroll);
