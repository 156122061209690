import './HeaderTitle.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { createClsNameMap } from '@utils';

export const clsNameMap = createClsNameMap()('header-title');

export type HeaderTitleProps = {
  title: string;
  collectionTitleColor?: string;
  fontFamily?: string;
  fontStyle?: string;
  fontWeight?: string | number;
  fontSize?: string | number;
  textTransform?: 'none' | 'uppercase' | 'capitalize' | 'lowercase';
  textAlign?: 'left' | 'center' | 'right';
};

const HeaderTitle = ({
  title,
  collectionTitleColor,
  fontFamily,
  fontStyle,
  fontWeight,
  fontSize,
  textTransform,
  textAlign,
}: HeaderTitleProps) => {
  const style: React.CSSProperties = {
    color: collectionTitleColor,
    fontFamily,
    fontStyle,
    fontWeight,
    fontSize,
    textTransform,
    textAlign,
  };

  return (
    <h1 className={clsNameMap.root()} style={style}>
      {textTransform === 'capitalize' ? title.toLowerCase() : title}
    </h1>
  );
};

export default registryComponent('HeaderTitle', HeaderTitle);
