import './ProductItemListViewLayout.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { useVDomToString } from '@boost-sd/core-js';
import Button from '@components/Button';
import FormatCurrency from '@components/FormatCurrency';
import IntegrationProductRating from '@components/IntegrationProductRating';
import ProductImage from '@components/ProductImage';
import ProductLabel from '@components/ProductLabel';
import ProductLink from '@components/ProductLink';
import TranslateWithComponent from '@components/ProductPrice/TranslateWithComponent';
import ProductSwatch from '@components/ProductSwatch';
import ProductTitle from '@components/ProductTitle';
import ProductVendor from '@components/ProductVendor';
import useTranslation from '@hooks/useTranslation';
import type { AddToCartState } from '@providers/CartProvider';
import { useProduct } from '@providers/ProductProvider';
import { createClsNameMap } from '@utils';
import classnames from '@utils/classnames';
import { useState } from 'react';

import type { ProductItemProps } from '../types';

const clsNameMap = createClsNameMap({
  elements: {
    image: createClsNameMap(),
    'info-group': createClsNameMap(),
    info: createClsNameMap(),
    'cta-buttons': createClsNameMap(),
    price: createClsNameMap(),
    description: createClsNameMap(),
  },
})('product-item-list-view-layout');

export type ProductItemListViewLayoutProps = ProductItemProps & {
  noImageUrl: any;
  hasCollection: boolean;
};

export const ProductItemListViewLayout = registryComponent(
  'ProductItemListViewLayout',
  (props: ProductItemListViewLayoutProps) => {
    const { t } = useTranslation();

    const { product, noImageUrl, hasCollection } = props;

    const { previewProductImages, isSale, isSoldOut, salePercent, saleAmount } = useProduct(
      product,
      noImageUrl
    );

    const [addToCartState, setAddToCartState] = useState<AddToCartState>({
      isAdding: false,
      success: false,
      fail: false,
    });

    const handleOnClickQuickViewButton = () => {
      window.dispatchEvent(
        new CustomEvent('boost-sd-open-quick-view', {
          detail: {
            productData: product,
            type: 'full',
          },
        })
      );
    };

    const handleATC = () => {
      if (product?.variants?.length === 1) {
        if (addToCartState?.isAdding) return;
        setAddToCartState((prev) => ({ ...prev, isAdding: true }));
        window.dispatchEvent(
          new CustomEvent('boost-sd-add-to-cart', {
            detail: {
              variantId: product?.variants?.[0]?.id,
              quantity: 1,
              onSuccess: () => {
                setTimeout(() => {
                  setAddToCartState((prev) => ({
                    fail: false,
                    isAdding: false,
                    success: false,
                  }));
                }, 350);
                setAddToCartState((prev) => ({
                  ...prev,
                  isAdding: false,
                  success: true,
                }));
              },
              onFail: () => {
                setTimeout(() => {
                  setAddToCartState((prev) => ({
                    fail: false,
                    isAdding: false,
                    success: false,
                  }));
                }, 350);
                setAddToCartState((prev) => ({ ...prev, fail: true, isAdding: false }));
              },
            },
          })
        );
      } else {
        window.dispatchEvent(
          new CustomEvent('boost-sd-open-quick-view', {
            detail: {
              productData: product,
              type: 'mini',
            },
          })
        );
      }
    };

    const renderTranslateButtonCTA = () => {
      if (isSoldOut) return 'productItem.soldoutLabel';

      return product?.variants.length > 1
        ? 'productItem.atcSelectOptionsLabel'
        : addToCartState?.isAdding
        ? 'productItem.atcAddingToCartBtnLabel'
        : addToCartState?.success
        ? 'productItem.atcAddedToCartBtnLabel'
        : addToCartState?.fail
        ? 'productItem.atcFailedToCartBtnLabel'
        : 'productItem.atcAvailableLabel';
    };

    const colorOption = product.options_with_values.find(
      (item) => (item.original_name?.toLowerCase() || item.name) === 'color'
    );

    const saleLabelString = useVDomToString({ vDOM: <FormatCurrency value={saleAmount} /> });

    return (
      <div
        className={clsNameMap.root()}
        id={product.id.toString()}
        data-product-id={product?.variant_id?.toString() || product.id.toString()}
      >
        <div className={clsNameMap.elm('image')}>
          <ProductLink
            data={product}
            className={classnames('product-link-image')}
            hasCollection={hasCollection}
          >
            <ProductImage
              images={previewProductImages}
              altImage={product.title}
              elements={{
                saleLabel:
                  isSale && !isSoldOut ? (
                    <ProductLabel
                      key='sale-label'
                      labelType='sale'
                      labelText={
                        <TranslateWithComponent
                          translation='productItem.productItemSale'
                          data={{
                            salePercent,
                            saleAmount: saleLabelString,
                          }}
                        />
                      }
                    />
                  ) : undefined,
                soldOutLabel: isSoldOut ? (
                  <ProductLabel
                    key='sold-out-label'
                    labelType='soldout'
                    labelText={t('productItem.productItemSoldOut')}
                  />
                ) : undefined,
              }}
              grid={{
                top: {
                  direction: 'horizontal',
                  elements: {
                    left: ['saleLabel', 'soldOutLabel'],
                  },
                },
              }}
            />
          </ProductLink>
        </div>

        <div className={clsNameMap.elm('info-group')}>
          <div className={clsNameMap.elm('info')}>
            <ProductLink data={product} hasCollection={hasCollection}>
              <ProductTitle title={product.title} />
              <ProductVendor vendor={product.vendor} />
              <IntegrationProductRating />
              <div className={clsNameMap.elm('price')}>
                <FormatCurrency isSale={isSale} value={product.price_min} />
                {product.compare_at_price_min !== 0 && isSale && (
                  <FormatCurrency isPriceCompare value={product.compare_at_price_min} />
                )}
              </div>
            </ProductLink>
            <div>
              {colorOption && (
                <ProductSwatch
                  options={colorOption.values.map((value) => ({
                    value: value.origin_title || value.title,
                    label: value.title,
                    id: `product-swatch-qv-${value.title}`,
                    labelAs: 'image',
                    displayType: 'circle',
                    showOptionValueOnHovering: true,
                  }))}
                  isPopupSelectOption
                  maxItem={4}
                />
              )}
            </div>
          </div>

          <div className={clsNameMap.elm('cta-buttons')}>
            <Button
              onClick={handleATC}
              disabled={isSoldOut}
              buttonModifiers={['primary', 'full-width', 'large']}
              className={
                t(renderTranslateButtonCTA()) === t('productItem.atcAvailableLabel')
                  ? classnames('btn-add-to-cart')
                  : ''
              }
              title={t(renderTranslateButtonCTA())}
            >
              {t(renderTranslateButtonCTA())}
            </Button>
            <Button
              onClick={handleOnClickQuickViewButton}
              buttonModifiers={['secondary', 'full-width', 'large']}
              className={classnames('btn-quick-view')}
              title={t('productItem.qvBtnLabel')}
            >
              {t('productItem.qvBtnLabel')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
