import type { ViewAsGridMultiColListOption } from '@providers/AdditionalElementProvider';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import { useMemo } from 'react';

export type ViewAsOptionState = {
  viewAsOption: ViewAsGridMultiColListOption;
};

export const useConnectViewAsOption = () => {
  const { viewAsGridMultiColListOption } = useAdditionalElementState();

  const props: ViewAsOptionState = useMemo(() => {
    return {
      viewAsOption: viewAsGridMultiColListOption,
    };
  }, [viewAsGridMultiColListOption]);

  return {
    props,
  };
};
