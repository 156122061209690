import type { Dict } from '@types';

const getProductsByBatch = async (handleString: string, templateName = 'boost-sd-integration') => {
  const url = `/collections/all/${handleString}?view=${templateName}`;
  const response = await fetch(url, { method: 'GET' });
  return response.json();
};
export const getProductListHtmlByAjax = async (
  enable: boolean,
  handles: string[],
  templateName?: string, // templateName collection view custom
  perPage?: number
) => {
  if (!enable) return;

  if (!perPage || perPage > 20) perPage = 20;

  // Add loading class
  // this.$element.addClass(Class.productWrapLoading);

  const batches = Math.ceil(handles.length / perPage);

  const allFetch = [];
  for (let i = 0; i < batches; i++) {
    const fromIndex = perPage * i;
    const toIndex = Math.min(handles.length, perPage * (i + 1));
    const currentHandles = handles.slice(fromIndex, toIndex);
    const handleString = currentHandles.join('+');

    allFetch.push(getProductsByBatch(handleString, templateName));
  }

  return Promise.all(allFetch)
    .then((res) => {
      let allProducts: Dict = [];

      res.forEach((item) => {
        allProducts = allProducts.concat(item.products);
      });

      return allProducts;
    })
    .catch((err) => {
      return [];
    });
};
