import PaginationButton from '@components/PaginationButton';
import PaginationPageLink from '@components/PaginationPageLink';
import type {
  PaginationButtonStyleSettings,
  PaginationNumberStyleSettings,
} from '@providers/ThemeProvider/types';

import { pageLinkNextIcon, pageLinkPrevIcon } from '@/widgets/ProductList/Pagination';

export type SearchResultPaginationProps = {
  totalPages: number;
  currentPage?: number;
  numberSettings?: PaginationNumberStyleSettings;
  buttonSettings?: PaginationButtonStyleSettings;
  onChange: (page: number) => void;
};

const SearchResultPagination = ({
  totalPages,
  currentPage = 1,
  numberSettings,
  buttonSettings,
  onChange,
}: SearchResultPaginationProps) => {
  return (
    <PaginationPageLink
      totalPages={totalPages}
      currentPage={currentPage}
      onChangePage={onChange}
      prevButton={
        currentPage !== 1 ? (
          <PaginationButton
            onClick={() => onChange(currentPage - 1)}
            icon={pageLinkPrevIcon}
            iconPosition='left'
            {...buttonSettings}
          />
        ) : undefined
      }
      nextButton={
        currentPage !== totalPages ? (
          <PaginationButton
            icon={pageLinkNextIcon}
            onClick={() => {
              onChange(currentPage + 1);
            }}
            iconPosition='right'
            {...buttonSettings}
          />
        ) : undefined
      }
      {...numberSettings}
    />
  );
};

export default SearchResultPagination;
