import './CallToActionForOtherPage.scss';

import CallToAction from '@components/CallToAction';
import { createClsNameMap } from '@utils';

import type { QuickViewProductData } from '@/widgets/QuickView';

export const clsNameMap = createClsNameMap()('cta-other-page-wrapper');

export type CallToActionForOtherPageProps = {
  productData: QuickViewProductData;
  containerEl: Element;
};

const CallToActionForOtherPage = ({ productData, containerEl }: CallToActionForOtherPageProps) => {
  const handleClick = () =>
    window.dispatchEvent(
      new CustomEvent('boost-sd-open-quick-view', {
        detail: {
          productData: productData,
          type: 'full',
        },
      })
    );

  return ReactDOM.createPortal(
    <div className={clsNameMap.root()}>
      <CallToAction
        buttons={[
          {
            icon: '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18.1 18.4C17.6592 18.4 17.3 18.0408 17.3 17.6C17.3 17.1592 17.6592 16.8 18.1 16.8C18.5408 16.8 18.9 17.1592 18.9 17.6C18.9 18.0408 18.5408 18.4 18.1 18.4ZM7.7001 17.6C7.7001 18.0408 7.3409 18.4 6.9001 18.4C6.4593 18.4 6.1001 18.0408 6.1001 17.6C6.1001 17.1592 6.4593 16.8 6.9001 16.8C7.3409 16.8 7.7001 17.1592 7.7001 17.6ZM18.6328 8.67277L17.5232 12H7.69995V7.30637L18.6328 8.67277ZM18.1 15.2H7.7V13.6H18.1C18.4448 13.6 18.7496 13.38 18.8592 13.0528L20.4592 8.2528C20.5344 8.0256 20.5048 7.776 20.3768 7.5736C20.2488 7.3704 20.0368 7.236 19.7992 7.2064L7.7 5.6936V4.8C7.7 4.3584 7.3416 4 6.9 4H5.3C4.8584 4 4.5 4.3584 4.5 4.8C4.5 5.2416 4.8584 5.6 5.3 5.6H6.1V15.3472C5.1704 15.6792 4.5 16.5584 4.5 17.6C4.5 18.9232 5.5768 20 6.9 20C8.2232 20 9.3 18.9232 9.3 17.6C9.3 17.3176 9.2424 17.0512 9.1528 16.8H15.8472C15.7576 17.0512 15.7 17.3176 15.7 17.6C15.7 18.9232 16.7768 20 18.1 20C19.4232 20 20.5 18.9232 20.5 17.6C20.5 16.2768 19.4232 15.2 18.1 15.2Z" fill="currentColor"/></svg>',
            onClick: handleClick,
            backgroundColor: 'rgba(0, 0, 0, 1)',
            backgroundColorOnHover: 'rgba(61, 66, 70, 1)',
            borderColor: 'rgba(0, 0, 0, 1)',
            borderColorOnHover: 'rgba(61, 66, 70, 1)',
            textColor: 'rgba(255, 255, 255, 1)',
            textColorOnHover: 'rgba(24, 116, 189, 1)',
          },
        ]}
      />
    </div>,
    containerEl
  );
};

export default CallToActionForOtherPage;
