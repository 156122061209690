import type { Dict } from '@types';

import { stripHtml } from './format';

export const getTermValueFromUrl = (termKey = 'q', isStripHtml = true) => {
  const { search } = window.location;

  const hashes = search.slice(search.indexOf('?') + 1).split('&');

  const params: Dict = {};

  if (hashes[0] === '') return '';

  hashes.forEach((hash) => {
    const [key, val] = hash.split('=');
    const decodedKey = decodeURIComponent(key);
    // By default, space will be converted into '+' when navigate to search page
    // Therefore, when decode need to turn it back
    const decodedValue = decodeURIComponent(val.replace(/\+/g, ' '));

    params[decodedKey] = decodedValue;
  });

  let termValue = params[termKey];

  if (!termValue) return '';

  if (isStripHtml) termValue = stripHtml(termValue);

  return termValue;
};
