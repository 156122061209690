import { registryComponent } from '@boost-sd/components-registry/registry';
import { useUpdateEffect } from '@boost-sd/core-js';
import useMountEffect from '@hooks/useMountEffect';
import { isMobileWidth } from '@utils';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

import type { FilterParams, FilterTreeProviderValue, FilterTreeState } from '../types';
import { useFilterSettings } from './FilterSettings';
import { useFilterState } from './FilterState';

const FilterTreeContext = createContext<FilterTreeProviderValue | null>(null);

type FilterTreeStateProviderProps = {
  filterTreeOpenDefault?: boolean;
};

export const FilterTreeProvider = ({
  children,
  filterTreeOpenDefault = false,
}: PropsWithChildren<FilterTreeStateProviderProps>) => {
  const { filterTreeVerticalStyle, filterTreeHorizontalStyle, filterLayout, filterPrefixParam } =
    useFilterSettings();
  const { filterParams } = useFilterState();

  const [refineByHorizontal, setRefineByHorizontal] = useState<FilterParams>(filterParams);

  const [filterTreeState, setFilterTreeState] = useState<FilterTreeState>(() => {
    // filter tree vertical & desktop & style = style-default => true.
    // filter tree horizontal & desktop & style 'style1', 'style2', 'style3' => true
    const filterTreeVisible =
      !isMobileWidth() &&
      ((filterLayout === 'vertical' && filterTreeVerticalStyle === 'style-default') ||
        (filterLayout === 'horizontal' &&
          ['style1', 'style2', 'style3'].includes(filterTreeHorizontalStyle)))
        ? true
        : filterTreeOpenDefault;

    return {
      filterTreeVisible,
    };
  });

  const setFilterTreeVisible = useCallback((visible?: boolean) => {
    /**
     * @desc Toggle current visible state, when the visible param isn't passed
     */

    if (visible == null) {
      setFilterTreeState((prevState) => ({
        ...prevState,
        filterTreeVisible: !prevState.filterTreeVisible,
      }));

      return;
    }

    setFilterTreeState((prevState) => ({
      ...prevState,
      filterTreeVisible: visible,
    }));
  }, []);

  // only apply for horizontal
  if (filterLayout === 'horizontal') {
    useUpdateEffect(() => {
      const collectionOptionId = `${filterPrefixParam}c_collection`;

      if (filterParams[collectionOptionId] !== refineByHorizontal[collectionOptionId]) {
        setRefineByHorizontal({
          ...refineByHorizontal,
          [collectionOptionId]: filterParams[collectionOptionId]
            ? [filterParams[collectionOptionId].toString()]
            : undefined,
        });
      }
    }, [filterParams]);
  }

  const providerValue: FilterTreeProviderValue = {
    filterTreeState: filterTreeState,
    setFilterTreeVisible,
    refineByHorizontal,
    setRefineByHorizontal,
  };

  useMountEffect(function integrateWithSearchFeature() {
    const bindSearchInputEvent = new CustomEvent('bind-search-input');
    window.dispatchEvent(bindSearchInputEvent);
    if (filterLayout === 'horizontal') {
      window.addEventListener('boost-sd-click-back-button-horizontal', (e) => {
        const { filterParamsHorizontal } = e.detail;
        filterParamsHorizontal && setRefineByHorizontal(filterParamsHorizontal);
      });
    }
  });

  return <FilterTreeContext.Provider value={providerValue}>{children}</FilterTreeContext.Provider>;
};

export const FilterTreeStateProvider = registryComponent(
  'FilterTreeStateProvider',
  FilterTreeProvider
);

export const useFilterTreeState = () => {
  const context = useContext(FilterTreeContext);
  if (!context) {
    throw Error('Use useFilterTreeState in FilterTreeProvider');
  }

  return context;
};
