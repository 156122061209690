import { INITIAL_PAGE, NEXT_PAGE, PREV_PAGE } from '@constants/pagination';
import type { Dict } from '@types';

import { isMobileWidth, setSessionStorage } from './browser';

export type stickyType = {
  stickyElement: HTMLCollectionOf<Element>;
  endElement: string;
  avoidElement: string;
  isFullWidth?: boolean;
  isMaxWidth?: boolean;
  isToolbarIndex?: boolean;
  customStyle?: string;
  isFullHeight?: boolean;
  ignoreCheckWidth?: boolean;
  callBack?: (isSticky: boolean) => void;
  isOpeningFilterTree?: boolean;
  customizeSticky?: (stickyType: stickyType) => void;
};

export const sticky = async ({
  stickyElement,
  endElement,
  avoidElement,
  isFullWidth,
  isMaxWidth,
  isToolbarIndex,
  customStyle,
  isFullHeight,
  ignoreCheckWidth,
  callBack,
  isOpeningFilterTree,
  customizeSticky,
}: stickyType) => {
  // stickyElement not existed return or stickyElement not display in view port.
  if (
    !stickyElement ||
    (!ignoreCheckWidth &&
      (stickyElement?.length === 0 || !stickyElement[0]?.getBoundingClientRect()?.width))
  )
    return;
  const stickyOriginWidth = stickyElement[0]?.getBoundingClientRect().width;

  // add customize function for sticky - customize for some special store
  if (customizeSticky) {
    return customizeSticky({
      stickyElement,
      endElement,
      avoidElement,
      isFullWidth,
      isMaxWidth,
      isToolbarIndex,
      customStyle,
      isFullHeight,
      callBack,
      isOpeningFilterTree,
    });
  }

  let firstRegisterAvoidHeader = 0;
  let mobileStyle1 = false;
  let deskTopHorizontalStyle1 = false;

  if (stickyElement[0]?.classList[0]?.endsWith('top-mobile')) {
    mobileStyle1 = true;
  }

  if (
    Array.from(stickyElement[0]?.classList || []).find((className) =>
      className.endsWith('container--style-expand')
    )
  ) {
    deskTopHorizontalStyle1 = true;
  }

  // position of element start Sticky
  if (!stickyElement[0]?.getAttribute('data-offset-top')) {
    let dataOffsetTopOrigin = stickyElement[0]?.getBoundingClientRect().top;
    /* SOLUTION TEMP: Fix some case data offset origin too large
     * store issues: fjern-nlg.myshopify.com - data offset origin 2716px
     * NEED test in store customer because i can't not reproduce in store local
     */
    if (dataOffsetTopOrigin > 1500) return;

    // Cover case: scroll down & reload = currentElement sticky = position + scrollY
    if (window.scrollY > 0) {
      dataOffsetTopOrigin = dataOffsetTopOrigin + window.scrollY;
    }

    stickyElement[0]?.setAttribute('data-offset-top', dataOffsetTopOrigin.toString());
  }
  // Check for sticky header and announcement bar to avoid
  const setPosition = () => {
    if (stickyElement.length === 0) return;

    const avoidStickyElement = avoidElement ? document.querySelectorAll(avoidElement) : [];

    //FIXME: fix issue avoidStickyElement not existed the first.
    if (firstRegisterAvoidHeader === 0 && avoidStickyElement.length > 0) {
      firstRegisterAvoidHeader++;
      avoidStickyElement.forEach((element) => {
        if (element instanceof HTMLElement) {
          element.removeEventListener('resize', setPosition);
          element.removeEventListener('transitionend', setPosition);
        }
      });

      avoidStickyElement.forEach((element) => {
        if (element instanceof HTMLElement) {
          element.addEventListener('resize', setPosition);
          element.addEventListener('transitionend', setPosition);
        }
      });
    }

    // use requestAnimationFrame to animate smooth
    requestAnimationFrame(async () => {
      const styleSticky = [];
      let startPosSticky = Number(stickyElement[0]?.getAttribute('data-offset-top'));

      // cover case: some case have header collection but image not loaded
      if (stickyElement[0]?.getBoundingClientRect().top > startPosSticky) {
        stickyElement[0]?.setAttribute(
          'data-offset-top',
          (stickyElement[0]?.getBoundingClientRect().top + window.scrollY).toString()
        );
      }

      const stickyHeight = stickyElement[0]?.scrollHeight;
      const endStickyElement = document.querySelector(endElement);

      // if not existed endStickyElement then not sticky
      let endPosSticky = endStickyElement
        ? endStickyElement?.getBoundingClientRect().top +
          window?.scrollY +
          endStickyElement?.scrollHeight
        : 0;

      let offsetTop = 0;

      const checkClassIgnored = (headerChild: Element) => {
        const ignoredSomeClassSpecialOfThemes = [
          'mobile_nav-fixed--true', // because this class children of shopify-section-header -> fix in Turbo theme duplicate header sticky
        ];

        const childrenClasses = headerChild.classList;

        if (childrenClasses.length > 0) {
          for (let i = 0; i < childrenClasses.length; i++) {
            if (ignoredSomeClassSpecialOfThemes.includes(childrenClasses[i])) {
              return true;
            }
          }
        }

        return false;
      };

      if (avoidStickyElement.length > 0) {
        for (const header of avoidStickyElement) {
          const rect = header.getBoundingClientRect();
          // Sticky header is visible in viewport
          // Change condition 0 to -1 for case Flow theme 33.11 and need to check and improve
          if (rect.y >= -1 && rect.height > 0) {
            offsetTop += rect.height;

            // Check sticky header's children
          } else {
            if (header.children?.length > 0) {
              for (const headerChild of header.children) {
                const childRect = headerChild.getBoundingClientRect();

                if (childRect.y >= 0 && childRect.height > 0 && !checkClassIgnored(headerChild)) {
                  offsetTop += childRect.height;
                }
              }
            }
          }
        }
      }

      // Round to integer for theme headers with odd heights
      offsetTop = Math.floor(offsetTop);

      // Set new Start and End Pos to avoid the avoidElement
      startPosSticky = startPosSticky - offsetTop;

      // Math.min(stickyHeight, window.innerHeight - offsetTop) -> cover case sticky content height > window.innerHeight - offsetTop (vertical left-side)
      endPosSticky =
        endPosSticky - offsetTop - Math.min(stickyHeight, window.innerHeight - offsetTop);
      const widthSticky = isFullWidth ? '100%' : `inherit`;
      const maxWidthSticky = isMaxWidth ? '100%' : `${stickyOriginWidth}px`;
      const zIndex = isToolbarIndex ? '3' : '2';

      const alwaysStickyMobileStyle1WhenOpening =
        isOpeningFilterTree &&
        (mobileStyle1 || deskTopHorizontalStyle1) &&
        startPosSticky <= window.scrollY;

      if (
        !alwaysStickyMobileStyle1WhenOpening &&
        (window.scrollY < startPosSticky ||
          (window.scrollY > endPosSticky && endPosSticky - startPosSticky <= stickyHeight))
      ) {
        styleSticky.push(`position: static`);
        if (callBack) callBack(false);
      } else if (
        alwaysStickyMobileStyle1WhenOpening ||
        (startPosSticky <= window.scrollY && window.scrollY <= endPosSticky)
      ) {
        styleSticky.push(
          `position: fixed; max-height: ${window.innerHeight - offsetTop}px`,
          `top: ${offsetTop}px`,
          `z-index: ${zIndex}`,
          `width: ${widthSticky}`,
          `max-width: ${maxWidthSticky}`,
          `background-color: #ffffff`,
          'margin: 0 auto',
          customStyle || ''
        );

        if (isFullHeight) {
          styleSticky.push(`height: 100%`, 'max-height: unset');
        }

        if (isFullWidth) {
          styleSticky.push(`left: 0; right:0`);
        }

        if (callBack) callBack(true);
      } else {
        // Cover case scroll > end position

        if (isMobileWidth()) {
          styleSticky.push(
            `position: absolute`,
            `max-height: ${window.innerHeight - offsetTop}px`,
            `top: -100%; left: 0; right: 0`,
            `z-index: ${zIndex}`,
            `width: ${widthSticky}`,
            `max-width: ${maxWidthSticky}`,
            'background-color: #ffffff',
            'margin: 0 auto',
            customStyle || ''
          );
        } else {
          styleSticky.push(
            `position: absolute`,
            `max-height: unset`,
            `bottom: 100px`,
            `z-index: ${zIndex}`,
            `width: ${widthSticky}`,
            `max-width: ${maxWidthSticky}`,
            'background-color: #ffffff',
            'margin: 0 auto',
            customStyle || ''
          );

          if (isFullWidth) {
            styleSticky.push(`left: 0; right:0`);
          }
        }

        if (callBack) callBack(true);
      }
      // set Style for Element sticky
      if (stickyElement[0]) stickyElement[0]?.setAttribute('style', styleSticky.join(';'));
    });
  };

  // listen to events in product list
  window.removeEventListener('product-list-change', setPosition);
  window.addEventListener('product-list-change', setPosition);

  window.removeEventListener('resize-trigger-sticky-event', setPosition);
  window.addEventListener('resize-trigger-sticky-event', setPosition);

  window.removeEventListener('scroll', setPosition);
  window.addEventListener('scroll', setPosition);
};

// TODO: Created PFSN-48714 - Don't use this function. We will discuss and refactor new approach
export const setVariableRootCSS = (objStyle: Dict, element: HTMLElement | Element | null) => {
  const keys = Object.keys(objStyle);

  if (keys?.length === 0) return;
  const root = element || document.body;

  keys.forEach((key) => {
    if (objStyle[key]) {
      (root as HTMLElement)?.style?.setProperty(`--boost-sd-app-${key}`, objStyle[key]);
    }
  });
};

export const shouldKeepScrollRightAfterFiltering = () => {
  // after filtering -> scrollTo bs-sd-right.
  const boostSDRight = document.querySelector('.boost-sd-right');

  if (!boostSDRight) return;

  const yBoostSDRight = boostSDRight?.getBoundingClientRect()?.top as number;

  // - 150: cover case theme have menu show when scroll top and sticky toolbar
  if (window.scrollY > yBoostSDRight) {
    window.scrollTo({
      top: yBoostSDRight + window.scrollY - 150,
    });
  }
};

export const resetPaginationSessionStorage = () => {
  setSessionStorage(INITIAL_PAGE, 1);
  setSessionStorage(PREV_PAGE, 1);
  setSessionStorage(NEXT_PAGE, 1);
};
