import './ProductList.scss';

import { getProductListHtmlByAjax } from '@apis/ajax';
import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import { DeferredList } from '@boost-sd/core-js';
import { getQueryParamByKey, setQueryParam } from '@boost-sd/core-js/history';
import { ProductItemListViewLayout } from '@components/ProductItem';
import ProductListPlaceholder from '@components/ProductListPlaceholder';
import {
  CURRENT_PER_TOTAL,
  DEFAULT,
  INFINITE,
  LOAD_MORE,
  PREV_PAGE,
  SELECTED_PRODUCT_ID,
  SELECTED_PRODUCT_PAGE,
} from '@constants/pagination';
import useGeneralSettings from '@hooks/useGeneralSettings';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import { useFilterState } from '@providers/FilterProvider';
import { useProductListState } from '@providers/ProductListProvider';
import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import { useProductItemThemeSettings } from '@providers/ThemeProvider/Provider/ProductItemThemeSettings';
import { getSessionStorage, setSessionStorage } from '@utils';
import classnames from '@utils/classnames';
import { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import ProductItem from '../ProductItem';
import { useConnectViewAsOption } from './connectors/connectViewAsOptionState';
import type { ProductListPaginationProps } from './Pagination';
import ProductListPagination from './Pagination';
import useKeepPositionOnBack from './useKeepPositionOnBack';

export type ProductListProps = RegistryComponentProps<
  {
    className?: string;
    pagination?: {
      enable: boolean;
    };
    scrollToTopContainerOnLoadProducts?: {
      enable: boolean;
      behavior?: ScrollBehavior;
      target?: 'product-list' | 'window' | HTMLElement;
    };
    callbackFuncAppIntegration?: (() => void)[];
    enableKeepScrollbackPosition?: boolean;
    scrollBackOffset?: number;
    scrollBackAwait?: number;
    afterRender?: () => void;
    disableDeferredRender?: boolean;
  },
  {
    getProductHandles?: () => string[];
  }
>;

const ProductList = ({
  className,
  pagination = {
    enable: true,
  },
  scrollToTopContainerOnLoadProducts = { enable: true, behavior: 'smooth' },
  callbackFuncAppIntegration,
  enableKeepScrollbackPosition = true,
  scrollBackOffset,
  scrollBackAwait,
  helpersRef,
  afterRender,
  disableDeferredRender = true,
}: ProductListProps) => {
  const productListElementRef = useRef<HTMLDivElement | null>(null);
  const { props: viewAsOptionProps } = useConnectViewAsOption();

  const {
    products,
    totalProducts,
    pagination: paginationState,
    changeProductPage,
    currentCollectionSelected,
  } = useProductListState();

  const { loadingAdditional, viewAsGridMultiColListOption } = useAdditionalElementState();

  const [limitDisplayProducts, setLimitDisplayProducts] = useState({
    // enable by default if device's width is mobile and not want to keep position on back
    // TODO: review solution later
    enabled: false,
    limit: 4,
  });

  useImperativeHandle(helpersRef, () => ({
    getProductHandles() {
      const getCurrentProductIds = products
        .slice(-(paginationState?.limit || 24))
        .map((item) => item.handle);

      return getCurrentProductIds;
    },
    getProductListHtmlByAjax,
    setAjaxLoading,
  }));

  const {
    sharedAdditionalElementData: { limit },
    setAjaxLoading,
  } = useFilterState();

  const {
    pagination: { paginationType },
  } = useAdditionalElementThemeSettings();

  const {
    general: { borderLayout },
  } = useProductItemThemeSettings();

  // scroll restoration
  const { performScrollRestoration } = useKeepPositionOnBack(products, {
    enableKeepScrollbackPosition,
    scrollBackOffset,
    scrollBackAwait,
  });

  const {
    generalSettings: { no_image_url, addCollectionToProductUrl: hasCollection },
  } = useGeneralSettings();

  const trackPosition = (index: number, productId: number) => {
    const pageParam = getQueryParamByKey('page');
    const currPage = pageParam ? Number(pageParam) : 1;
    const page = Math.ceil((index + 1) / limit) || 1;
    const prevPage = getSessionStorage(PREV_PAGE);
    const calc = {
      [DEFAULT]: currPage,
      [LOAD_MORE]: prevPage - 1 + page,
      [INFINITE]: prevPage - 1 + page,
      [CURRENT_PER_TOTAL]: currPage,
    };

    if (!paginationType) return;

    const actualPage = calc[paginationType];

    setSessionStorage(SELECTED_PRODUCT_PAGE, calc[paginationType]);
    setSessionStorage(SELECTED_PRODUCT_ID, productId);

    setQueryParam('page', actualPage.toString(), true);
  };

  const productListPaginationProps: ProductListPaginationProps = {
    products,
    totalProducts,
    productListElementRef,
    scrollToTopContainerOnLoadProducts,
    paginationState,
    changeProductPage,
    enable: pagination.enable,
    currentCollectionSelected,
  };

  const getColClassName = () => {
    return `product-list-${viewAsOptionProps.viewAsOption}-col`;
  };

  const autoAspectRatio = useMemo(() => {
    return products.reduce((maxRatio, currentProduct) => {
      const firstImage = currentProduct.images_info.length > 0 && currentProduct.images_info[0];
      if (!firstImage) return maxRatio;

      const { height, width } = firstImage;

      return Number(Math.max(height / width, maxRatio).toFixed(2));
    }, 0);
  }, [products]);

  useEffect(() => {
    if (callbackFuncAppIntegration) {
      callbackFuncAppIntegration.forEach((callback: () => void) => callback?.());
    }
  }, [viewAsOptionProps.viewAsOption]);

  const displayProducts = useMemo(() => {
    if (limitDisplayProducts.enabled) return products.slice(0, limitDisplayProducts.limit);

    return products;
  }, [products, limitDisplayProducts]);

  const afterDeferredListRender = () => {
    if (afterRender) {
      afterRender();
      performScrollRestoration();

      const productListElement = productListElementRef.current;

      if (limitDisplayProducts.enabled && productListElement) {
        const intersection = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setLimitDisplayProducts((prev) => ({
                  ...prev,
                  enabled: false,
                }));

                intersection.disconnect();
              }
            });
          },
          {
            rootMargin: '0px 0px 10px 0px',
            threshold: 1,
          }
        );

        intersection.observe(productListElement);
      }
    }
  };

  useEffect(() => {
    if (displayProducts.length === 0) {
      afterRender?.();
    }
  }, [displayProducts]);

  const firstLoadRef = useRef<boolean>(true);

  if (!loadingAdditional && firstLoadRef.current) {
    firstLoadRef.current = false;
  }

  if (firstLoadRef.current) {
    const productListType = viewAsGridMultiColListOption === 'list' ? 'list' : 'grid';
    const numberOfCols =
      viewAsGridMultiColListOption === 'list'
        ? 1
        : Number(viewAsGridMultiColListOption.split('--')[1]);

    return <ProductListPlaceholder columns={numberOfCols} productListType={productListType} />;
  }

  return (
    <ProductListPagination {...productListPaginationProps}>
      <div
        ref={productListElementRef}
        className={classnames(
          'product-list',
          className,
          getColClassName(),
          borderLayout === 'noBorder' && 'product-list--margin',
          borderLayout === 'hasPadding' && 'product-list--margin',
          borderLayout === 'noPadding' && 'product-list--border-no-margin',
          borderLayout === 'noPaddingImage' && 'product-list--border-no-margin'
        )}
      >
        <DeferredList
          delay={0}
          runOnceWhenItemsAvailable
          items={displayProducts}
          disableDeferredRender={disableDeferredRender}
          afterComplete={afterDeferredListRender}
          onItemRender={(product, index) => (
            <ProductItem
              autoAspectRatio={`1/${autoAspectRatio}`}
              key={`${product.id} ${product?.variant_id || ''}`}
              product={product}
              onClick={() => trackPosition(index, product.id)}
              onRender={
                viewAsOptionProps.viewAsOption === 'list'
                  ? function (productItemProps) {
                      return (
                        <ProductItemListViewLayout
                          {...productItemProps}
                          noImageUrl={no_image_url}
                          hasCollection={hasCollection}
                        />
                      );
                    }
                  : undefined
              }
            />
          )}
        />
      </div>
    </ProductListPagination>
  );
};

export default registryComponent('ProductList', ProductList);
