import './SearchResultPanelContent.scss';

import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import type { PaginationPageLinkProps } from '@components/PaginationPageLink';
import { buildUrlWithLocalization, createClsNameMap, isMobileWidth } from '@utils';

import type { SearchResultPanelContentType } from './types';

export type SearchResultPanelContentProps = {
  content?: SearchResultPanelContentType[];
  type: 'collection' | 'page';
  showImage: boolean;
  showDescription: boolean;
  pagination: React.ReactElement<PaginationPageLinkProps>;
};

const clsNameMap = createClsNameMap({
  elements: {
    item: createClsNameMap({
      elements: {
        text: createClsNameMap(),
        image: createClsNameMap(),
        title: createClsNameMap(),
        description: createClsNameMap(),
      },
    }),
  },
})('search-result-panel-content');

const SearchResultPanelContent = ({
  content = [],
  type,
  showImage,
  showDescription,
  pagination,
}: SearchResultPanelContentProps) => {
  const getLink = (item: SearchResultPanelContentType) => {
    if ('url' in item) {
      /**
       * Avoid "//" created by buildUrlWithLocalization in url lead to an incorrect page
       */
      return item.url?.replace(/^\//g, '');
    } else {
      return `collections/${item.handle}`;
    }
  };

  const renderContentImage = (item: SearchResultPanelContentType) => {
    if (!showImage) return null;

    if ('image' in item) {
      if (item.image) {
        return (
          <div className={clsNameMap.elm('item.image')}>
            <img src={item.image.src} alt={item.image.alt} />
          </div>
        );
      }
    }

    if (isMobileWidth()) {
      return (
        <svg
          width='9'
          height='16'
          viewBox='0 0 9 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M0.494078 1.16058C0.819515 0.835145 1.34715 0.835145 1.67259 1.16058L7.92259 7.41058C8.24803 7.73602 8.24803 8.26366 7.92259 8.58909L1.67259 14.8391C1.34715 15.1645 0.819515 15.1645 0.494078 14.8391C0.168641 14.5137 0.168641 13.986 0.494078 13.6606L6.15482 7.99984L0.494078 2.33909C0.168641 2.01366 0.168641 1.48602 0.494078 1.16058Z'
            fill='#212121'
          />
        </svg>
      );
    }

    return null;
  };

  const renderContentDescription = (item: SearchResultPanelContentType) => {
    if (!showDescription) return null;

    return (
      <InnerHTMLRenderer
        className={clsNameMap.elm('item.description')}
        html={'summary_html' in item ? item.summary_html : item.body_html}
      />
    );
  };

  return (
    <div className={clsNameMap.root()}>
      <>
        {content.map((item) => (
          <a
            href={buildUrlWithLocalization(getLink(item))}
            key={item.title}
            className={clsNameMap.elm('item')}
          >
            {renderContentImage(item)}
            <div className={clsNameMap.elm('item.text')}>
              <p className={clsNameMap.elm('item.title')}>{item.title}</p>
              {renderContentDescription(item)}
            </div>
          </a>
        ))}
        {pagination}
      </>
    </div>
  );
};

export default SearchResultPanelContent;
