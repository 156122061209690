import {
  AdditionalElementProvider,
  FilterProvider,
  FilterTreeWidget,
  getGeneralSettingsFromAdmin,
  isMobileWidth,
  isSearchPage,
  NoSearchResultPage,
  ProductListProvider,
  ProductListWidget,
  SearchForm,
  SearchProvider,
  SearchResultPanelWidget,
  ThemeProvider,
  ToolbarWidget,
  useAdditionalElementFilterResult,
  useFilterSettings,
  useProductsFilterResult,
} from '@boost-sd/app';
import { useSyncExternalStore } from 'react';
import { createRoot } from 'react-dom/client';

import type { Block } from '../types';

export type ProductFilterSearchPageBlock = Block<{
  blockSelector: string;
}>;

const defaultConfig = {
  blockSelector: process.env.FILTER_BLOCK_SELECTOR || '',
};

export const createProductFilterSearchPageBlock = (
  config: ProductFilterSearchPageBlock['config'] = defaultConfig
): ProductFilterSearchPageBlock => {
  return {
    config: {
      ...defaultConfig,
      ...config,
    },
    render() {
      const { blockSelector } = {
        ...defaultConfig,
        ...config,
      };

      const generalSettings = getGeneralSettingsFromAdmin();

      const SearchPage = () => {
        const additionalElementSource = useAdditionalElementFilterResult();

        const productsSource = useProductsFilterResult();

        const { totalProducts = 0 } = useSyncExternalStore(
          productsSource.subscribe,
          productsSource.getSnapshot
        );

        const {
          filterLayout,
          filterTreeMobileStyle,
          filterTreeHorizontalStyle,
          filterTreeVerticalStyle,
        } = useFilterSettings();

        const isMobileExpandStyle = filterTreeMobileStyle === 'style1' && isMobileWidth();
        const isFilterVertical = filterLayout === 'vertical';
        const isFilterVerticalStyleExpand =
          isFilterVertical && filterTreeVerticalStyle === 'style-expand';
        const isFilterHorizontal = filterLayout === 'horizontal';
        const isFilterHorizontalStyleExpand =
          isFilterHorizontal && filterTreeHorizontalStyle === 'style-expand';
        const isFilterStyleExpand =
          !isMobileWidth() && (isFilterVerticalStyleExpand || isFilterHorizontalStyleExpand);

        const filterLayoutStyle =
          (isFilterVertical && filterTreeVerticalStyle) ||
          (isFilterHorizontal && filterTreeHorizontalStyle);

        return (
          <div className='boost-sd-container boost-sd-container-search-page'>
            <AdditionalElementProvider additionalElementStore={additionalElementSource}>
              <SearchForm />
              <SearchResultPanelWidget
                productTab={
                  <>
                    <div className='boost-sd-toolbar-product-tab'>
                      {isFilterStyleExpand && (
                        <ToolbarWidget
                          filterTree={isMobileExpandStyle ? <FilterTreeWidget /> : undefined}
                        />
                      )}
                    </div>
                    <div
                      className={`boost-sd-layout boost-sd-layout--has-filter-${filterLayout} boost-sd-layout--has-${filterLayout}-${filterLayoutStyle}`}
                    >
                      <div className={'boost-sd-left'}>
                        {!isMobileExpandStyle ? <FilterTreeWidget /> : undefined}
                      </div>

                      <div
                        className={`boost-sd-right ${
                          totalProducts === 0 ? 'boost-sd-no-search-result' : ''
                        }`}
                      >
                        {!isFilterStyleExpand && (
                          <ToolbarWidget
                            filterTree={isMobileExpandStyle ? <FilterTreeWidget /> : undefined}
                          />
                        )}

                        <ProductListProvider productsStore={productsSource}>
                          <ProductListWidget />
                        </ProductListProvider>

                        {totalProducts === 0 ? <NoSearchResultPage /> : null}
                      </div>
                    </div>
                  </>
                }
              />
            </AdditionalElementProvider>
          </div>
        );
      };

      if (
        isSearchPage() ||
        (generalSettings.preview_mode && generalSettings.preview_path === '/search')
      ) {
        const elm = document.querySelector(blockSelector);

        if (elm) {
          const root = createRoot(elm);
          root.render(
            <SearchProvider>
              <ThemeProvider>
                <FilterProvider>
                  <SearchPage />
                </FilterProvider>
              </ThemeProvider>
            </SearchProvider>
          );
        }
      }
    },
  };
};
