import './ShowLimitList.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import PlaceholderItem from '@components/PlaceHolderItem';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import { useFilterState } from '@providers/FilterProvider';
import { createClsNameMap, mapModifiers } from '@utils';
import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

export const clsNameMap = createClsNameMap({
  modifiers: ['inline'],
  elements: {
    label: createClsNameMap(),
    button: createClsNameMap({
      modifiers: ['active'],
    }),
    list: createClsNameMap({
      modifiers: ['active'],
    }),
    option: createClsNameMap({
      modifiers: ['active'],
    }),
  },
})('show-limit-list');

export type ShowLimitListProps = {
  itemPerPageOptions: Array<number>;
  isInLineStyle?: boolean;
  isLoading: boolean;
};

const ShowLimitList = ({ itemPerPageOptions, isInLineStyle, isLoading }: ShowLimitListProps) => {
  const [isOpenList, setIsOpenList] = useState(false);

  const { limit } = useFilterState();

  const { onChangeFilterLimit } = useAdditionalElementState();

  const elmRef = useRef<HTMLDivElement | null>(null);

  const handleOnchange = (itemPerPageValue: number) => {
    setIsOpenList(false);
    onChangeFilterLimit?.(itemPerPageValue);
  };

  useOnClickOutside(elmRef, () => {
    setIsOpenList(false);
  });

  if (isLoading) {
    return <PlaceholderItem modifiers={['limit']} />;
  }

  return (
    <div className={mapModifiers(clsNameMap, { inline: isInLineStyle })}>
      <div
        className={mapModifiers(clsNameMap.button, { active: isOpenList })}
        onClick={() => setIsOpenList(!isOpenList)}
        ref={elmRef}
      >
        <Trans i18nKey={'perpage.productCountPerPage'} values={{ count: limit }} />
        <ul className={mapModifiers(clsNameMap.list, { active: isOpenList })}>
          {itemPerPageOptions.map((item: number) => {
            return (
              <li
                className={mapModifiers(clsNameMap.option, {
                  active: limit === item,
                })}
                key={item}
                onClick={() => handleOnchange(item)}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default registryComponent('ShowLimitList', ShowLimitList);

ShowLimitList.defaultProps = {
  isInLineStyle: false,
};
