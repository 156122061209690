import './SearchResultToolbar.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import useTranslation from '@hooks/useTranslation';
import type { SearchResultToolbarPanel } from '@providers/AdditionalElementProvider';
import { createClsNameMap, mapModifiers } from '@utils';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

import type { SearchResultElement } from '@/widgets/SearchResultPanels';

export type SearchResultToolbarProps = {
  totalProducts?: number;
  totalCollections?: number;
  totalPages?: number;
  orders: SearchResultElement[];
  searchResultSelectedPanel: SearchResultElement;
  setSearchResultSelectedPanel: Dispatch<SetStateAction<SearchResultElement>>;
};

const clsNameMap = createClsNameMap({
  elements: {
    container: createClsNameMap({}),
    item: createClsNameMap({}),
    'panel-wrapper': createClsNameMap({}),
    panel: createClsNameMap({
      modifiers: ['active'],
    }),
  },
})('search-result-toolbar');

const SearchResultToolbar = ({
  totalProducts = 0,
  totalCollections = 0,
  totalPages = 0,
  orders,
  searchResultSelectedPanel,
  setSearchResultSelectedPanel,
}: SearchResultToolbarProps) => {
  const { t } = useTranslation();

  const totalNumber = useMemo(() => {
    if (searchResultSelectedPanel === 'product') return totalProducts;
    else if (searchResultSelectedPanel === 'collection') return totalCollections;
    else return totalPages;
  }, [searchResultSelectedPanel, totalProducts, totalCollections, totalPages]);

  const renderLabel = (panel: SearchResultToolbarPanel) => {
    if (panel === 'product') {
      const label = t('search.searchPanelProduct');
      return `${label} (${totalProducts})`;
    } else if (panel === 'collection') {
      const label = t('search.searchPanelCollection');
      return `${label} (${totalCollections})`;
    } else {
      const label = t('search.searchPanelPage');
      return `${label} (${totalPages})`;
    }
  };

  const renderPanels = () => {
    return orders.map((panel) => {
      return (
        <div
          key={panel}
          className={mapModifiers(clsNameMap.panel, {
            active: searchResultSelectedPanel === panel,
          })}
          onClick={() => setSearchResultSelectedPanel(panel)}
        >
          {renderLabel(panel)}
        </div>
      );
    });
  };

  return (
    <div className={clsNameMap.elm('container')}>
      <div className={clsNameMap.root()}>
        <div className={clsNameMap.elm('item')}>
          <div className={clsNameMap.elm('panel-wrapper')}>{renderPanels()}</div>
        </div>

        <div className={clsNameMap.elm('item')}>
          <Trans
            i18nKey={totalNumber > 1 ? 'search.searchTotalResults' : 'search.searchTotalResult'}
            values={{ count: totalNumber }}
          />
        </div>
      </div>
    </div>
  );
};

export default registryComponent('SearchResultToolbar', SearchResultToolbar);
