import { useProductListThemeSettings } from '@providers/ThemeProvider/Provider/ProductListThemeSettings';
import { isMobileWidth } from '@utils';
import React, { useContext, useEffect, useState, useSyncExternalStore } from 'react';

import type {
  AdditionalElementStateProviderProps,
  AdditionalElementStateValue,
  ViewAsGridMultiColListOption,
} from '../types';

const AdditionalElementStateContext = React.createContext<AdditionalElementStateValue | null>(null);

export const AdditionalElementStateProvider = ({
  additionalElementStore,
  children,
}: AdditionalElementStateProviderProps) => {
  const {
    loadingAdditional,
    page,
    limit,
    totalPages,
    totalProducts,
    currentProductCount,
    fromProductIndex,
    toProductIndex,
    sortBy,
    searchPanelTotalCollections,
    searchPanelTotalPages,
    onChangeFilterLimit,
    onChangeFilterSortBy,
  } = useSyncExternalStore(additionalElementStore.subscribe, additionalElementStore.getSnapshot);

  const { productsPerRowOnDesktop, productsPerRowOnMobile } = useProductListThemeSettings();

  const viewAsDefaultCol = `grid--${
    isMobileWidth() ? productsPerRowOnMobile : productsPerRowOnDesktop
  }` as ViewAsGridMultiColListOption;

  const [viewAsGridMultiColListOption, setViewAsGridMultiColListOption] =
    useState<ViewAsGridMultiColListOption>(viewAsDefaultCol);

  useEffect(() => {
    setViewAsGridMultiColListOption(viewAsDefaultCol);
  }, [productsPerRowOnDesktop, productsPerRowOnMobile]);

  const providerValue = {
    loadingAdditional,
    page,
    limit,
    totalPages,
    totalProducts,
    currentProductCount,
    fromProductIndex,
    toProductIndex,
    sortBy,
    viewAsGridMultiColListOption,
    viewAsDefaultCol,
    setViewAsGridMultiColListOption,
    onChangeFilterLimit,
    onChangeFilterSortBy,
    searchPanelTotalCollections,
    searchPanelTotalPages,
  };

  return (
    <AdditionalElementStateContext.Provider value={providerValue}>
      {children}
    </AdditionalElementStateContext.Provider>
  );
};

export const useAdditionalElementState = (): AdditionalElementStateValue => {
  const context = useContext(AdditionalElementStateContext);
  if (!context) {
    throw Error('Use useAdditionalElementState in AdditionalElementStateProvider');
  }

  return context;
};
