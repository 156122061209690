import { registryComponent } from '@boost-sd/components-registry/registry';
import { useMemo } from 'react';

/**
 * @param totalPageCount: The total page number
 * @param neightborCount: The number of additional page numbers to show on each side of the current page
 * @param currentPage - The current page number
 */
export type PaginationRangeProps = {
  currentPage: number;
  totalPageCount: number;
  neighborCount?: number;
  onPaginationNumberRender: (
    page: number | string,
    index: number
  ) => React.ReactNode | React.ReactNode[];
};

/**
 * For ex: We have totalPageCount 10 pages and we set neighborCount to 1
 * Given that the current page is 6
 * The pagination control will look like the following:
 *
 * (1) < {5} [6] {7} > (10)
 *
 * (x) => terminal pages: first and last page(always visible)
 * [x] => represents current page
 * {...x} => represents page neighbours
 */
const PaginationRange = ({
  currentPage,
  totalPageCount,
  neighborCount = 1,
  onPaginationNumberRender,
}: PaginationRangeProps) => {
  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = neighborCount * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    const leftNeighborIndex = Math.max(currentPage - neighborCount, 1);
    const rightNeighborIndex = Math.min(currentPage + neighborCount, totalPageCount);

    const shouldShowLeftDots = leftNeighborIndex > 3;
    const shouldShowRightDots = rightNeighborIndex < totalPageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    switch (true) {
      /*
      Case 1: If the number of pages is less than the page numbers we want to show, return the range [1..totalPageCount]
      */
      case totalPageCount < totalBlocks: {
        const mid = Math.ceil(totalPageCount / 2);

        if (currentPage < mid && totalPageCount > Math.ceil(totalBlocks / 2)) {
          const leftRange = range(1, mid);
          return [...leftRange, '...', totalPageCount];
        }

        return range(1, totalPageCount);
      }

      /*
        Case 2: No left dots to show, but rights dots to be shown
      */
      case !shouldShowLeftDots && shouldShowRightDots: {
        let leftItemCount = 2 + 2 * neighborCount;

        if (currentPage === 4) {
          leftItemCount++;
        }
        const leftRange = range(1, leftItemCount);

        return [...leftRange, '...', totalPageCount];
      }

      /*
        Case 3: No right dots to show, but left dots to be shown
      */
      case shouldShowLeftDots && !shouldShowRightDots: {
        let rightItemCount = 2 + 2 * neighborCount;

        if (currentPage === totalPageCount - 3) {
          rightItemCount++;
        }
        const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
        return [firstPageIndex, '...', ...rightRange];
      }

      /*
        Case 4: Both left and right dots to be shown
      */
      case shouldShowLeftDots && shouldShowRightDots: {
        const middleRange = range(leftNeighborIndex, rightNeighborIndex);
        return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
      }
    }
  }, [totalPageCount, neighborCount, currentPage]);

  return <>{paginationRange?.map(onPaginationNumberRender)}</>;
};

export default registryComponent('PaginationRange', PaginationRange);
