import './ProductListPlaceholder.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import PlaceholderItem from '@components/PlaceHolderItem';
import type { ModifiersType } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils';
import { Fragment } from 'react';

export type ProductListPlaceholderProps = {
  productListType: 'grid' | 'list';
  columns?: number;
  rows?: number;
};

export const clsNameMap = createClsNameMap({
  elements: {
    item: createClsNameMap(),
    text: createClsNameMap(),
    image: createClsNameMap(),
    title: createClsNameMap(),
    vendor: createClsNameMap(),
    price: createClsNameMap(),
    description: createClsNameMap(),
  },
  modifiers: ['1-col', '2-col', '3-col', '4-col', '5-col', '6-col', 'list-col'],
})('product-list-placeholder');

const ProductListPlaceholder = ({
  columns = 4,
  rows = 4,
  productListType,
}: ProductListPlaceholderProps) => {
  /**
   * List View
   */
  if (productListType === 'list') {
    return (
      <div className={mapModifiers(clsNameMap, ['list-col'])}>
        <div className={clsNameMap.elm('item')}>
          <div className={clsNameMap.elm('image')}></div>
          <div className={clsNameMap.elm('text')}>
            <PlaceholderItem modifiers={['width4']} />
            <PlaceholderItem modifiers={['width3']} />
            <PlaceholderItem modifiers={['width1']} />
            <PlaceholderItem modifiers={['width2']} />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Grid View
   */
  return (
    <div
      className={mapModifiers(clsNameMap, [
        `${columns}-col` as ModifiersType<typeof clsNameMap>[0],
      ])}
    >
      {Array.from({ length: rows }, (_, i) => (
        <Fragment key={i}>
          {Array.from({ length: columns }, (_, i) => (
            <div className={clsNameMap.elm('item')} key={i}>
              <div className={clsNameMap.elm('image')}></div>
              <PlaceholderItem modifiers={['width4']} />
              <PlaceholderItem modifiers={['width3']} />
              <PlaceholderItem modifiers={['width1']} />
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default registryComponent('ProductListPlaceholder', ProductListPlaceholder);
