import './PaginationPageLink.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import type { PaginationButtonProps } from '@components/PaginationButton';
import PaginationNumber from '@components/PaginationNumber';
import PaginationRange from '@components/PaginationRange';
import type { PaginationNumberStyleSettings } from '@providers/ThemeProvider/types';
import type { ModifiersType } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils';

const clsNameMap = createClsNameMap({
  modifiers: ['left', 'center', 'right'],
})('pagination');

export type PaginationPageLinkProps = {
  currentPage: number;
  totalPages: number;
  alignmentModifier?: ModifiersType<typeof clsNameMap>;
  onChangePage?: (page: number) => unknown;
  prevButton?: React.ReactElement<PaginationButtonProps>;
  nextButton?: React.ReactElement<PaginationButtonProps>;
  paginationNumberSettings?: PaginationNumberStyleSettings;
  onPageRangeRender?: ReactNodeRenderer;
  onPrevButtonRender?: ReactNodeRenderer;
  onNextButtonRender?: ReactNodeRenderer;
};

const PaginationPageLink = ({
  currentPage,
  totalPages,
  alignmentModifier,
  onChangePage,
  prevButton,
  nextButton,
  onPageRangeRender,
  onPrevButtonRender,
  onNextButtonRender,
  ...paginationNumberSettings
}: PaginationPageLinkProps) => {
  const handleOnChangePage = (page: number) => {
    if (onChangePage) {
      onChangePage(page);
    }
  };
  if (totalPages <= 1) return <></>;

  return (
    <div className={mapModifiers(clsNameMap, alignmentModifier)}>
      <CustomizableNode renderer={onPrevButtonRender}>{prevButton}</CustomizableNode>

      <CustomizableNode renderer={onPageRangeRender}>
        <PaginationRange
          currentPage={currentPage}
          totalPageCount={totalPages}
          onPaginationNumberRender={(page, index) => (
            <PaginationNumber
              key={index}
              page={page}
              active={page === currentPage}
              disabled={page === '...'}
              onClick={() => handleOnChangePage(page as number)}
              {...paginationNumberSettings}
            />
          )}
        />
      </CustomizableNode>

      <CustomizableNode renderer={onNextButtonRender}>{nextButton}</CustomizableNode>
    </div>
  );
};
export default registryComponent('PaginationPageLink', PaginationPageLink);
