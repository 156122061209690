import './ProductCount.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import PlaceholderItem from '@components/PlaceHolderItem';
import { appendClassNames, createClsNameMap, isMobileWidth } from '@utils';
import { useTranslation } from 'react-i18next';
export type ProductCountProps = {
  locatedIn: 'CollectionHeader' | 'Toolbar' | 'Pagination';
  totalProducts: number;
  fromProductIndex?: number;
  toProductIndex?: number;
  className?: string;
  color?: string;
  fontSize?: number | string;
  textAlign?: 'left' | 'center' | 'right';
  isLoading?: boolean;
};

const clsNameMap = createClsNameMap()('product-count');

const ProductCount = ({
  locatedIn,
  totalProducts,
  fromProductIndex,
  toProductIndex,
  className,
  color,
  fontSize,
  textAlign,
  isLoading,
}: ProductCountProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <PlaceholderItem modifiers={['width3', 'product-count']} />;
  }

  if ((!totalProducts || !fromProductIndex || !toProductIndex) && !isMobileWidth()) {
    return <></>;
  }

  const style: React.CSSProperties = {
    color,
    fontSize,
    textAlign,
  };

  const renderProductCount = () => {
    const isPlural = totalProducts > 1;

    const translationKey = `productCount.textDescription${locatedIn}${isPlural ? 'Plural' : ''}`;

    return t(translationKey, {
      from: fromProductIndex,
      to: toProductIndex,
      total: totalProducts,
      count: totalProducts,
    });
  };

  return (
    <div className={appendClassNames(clsNameMap.root(), className)} style={style}>
      {renderProductCount()}
    </div>
  );
};

export default registryComponent('ProductCount', ProductCount);
