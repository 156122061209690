import './PaginationNumber.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { isMobileWidth } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils/classnames';
import { useEffect, useMemo, useState } from 'react';

export type PaginationNumberProps = {
  key?: number | string;
  page: number | string;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => unknown;

  color?: string;
  colorOnSelected?: string;
  fontFamily?: string;
  fontSize?: string | number;
  fontStyle?: string;
  fontWeight?: string | number;
  shape?: 'square' | 'circle';
  backgroundColor?: string;
  backgroundColorOnSelected?: string;
};

const clsNameMap = createClsNameMap({
  modifiers: ['square', 'circle', 'disabled', 'active', 'mobile'],
})('pagination-number');

const PaginationNumber = ({
  page,
  active,
  disabled,
  onClick,
  color,
  colorOnSelected,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  shape = 'square',
  backgroundColor,
  backgroundColorOnSelected,
}: PaginationNumberProps) => {
  const sharedStyles = {
    fontFamily,
    fontSize: isMobileWidth() ? '10px' : fontSize || 'inherit',
    fontStyle,
    fontWeight,
  };

  const stylesOfButton = {
    default: {
      color: disabled ? '#000' : color,
      backgroundColor,
      ...sharedStyles,
    },
  };

  const [numberStyle, setNumberStyle] = useState<
    Pick<
      PaginationNumberProps,
      'color' | 'fontFamily' | 'fontSize' | 'fontStyle' | 'fontWeight' | 'backgroundColor'
    >
  >(stylesOfButton.default);

  useEffect(() => {
    setNumberStyle(stylesOfButton.default);
  }, [
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    color,
    colorOnSelected,
    backgroundColor,
    backgroundColorOnSelected,
    disabled,
  ]);

  const activeState = useMemo(() => {
    if (active) {
      return {
        color: colorOnSelected,
        backgroundColor: backgroundColorOnSelected,
      };
    }
  }, [active, colorOnSelected, backgroundColorOnSelected]);

  const getNumberModifiers = () => {
    return {
      active,
      disabled,
      [shape as string]: true,
      mobile: isMobileWidth(),
    };
  };

  return (
    <button
      key={page}
      className={mapModifiers(clsNameMap, getNumberModifiers())}
      onClick={onClick}
      style={{ ...numberStyle, ...activeState }}
    >
      {page}
    </button>
  );
};

export default registryComponent('PaginationNumber', PaginationNumber);
