import './FilterTreeToggleButton.scss';

import PlaceholderItem from '@components/PlaceHolderItem';
import useTranslation from '@hooks/useTranslation';
import { useFilterSettings } from '@providers/FilterProvider/Provider/FilterSettings';
import { useFilterTreeState } from '@providers/FilterProvider/Provider/FilterTree';
import { createClsNameMap, isMobileWidth } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    icon: createClsNameMap(),
    title: createClsNameMap(),
  },
})('filter-tree-toggle-button');

export type FilterTreeToggleButtonProps = {
  isLoading: boolean;
};

const FilterTreeToggleButton = ({ isLoading }: FilterTreeToggleButtonProps) => {
  const { t } = useTranslation();

  const {
    setFilterTreeVisible,
    filterTreeState: { filterTreeVisible },
  } = useFilterTreeState();

  const {
    filterTreeVerticalStyle,
    filterTreeHorizontalStyle,
    filterLayout,
    changeMobileButtonLabel,
  } = useFilterSettings();

  const onButtonClick = () => {
    setFilterTreeVisible(!filterTreeVisible);
  };

  if (
    !isMobileWidth() &&
    ((filterLayout === 'vertical' && filterTreeVerticalStyle === 'style-default') ||
      (filterLayout === 'horizontal' && filterTreeHorizontalStyle !== 'style-expand'))
  )
    return null;

  const renderLabelRefine = () => {
    if (isMobileWidth()) {
      if (changeMobileButtonLabel && filterTreeVisible) {
        return t('refineMobileCollapse');
      }

      return t('refineMobile');
    }

    return t('refineDesktop');
  };

  return (
    <button type='button' className={clsNameMap.root()} onClick={onButtonClick}>
      <span className={clsNameMap.elm('icon')}>
        {filterTreeVisible ? (
          <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.13062 8.00003L12.565 4.56563C12.8778 4.25283 12.8778 3.74723 12.565 3.43443C12.2522 3.12163 11.7466 3.12163 11.4338 3.43443L7.99942 6.86883L4.56502 3.43443C4.25222 3.12163 3.74662 3.12163 3.43382 3.43443C3.12102 3.74723 3.12102 4.25283 3.43382 4.56563L6.86822 8.00003L3.43382 11.4344C3.12102 11.7472 3.12102 12.2528 3.43382 12.5656C3.58982 12.7216 3.79462 12.8 3.99942 12.8C4.20422 12.8 4.40902 12.7216 4.56502 12.5656L7.99942 9.13123L11.4338 12.5656C11.5898 12.7216 11.7946 12.8 11.9994 12.8C12.2042 12.8 12.409 12.7216 12.565 12.5656C12.8778 12.2528 12.8778 11.7472 12.565 11.4344L9.13062 8.00003Z'
              fill='#222222'
            ></path>
            <mask id='mask0_33:1711' maskUnits='userSpaceOnUse' x='3' y='3' width='10' height='10'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.13062 8.00003L12.565 4.56563C12.8778 4.25283 12.8778 3.74723 12.565 3.43443C12.2522 3.12163 11.7466 3.12163 11.4338 3.43443L7.99942 6.86883L4.56502 3.43443C4.25222 3.12163 3.74662 3.12163 3.43382 3.43443C3.12102 3.74723 3.12102 4.25283 3.43382 4.56563L6.86822 8.00003L3.43382 11.4344C3.12102 11.7472 3.12102 12.2528 3.43382 12.5656C3.58982 12.7216 3.79462 12.8 3.99942 12.8C4.20422 12.8 4.40902 12.7216 4.56502 12.5656L7.99942 9.13123L11.4338 12.5656C11.5898 12.7216 11.7946 12.8 11.9994 12.8C12.2042 12.8 12.409 12.7216 12.565 12.5656C12.8778 12.2528 12.8778 11.7472 12.565 11.4344L9.13062 8.00003Z'
                fill='white'
              ></path>
            </mask>
            <g mask='url(#mask0_33:1711)'></g>
          </svg>
        ) : (
          <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M0.8 3.2H3.348C3.6792 4.1288 4.5584 4.8 5.6 4.8C6.6416 4.8 7.5208 4.1288 7.852 3.2H15.2C15.6424 3.2 16 2.8424 16 2.4C16 1.9576 15.6424 1.6 15.2 1.6H7.852C7.5208 0.6712 6.6416 0 5.6 0C4.5584 0 3.6792 0.6712 3.348 1.6H0.8C0.3576 1.6 0 1.9576 0 2.4C0 2.8424 0.3576 3.2 0.8 3.2ZM15.2 12.8H7.852C7.5208 11.8712 6.6416 11.2 5.6 11.2C4.5584 11.2 3.6792 11.8712 3.348 12.8H0.8C0.3576 12.8 0 13.1576 0 13.6C0 14.0424 0.3576 14.4 0.8 14.4H3.348C3.6792 15.3288 4.5584 16 5.6 16C6.6416 16 7.5208 15.3288 7.852 14.4H15.2C15.6424 14.4 16 14.0424 16 13.6C16 13.1576 15.6424 12.8 15.2 12.8ZM15.2 7.2H12.652C12.3208 6.2712 11.4416 5.6 10.4 5.6C9.3584 5.6 8.4792 6.2712 8.148 7.2H0.8C0.3576 7.2 0 7.5576 0 8C0 8.4424 0.3576 8.8 0.8 8.8H8.148C8.4792 9.7288 9.3584 10.4 10.4 10.4C11.4416 10.4 12.3208 9.7288 12.652 8.8H15.2C15.6424 8.8 16 8.4424 16 8C16 7.5576 15.6424 7.2 15.2 7.2ZM5.6 1.6C6.0416 1.6 6.4 1.9584 6.4 2.4C6.4 2.8416 6.0416 3.2 5.6 3.2C5.1584 3.2 4.8 2.8416 4.8 2.4C4.8 1.9584 5.1584 1.6 5.6 1.6ZM5.6 14.4C5.1584 14.4 4.8 14.0416 4.8 13.6C4.8 13.1584 5.1584 12.8 5.6 12.8C6.0416 12.8 6.4 13.1584 6.4 13.6C6.4 14.0416 6.0416 14.4 5.6 14.4ZM10.4 8.8C9.9584 8.8 9.6 8.4416 9.6 8C9.6 7.5584 9.9584 7.2 10.4 7.2C10.8416 7.2 11.2 7.5584 11.2 8C11.2 8.4416 10.8416 8.8 10.4 8.8Z'
              fill='#222222'
            ></path>
            <mask
              id='mask0_8095:39389'
              maskUnits='userSpaceOnUse'
              x='0'
              y='0'
              width='16'
              height='16'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.8 3.2H3.348C3.6792 4.1288 4.5584 4.8 5.6 4.8C6.6416 4.8 7.5208 4.1288 7.852 3.2H15.2C15.6424 3.2 16 2.8424 16 2.4C16 1.9576 15.6424 1.6 15.2 1.6H7.852C7.5208 0.6712 6.6416 0 5.6 0C4.5584 0 3.6792 0.6712 3.348 1.6H0.8C0.3576 1.6 0 1.9576 0 2.4C0 2.8424 0.3576 3.2 0.8 3.2ZM15.2 12.8H7.852C7.5208 11.8712 6.6416 11.2 5.6 11.2C4.5584 11.2 3.6792 11.8712 3.348 12.8H0.8C0.3576 12.8 0 13.1576 0 13.6C0 14.0424 0.3576 14.4 0.8 14.4H3.348C3.6792 15.3288 4.5584 16 5.6 16C6.6416 16 7.5208 15.3288 7.852 14.4H15.2C15.6424 14.4 16 14.0424 16 13.6C16 13.1576 15.6424 12.8 15.2 12.8ZM15.2 7.2H12.652C12.3208 6.2712 11.4416 5.6 10.4 5.6C9.3584 5.6 8.4792 6.2712 8.148 7.2H0.8C0.3576 7.2 0 7.5576 0 8C0 8.4424 0.3576 8.8 0.8 8.8H8.148C8.4792 9.7288 9.3584 10.4 10.4 10.4C11.4416 10.4 12.3208 9.7288 12.652 8.8H15.2C15.6424 8.8 16 8.4424 16 8C16 7.5576 15.6424 7.2 15.2 7.2ZM5.6 1.6C6.0416 1.6 6.4 1.9584 6.4 2.4C6.4 2.8416 6.0416 3.2 5.6 3.2C5.1584 3.2 4.8 2.8416 4.8 2.4C4.8 1.9584 5.1584 1.6 5.6 1.6ZM5.6 14.4C5.1584 14.4 4.8 14.0416 4.8 13.6C4.8 13.1584 5.1584 12.8 5.6 12.8C6.0416 12.8 6.4 13.1584 6.4 13.6C6.4 14.0416 6.0416 14.4 5.6 14.4ZM10.4 8.8C9.9584 8.8 9.6 8.4416 9.6 8C9.6 7.5584 9.9584 7.2 10.4 7.2C10.8416 7.2 11.2 7.5584 11.2 8C11.2 8.4416 10.8416 8.8 10.4 8.8Z'
                fill='white'
              ></path>
            </mask>
            <g mask='url(#mask0_8095:39389)'></g>
          </svg>
        )}
      </span>
      <span className={clsNameMap.elm('title')}>
        {isLoading ? (
          <PlaceholderItem modifiers={['width4', 'text-toggle']} />
        ) : (
          renderLabelRefine()
        )}
      </span>
    </button>
  );
};

export default FilterTreeToggleButton;
