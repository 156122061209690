import './Toolbar.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import ProductCount from '@components/ProductCount';
import ShowLimitList from '@components/ShowLimitList';
import Sorting from '@components/Sorting';
import ViewAs from '@components/ViewAs';
import useMountEffect from '@hooks/useMountEffect';
import type { ViewAsGridMultiColListOption } from '@providers/AdditionalElementProvider';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import { useFilterState } from '@providers/FilterProvider';
import { useFilterSettings } from '@providers/FilterProvider/Provider/FilterSettings';
import type { ModifiersType } from '@utils';
import { createClsNameMap, isMobileWidth, mapModifiers, mergeModifiers, objectKeys } from '@utils';
import classnames from '@utils/classnames';
import { debounce, uniq } from 'lodash-es';
import { useCallback, useState } from 'react';

import FilterTreeToggleButton from '@/widgets/FilterTree/ToggleButton';

import type { FilterTreeProps } from '../FilterTree';
import { useConnectToolbarElements } from './connectors/connectToolbarElements';
import { useConnectToolbarLayout } from './connectors/connectToolbarLayout';
import { useConnectToolbarShowLimitList } from './connectors/connectToolbarShowLimitList';
import { useConnectToolbarSortingList } from './connectors/connectToolbarSortingList';

export const clsNameMap = createClsNameMap({
  elements: {
    'product-count': createClsNameMap(),
    'top-mobile': createClsNameMap(),
    item: createClsNameMap({
      modifiers: ['view-as', 'product-count', 'show-limit-list', 'sorting'],
    }),
    inner: createClsNameMap(),
    container: createClsNameMap({
      modifiers: ['hidden', 'style-expand'],
    }),
    content: createClsNameMap(),
  },
  modifiers: [
    '1_1',
    '1_2',
    '1_3',
    '2_1',
    '2_2',
    '2_3',
    '2_4',
    '3_1',
    '3_2',
    '3_3',
    '3_4',
    '4_1',
    '4_2',
  ],
})('toolbar');

export type ToolbarElement = 'viewAs' | 'productCount' | 'showLimitList' | 'sorting';

export type ToolbarProps = {
  toolbarModifiers?: ModifiersType<(typeof clsNameMap)['container'], true>;
  filterTree?: React.ReactElement<FilterTreeProps>;
};

const Toolbar = ({ toolbarModifiers, filterTree }: ToolbarProps) => {
  const { props: elements } = useConnectToolbarElements();
  const { props: layout } = useConnectToolbarLayout();
  const { props: showLimitList } = useConnectToolbarShowLimitList();
  const { props: sortingList } = useConnectToolbarSortingList();

  const { filterTreeVerticalStyle, enableFilter, filterTreeHorizontalStyle, filterLayout } =
    useFilterSettings();

  if (!toolbarModifiers) {
    toolbarModifiers = {};
  }

  const { loadingAdditional } = useAdditionalElementState();

  const [isMobile, setIsMobile] = useState<boolean>(() => isMobileWidth());
  const [firstLoad, setFirstLoad] = useState(true);

  const isLoading = loadingAdditional && firstLoad;

  const renderOrders: ToolbarElement[] = isMobile
    ? ['sorting', 'productCount', 'viewAs', 'showLimitList']
    : objectKeys(elements || {});

  const {
    viewAsDefaultCol,
    viewAsGridMultiColListOption,
    setViewAsGridMultiColListOption,
    totalProducts,
    fromProductIndex,
    toProductIndex,
    onChangeFilterSortBy,
  } = useAdditionalElementState();

  const { sortBy, filterData } = useFilterState();

  useMountEffect(() => {
    const debounceDetectDevice = debounce(() => {
      if (isMobileWidth()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }, 100);

    window.addEventListener('resize', debounceDetectDevice);
  });

  const handleChangeViewAsColOption = (option: ViewAsGridMultiColListOption) => {
    // register event in product list
    const bindProductListChangeEvent = new CustomEvent('product-list-change');
    window.dispatchEvent(bindProductListChangeEvent);
    setViewAsGridMultiColListOption(option);
  };

  const isShowToggleButtonDesktop = () => {
    if (!enableFilter || isMobile) return false;

    if (
      filterLayout === 'vertical' &&
      ['style-off-canvas', 'style-expand'].includes(filterTreeVerticalStyle)
    )
      return true;

    if (filterLayout === 'horizontal' && filterTreeHorizontalStyle === 'style-expand') return true;

    return false;
  };

  const isShowToggleButtonMobile = () => {
    if (enableFilter && isMobile) return true;

    return false;
  };

  if (!loadingAdditional && firstLoad) {
    setFirstLoad(false);
  }

  const renderElements = useCallback(() => {
    const renders: Record<ToolbarElement, JSX.Element> = {
      viewAs: (
        <>
          {elements?.viewAs || isMobile ? (
            <ViewAs
              type={elements?.viewAs?.listType}
              currentCol={viewAsGridMultiColListOption}
              defaultCol={viewAsDefaultCol}
              isLoading={isLoading}
              onChangeOption={handleChangeViewAsColOption}
            />
          ) : (
            <></>
          )}
        </>
      ),
      productCount:
        elements?.productCount || isMobile ? (
          <ProductCount
            totalProducts={totalProducts}
            fromProductIndex={fromProductIndex}
            toProductIndex={toProductIndex}
            locatedIn='Toolbar'
            isLoading={isLoading}
          />
        ) : (
          <></>
        ),
      showLimitList:
        elements?.showLimitList || isMobile ? (
          <ShowLimitList itemPerPageOptions={showLimitList || []} isLoading={isLoading} />
        ) : (
          <></>
        ),
      sorting: (
        <>
          <div className={clsNameMap.elm('top-mobile')}>
            {elements?.sorting || isMobile ? (
              <Sorting
                onSort={onChangeFilterSortBy}
                initSortValue={sortBy || ''}
                sortingList={sortingList || []}
                isLoading={isLoading}
              />
            ) : (
              <></>
            )}
            {isShowToggleButtonMobile() && <FilterTreeToggleButton isLoading={isLoading} />}
          </div>
          {filterTree}
        </>
      ),
    };

    const mapClass = {
      productCount: 'product-count',
      viewAs: 'view-as',
      showLimitList: 'show-limit-list',
      sorting: 'sorting',
    };

    return uniq(renderOrders)?.map((element) => (
      <div
        className={mapModifiers(clsNameMap.item, {
          [mapClass[element]]: true,
        })}
        key={element}
      >
        {renders[element]}
      </div>
    ));
  }, [
    isMobile,
    viewAsGridMultiColListOption,
    elements,
    totalProducts,
    fromProductIndex,
    toProductIndex,
    isLoading,
    sortBy,
  ]);

  if (
    !isMobile &&
    !isShowToggleButtonDesktop() &&
    (!elements || objectKeys(elements).length === 0)
  ) {
    return <></>;
  }

  if (
    objectKeys(elements).length === 1 &&
    elements?.productCount &&
    filterData.data?.total_product === 0
  ) {
    document.body.classList.add(classnames('toolbar-no-products'));
  } else {
    document.body.classList.remove(classnames('toolbar-no-products'));
  }

  return (
    <div
      className={mergeModifiers(clsNameMap.container, [
        toolbarModifiers,
        {
          'style-expand':
            filterLayout === 'horizontal' && filterTreeHorizontalStyle === 'style-expand',
        },
      ])}
    >
      <div className={clsNameMap.elm('inner')}>
        <div className={clsNameMap.elm('content')}>
          {isShowToggleButtonDesktop() && (
            <div>
              <FilterTreeToggleButton isLoading={isLoading} />
            </div>
          )}
          <div className={mapModifiers(clsNameMap, { [layout]: true })}>{renderElements()}</div>
        </div>
      </div>
    </div>
  );
};

export default registryComponent('Toolbar', Toolbar);
