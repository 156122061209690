import './ViewAs.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import PlaceholderItem from '@components/PlaceHolderItem';
import Tooltip from '@components/Tooltip';
import useTranslation from '@hooks/useTranslation';
import type {
  ViewAsGridMultiColListOption,
  ViewAsType,
} from '@providers/AdditionalElementProvider';
import { createClsNameMap, isDesktopWidth, isMobileWidth, mapModifiers } from '@utils';
import { Trans } from 'react-i18next';

export type ViewAsProps = {
  type?: ViewAsType;
  currentCol: ViewAsGridMultiColListOption;
  defaultCol: ViewAsGridMultiColListOption;
  isLoading: boolean;
  onChangeOption: (option: ViewAsGridMultiColListOption) => unknown;
  showViewAsMobile?: boolean;
};

export const clsNameMap = createClsNameMap({
  elements: {
    text: createClsNameMap(),
    icon: createClsNameMap({
      modifiers: [
        'active',
        'grid',
        'list',
        'grid--2',
        'grid--3',
        'grid--4',
        'grid--5',
        'grid--6',
        'list-grid',
        'list-grid-multi-col',
      ],
    }),
  },
})('view-as');

const ViewAs = ({
  type,
  currentCol,
  defaultCol,
  isLoading,
  onChangeOption,
  showViewAsMobile,
}: ViewAsProps) => {
  const { t } = useTranslation();

  const renderIcons = () => {
    if (isMobileWidth() && type !== 'grid/list' && !showViewAsMobile) {
      return <></>;
    }

    let to = 2;
    let hasListType = false;
    const viewAsItems = [];

    switch (type) {
      case 'grid/list': {
        return (
          <>
            <Tooltip content={t('gridView')}>
              <span
                onClick={() => onChangeOption(defaultCol)}
                className={mapModifiers(clsNameMap.icon, {
                  grid: true,
                  'list-grid': true,
                  active: currentCol !== 'list',
                })}
              ></span>
            </Tooltip>
            <Tooltip content={t('listView')}>
              <span
                onClick={() => onChangeOption('list')}
                className={mapModifiers(clsNameMap.icon, {
                  list: true,
                  'list-grid': true,
                  active: currentCol === 'list',
                })}
              ></span>
            </Tooltip>
          </>
        );
      }
      case '2,3,4columns': {
        to = 4;
        break;
      }
      case '2,3,4,5,6columns': {
        to = 6;
        break;
      }
      case '2,3,4columns/list': {
        to = 4;
        hasListType = true;
        break;
      }
      case '2,3,4,5,6columns/list': {
        to = 6;
        hasListType = true;
        break;
      }
    }

    for (let i = 2; i <= to; i++) {
      const gridCol = `grid--${i}`;

      viewAsItems.push(
        <Tooltip
          content={<Trans i18nKey={'gridViewColumns'} values={{ count: i }} />}
          key={gridCol}
        >
          <span
            onClick={() => onChangeOption(gridCol as ViewAsGridMultiColListOption)}
            className={mapModifiers(clsNameMap.icon, {
              [gridCol]: true,
              'list-grid-multi-col': true,
              active: currentCol === gridCol,
            })}
          ></span>
        </Tooltip>
      );
    }

    if (hasListType) {
      viewAsItems.push(
        <Tooltip content={t('listView')} key={'list'}>
          <span
            onClick={() => onChangeOption('list')}
            className={mapModifiers(clsNameMap.icon, {
              list: true,
              'list-grid-multi-col': true,
              active: currentCol === 'list',
            })}
          ></span>
        </Tooltip>
      );
    }

    return viewAsItems;
  };

  return (
    <div className={clsNameMap.root()}>
      {isLoading && isDesktopWidth() ? (
        <PlaceholderItem modifiers={['width1']} />
      ) : (
        <>
          <div className={clsNameMap.elm('text')}>{t('viewAs')}</div>
          {renderIcons()}
        </>
      )}
    </div>
  );
};
export default registryComponent('ViewAs', ViewAs);
