import './HeaderImage.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ModifiersTypeMap } from '@utils';
import { createClsNameMap, getSrcSet, mapModifiers } from '@utils';
import { useEffect, useRef } from 'react';

export const clsNameMap = createClsNameMap({
  elements: {
    inner: createClsNameMap(),
    overlay: createClsNameMap(),
  },
  modifiers: ['small', 'medium', 'large', 'original', 'absolute', 'as-main-1', 'as-main-3'],
})('header-image');

export type HeaderImageProps = {
  image?: string;
  altImage?: string;
  parallaxEffect?: boolean;
  imageSizeModifiers?: ModifiersTypeMap<typeof clsNameMap>;
  directionParallax?: 'horizontal' | 'vertical';
  overlayColor?: string;
  /**
   * Hint for browser current size of Collection Header
   */
  size?: number;
  /**
   * Define device sizes to generate srcSet
   */
  deviceSizes?: Array<number>;
  /**
   * Define image sizes corresponding to device sizes
   */
  imageSizes?: Array<number>;
};

const HeaderImage = ({
  image,
  altImage,
  imageSizeModifiers,
  parallaxEffect,
  directionParallax,
  overlayColor,
  size,
  deviceSizes = [800, 1000, 1200, 1600],
  imageSizes,
}: HeaderImageProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (imageSizeModifiers?.original) {
      imageRef.current?.setAttribute('style', `position: relative`);
      return;
    }
    const directionNumber = 5; // the number make direction parallax smooth
    const imageOffsetTop = imageRef.current?.getBoundingClientRect().top;
    const imageHeight = imageRef.current?.getBoundingClientRect().height;
    const parallaxValue =
      imageHeight !== undefined && imageOffsetTop !== undefined
        ? (imageHeight + imageOffsetTop) / directionNumber
        : undefined;

    if (parallaxEffect) {
      const parallaxStyles = `width: calc(100% + ${parallaxValue}px); height: calc(100% + ${parallaxValue}px);`;
      imageRef.current?.setAttribute('style', `${parallaxStyles}`);

      // The style will allow the parallax image to work without being affected by the style of the original image
      imageRef.current?.parentElement?.setAttribute('style', 'display: block');

      const listener = (e: Event) => {
        const translateValue =
          directionParallax === 'horizontal'
            ? `-${window.scrollY / directionNumber}px, 0px, 0px`
            : `0px, -${window.scrollY / directionNumber}px, 0px`;

        imageRef.current?.setAttribute(
          'style',
          `${parallaxStyles} 
          transform: translate3d(${translateValue})`
        );
      };

      window.addEventListener('scroll', listener);

      return () => {
        window.removeEventListener('scroll', listener);
      };
    } else {
      imageRef.current?.setAttribute('style', `position: absolute;`);
    }
  }, [parallaxEffect, imageSizeModifiers]);

  if (!image) return <></>;

  return (
    <div className={mapModifiers(clsNameMap, imageSizeModifiers)}>
      <div className={clsNameMap.elm('overlay')} style={{ backgroundColor: overlayColor }}>
        Overlay image
      </div>
      <img
        src={image}
        alt={altImage || ''}
        className={clsNameMap.elm('inner')}
        ref={imageRef}
        sizes={`${size}px`}
        srcSet={getSrcSet(image, deviceSizes, imageSizes)}
      />
    </div>
  );
};

export default registryComponent('HeaderImage', HeaderImage);
