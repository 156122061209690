import './Sorting.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import PlaceholderItem from '@components/PlaceHolderItem';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useTranslation from '@hooks/useTranslation';
import { createClsNameMap, isDesktopWidth, mapModifiers } from '@utils';
import { useRef, useState } from 'react';

export const clsNameMap = createClsNameMap({
  elements: {
    label: createClsNameMap(),
    button: createClsNameMap({
      modifiers: ['active'],
    }),
    list: createClsNameMap({
      modifiers: ['active'],
    }),
    option: createClsNameMap({
      modifiers: ['active'],
    }),
    value: createClsNameMap(),
  },
})('sorting');

export type SortingProps = {
  sortingList: string[];
  onSort?: (sortValue: string) => unknown;
  initSortValue: string;
  isLoading: boolean;
};

const Sorting = ({ onSort, initSortValue, sortingList, isLoading }: SortingProps) => {
  const { t } = useTranslation();

  const divElmRef = useRef<HTMLDivElement | null>(null);
  const sortValue = sortingList.includes(initSortValue) ? initSortValue : '';

  const [isOpenList, setIsOpenList] = useState(false);

  const handleSort = (sortValue: string) => {
    setIsOpenList(false);
    if (onSort) {
      onSort(sortValue);
    }
  };

  const formatSortLabel = (sortId: string) => {
    if (sortId === '') return '';

    return t(`sortingList.${sortId}`);
  };

  useOnClickOutside(divElmRef, () => {
    setIsOpenList(false);
  });

  if (isLoading && isDesktopWidth()) {
    return <PlaceholderItem modifiers={['width1', 'sorting']} />;
  }

  return (
    <div className={clsNameMap.root()}>
      <span className={clsNameMap.elm('label')}>{t('sortingList.sorting')}: </span>
      <div
        ref={divElmRef}
        className={mapModifiers(clsNameMap.button, { active: isOpenList })}
        onClick={() => {
          setIsOpenList(!isOpenList);
        }}
      >
        <span className={clsNameMap.elm('value')}>
          {isLoading ? (
            <PlaceholderItem modifiers={['width4', 'sorting']} />
          ) : (
            formatSortLabel(sortValue)
          )}
        </span>
        <ul className={mapModifiers(clsNameMap.list, { active: isOpenList })}>
          {sortingList.map((item: string) => {
            return (
              <li
                className={mapModifiers(clsNameMap.option, {
                  active: sortValue === item,
                })}
                key={item}
                data-value={item}
                onClick={() => handleSort(item)}
              >
                {formatSortLabel(item)}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default registryComponent('Sorting', Sorting);
