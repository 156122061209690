import { optionIdsOfRefineBy } from '@providers/FilterProvider';
import type { Dict } from '@types';

export const allParamsInUrl = () => {
  return new URLSearchParams(window.location.href);
};

export const checkExistFilterOptionParams = (filterParams: Dict) => {
  return optionIdsOfRefineBy(filterParams).length > 0;
};

export const decodeShortenValue = (value: string) => {
  if (!value || typeof value !== 'string' || !value.includes('+')) return value;

  // + -> ' '
  return value.replace(/[+]/g, ' ');
};
