import { FilterAPI } from '@apis/filter';
import { SearchAPI } from '@apis/search';
import { registryComponent } from '@boost-sd/components-registry/registry';
import { useUpdateEffect } from '@boost-sd/core-js';
import {
  getAppHistory,
  getQueryParamByKey,
  getQueryParamsState,
  removeQueryParam,
  setQueryParam,
  setQueryParams,
  useListenHistoryEvent,
} from '@boost-sd/core-js/history';
import { INITIAL_PAGE, NEXT_PAGE, PREV_PAGE } from '@constants/pagination';
import {
  SCOPED_CATEGORY,
  SCOPED_COLLECTION,
  SCOPED_PRODUCT_TYPE,
  SCOPED_VENDOR,
  scopedFilterParams,
} from '@constants/scopedSuggestion';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useMountEffect from '@hooks/useMountEffect';
import useStateAsExternalStore from '@hooks/useStateAsExternalStore';
import type { ProductItem } from '@providers/ProductProvider';
import {
  defaultAdditionalElementSettings,
  useAdditionalElementThemeSettings,
} from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import { useProductListThemeSettings } from '@providers/ThemeProvider/Provider/ProductListThemeSettings';
import type { Dict } from '@types';
import {
  checkExistFilterOptionParams,
  decodeShortenValue,
  getSessionStorage,
  getTermValueFromUrl,
  getWindowLocation,
  isBadUrl,
  isCollectionPage,
  isMobileWidth,
  isPageType,
  isSearchPage,
  isVendorPage,
  removeDefaultOptionsProducts,
  resetPaginationSessionStorage,
  setSessionStorage,
  slugify,
  stripHtml,
  toItemArray,
} from '@utils';
import { cloneDeep } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { clsNameMap as clsNameMapInCollectionSearch } from '@/widgets/InCollectionSearch';

import { FILTER_OPTION_DISPLAY_TYPE } from '../enum';
import type {
  CollectionHeader,
  CurrentCollectionFilterState,
  FilterData,
  FilterOptionType,
  FilterParams,
  FilterStateValue,
  LoadProductsBehavior,
  ScopedFilter,
  SharedAdditionalElementFilterData,
} from '../types';
import { useFilterSettings } from './FilterSettings';

const FilterStateContext = createContext<FilterStateValue | null>(null);

const getExtraParamsByFilterDisplayType = (filterOption: FilterOptionType) => {
  switch (filterOption.displayType) {
    case FILTER_OPTION_DISPLAY_TYPE.RATING: {
      return {
        [`${filterOption.filterOptionId}_show_exact_rating`]:
          filterOption.showExactRating || undefined,
      } as const;
    }

    default:
      return {};
  }
};

const getFilterPageByDefault = () => {
  return Number(getQueryParamByKey('page') || 1);
};

type FilterStateProviderProps = {
  showLimitOnUrl?: boolean;
};

const FilterStateProviderComponent = ({
  children,
  showLimitOnUrl = false,
}: PropsWithChildren<FilterStateProviderProps>) => {
  const {
    generalSettings: {
      collection_id,
      collection_handle,
      current_tags,
      collection_tags,
      customizeSearchParams,
      termKey,
    },
  } = useGeneralSettings();

  const currentCollectionSearchParam = getTermValueFromUrl(termKey);
  const {
    filterEverywhereCollectionId,
    availableAfterFiltering,
    showOutOfStockOption,
    productAndVariantAvailable,
    showVariantsAsProduct,
    priceMode,
    tagMode,
    sortingAvailableFirst,
    vendorParam,
    typeParam,
    filterPrefixParam,
    urlScheme,
    isShortenUrlParam,
    shortenUrlParamList,
    filterLayout,
    requestInstantly,
    ignoreCharacterURL,
  } = useFilterSettings();

  const {
    pagination: { paginationType },
  } = useAdditionalElementThemeSettings();

  const getCurrentCollectionId = () => {
    if (collection_handle === 'all' || !collection_id) return 0;

    return collection_id;
  };

  const [historyCollection, setHistoryCollection] = useState<Dict>({
    [slugify(collection_tags?.[0] || collection_handle || (Math.random() + 1).toString(36))]: {
      collectionId: collection_id,
      handle: collection_handle,
      tag: collection_tags?.[0] || undefined,
    },
  });

  const [scopedFilterType, setScopedFilterType] = useState<ScopedFilter | null>(null);

  const getParamMap = () => {
    const shortParamsMap = new Map();
    const longParamMap = new Map();

    if (
      isShortenUrlParam &&
      urlScheme !== 0 &&
      Array.isArray(shortenUrlParamList) &&
      shortenUrlParamList.length > 0
    ) {
      shortenUrlParamList.forEach((paramSetting) => {
        if (typeof paramSetting == 'string') {
          // 'paramSetting' has the format 'filter_option_id:short_id', for example: 'pf_opt_color:color'. Or 'namespace::filter_option_id:short_id'
          const separateMatch = paramSetting.match(/[^:]:[^:]/);
          let separateIndex = 0;
          if (separateMatch && separateMatch.index) separateIndex = separateMatch.index;
          if (!separateIndex) return;

          const filterOptionParam = paramSetting.slice(0, separateIndex + 1);
          const shortFilterOptionParam = paramSetting.slice(separateIndex + 2);

          if (filterOptionParam && shortFilterOptionParam) {
            shortParamsMap.set(filterOptionParam, shortFilterOptionParam);
            longParamMap.set(shortFilterOptionParam, filterOptionParam);
          }
        }
      });
    }

    return {
      shortParamsMap,
      longParamMap,
    };
  };

  const [{ longParamMap, shortParamsMap }] = useState(() => {
    return getParamMap();
  });

  // covert URL params from long to short
  const longToShortFilterParams = (newParamsFormatURL: Dict) => {
    if (isShortenUrlParam && urlScheme !== 0 && shortenUrlParamList.length > 0) {
      // 0: dont change url, 1: example 'color=red&color=blue', 2: example 'color=red,blue'
      Object.keys(newParamsFormatURL).forEach((key, i) => {
        const shortKey = shortParamsMap.get(key);

        if (shortKey) {
          if (newParamsFormatURL[key]) {
            if (urlScheme === 1 && Array.isArray(newParamsFormatURL[key])) {
              newParamsFormatURL[shortKey] = newParamsFormatURL[key];
            } else {
              newParamsFormatURL[shortKey] = newParamsFormatURL[key].toString();
            }
          } else {
            newParamsFormatURL[shortKey] = undefined;
          }

          delete newParamsFormatURL[key];
        }
      });

      return newParamsFormatURL;
    }

    return newParamsFormatURL;
  };

  // covert URL params from short to long
  const shortToLongFilterParams = (_firstParams: Dict) => {
    const url = getWindowLocation().search;
    const urlParams = url.slice(url.indexOf('?') + 1).split('&');

    if (urlParams.length > 0) {
      if (urlParams[0].includes('?')) urlParams[0] = urlParams[0].replace('?', '');

      urlParams.forEach((param) => {
        const [key, value] = param.split('=');
        if (key && value) {
          const _valueDecode =
            typeof value === 'string' ? decodeURIComponent(decodeShortenValue(value)) : value;
          const longKey = longParamMap.get(key);

          if (longKey) {
            if (_valueDecode.includes(',')) {
              _firstParams[longKey] = _valueDecode.split(',');
            } else if (Array.isArray(_firstParams[longKey])) {
              _firstParams[longKey].push(_valueDecode);
            } else {
              _firstParams[longKey] = [_valueDecode];
            }
          }
        }
      });
    }
  };

  const [currentCollectionSelected, setCurrentCollectionSelected] =
    useState<CurrentCollectionFilterState>(() => {
      if (isSearchPage()) {
        const collectionId =
          getQueryParamByKey(`${filterPrefixParam}c_collection`) ||
          getQueryParamByKey(`collection`) ||
          getQueryParamByKey('collections') ||
          getQueryParamByKey(SCOPED_COLLECTION);

        return {
          collectionId: collectionId ? collectionId : 0,
          handle: undefined,
          tag: getQueryParamByKey(`${filterPrefixParam}ct_collection`),
        };
      }

      return {
        collectionId: collection_handle === 'all' ? 0 : collection_id.toString(),
        handle: collection_handle,
        tag: current_tags?.[0],
      };
    });

  const [filterOptionSelected, setFilterOptionSelected] = useState<FilterOptionType | null>(null);

  const getFilterParams = () => {
    // check badUrl when reload
    const _isBadUrl = isBadUrl(window.location.href, ignoreCharacterURL);

    if (_isBadUrl) {
      window.location.href = window.location.pathname;
    }

    const _firstParams = getQueryParamsState({
      singleAsArray: true,
      filter: (key) =>
        key.startsWith(filterPrefixParam) &&
        !key.startsWith('collection_scope') &&
        !key.endsWith('_and_condition') &&
        !key.endsWith('_exclude_from_value'),
      decodeKey: true,
      decodeValue: true,
    });

    const scopedParams = getQueryParamsState({
      filter: (key) =>
        key === SCOPED_VENDOR ||
        key === SCOPED_PRODUCT_TYPE ||
        key === SCOPED_CATEGORY ||
        key === SCOPED_COLLECTION,
      decodeValue: true,
    });

    if (Object.keys(scopedParams).length > 0) {
      const scopedType = Object.keys(scopedParams)[0];
      let scopedParam = '';

      [SCOPED_VENDOR, SCOPED_PRODUCT_TYPE, SCOPED_CATEGORY, SCOPED_COLLECTION].forEach((scope) => {
        if (scopedType === scope) {
          scopedParam = scopedFilterParams[scope as ScopedFilter];
        }
      });

      const scopedValue = Object.values(scopedParams)[0]?.replace('AND', '&');

      if (scopedType !== scopedFilterType) {
        setScopedFilterType(scopedType as ScopedFilter);
      }

      if (_firstParams[scopedParam]) {
        _firstParams[scopedParam].push(scopedValue);
      } else {
        _firstParams[scopedParam] = [scopedValue];
      }
    }

    // shorten URL handle when init or reload page
    if (isShortenUrlParam && urlScheme !== 0 && shortenUrlParamList) {
      // convert short to long
      shortToLongFilterParams(_firstParams);
    }

    // if is TagPage or multi Collection has tag. After shortToLong because this tag always short.
    if (collection_tags?.length && collection_tags.length > 0) {
      _firstParams.tag = collection_tags[0];
    }

    return _firstParams;
  };

  const [filterParams, setFilterParams] = useState<FilterParams>(getFilterParams());

  const [ajaxLoading, setAjaxLoading] = useState(false);

  const { productsPerPage } = useProductListThemeSettings();

  const defaultLimit = productsPerPage as number;

  const getDefaultLimit = () => {
    const limitParam = Number(getQueryParamByKey('limit'));
    const n = limitParam || defaultLimit;
    const validLimit = defaultAdditionalElementSettings.showLimitList.includes(n);

    if (!validLimit) {
      if (showLimitOnUrl && defaultLimit !== limitParam) {
        setQueryParam('limit', String(defaultLimit), true);
      }

      return defaultLimit;
    }

    return n;
  };

  const getSortBy = () => {
    const defaultSortBy = window.boostSDAppConfig?.generalSettings?.default_sort_by;
    const customSortingList = window.boostSDAppConfig?.additionalElementSettings?.customSortingList;
    const defaultSortOrder = window.boostSDAppConfig?.additionalElementSettings?.default_sort_order;
    const currentCollectionId = getCurrentCollectionId();
    const sortingList: string[] = customSortingList
      ? customSortingList.split('|')
      : defaultAdditionalElementSettings.sortingList;
    const sortQueryKey = getQueryParamByKey('sort') as string;

    if (sortQueryKey && sortingList.includes(sortQueryKey)) return sortQueryKey;

    const searchPage = isSearchPage();
    const collectionPage = isCollectionPage();

    if (!defaultSortOrder) {
      return sortingList.includes(defaultSortBy) ? defaultSortBy : sortingList[0];
    }

    const { all, search } = defaultSortOrder;

    switch (true) {
      case Boolean(collectionPage && currentCollectionId in defaultSortOrder): {
        return defaultSortOrder[currentCollectionId];
      }

      case Boolean(collectionPage && all): {
        return all;
      }

      case Boolean(collectionPage && defaultSortBy): {
        return defaultSortBy;
      }

      case Boolean(searchPage && search): {
        return search;
      }

      // search page
      default: {
        return sortingList.includes('relevance') ? 'relevance' : sortingList[0];
      }
    }
  };

  const [limit, setLimit] = useState<number>(getDefaultLimit());
  const [page, setPage] = useState<number>(getFilterPageByDefault());
  const [sortBy, setSortBy] = useState<string>(getSortBy());
  const [collectionHeader] = useState<{
    isLoading: boolean;
    collection: CollectionHeader | null;
  }>({ isLoading: true, collection: null });

  const [filterData, setFilterData] = useState<FilterData>({
    loading: true,
    data: null,
  });

  const [sharedAdditionalElementData, setSharedAdditionalElementData] =
    useState<SharedAdditionalElementFilterData>({
      loadingAdditional: true,
      fromProductIndex: 0,
      toProductIndex: 0,
      page: 0,
      limit,
      sortBy,
      loadProductsBehavior: 'refresh',
      totalPages: 0,
      totalProducts: 0,
      currentProductCount: 0,
      searchPanelTotalCollections: 0,
      searchPanelTotalPages: 0,
    });

  /**
   * callback function setCurrentCollectionSelected
   * */
  useUpdateEffect(() => {
    const history = getAppHistory();
    const { collectionId, handle, tag, fromBack } = currentCollectionSelected;

    // setCurrentCollectionSelected from back button, no call api
    if (fromBack) {
      return;
    }

    const _filterParams: Dict = {};

    // set null for collection to close option
    if (filterLayout === 'horizontal') setFilterOptionSelected(null);

    if (getQueryParamByKey(SCOPED_COLLECTION) !== collectionId.toString()) {
      removeQueryParam(SCOPED_COLLECTION);
    }

    if (isSearchPage()) {
      const _collectionId = +collectionId === 0 ? null : collectionId?.toString();

      handleChangeFilterParams(
        {
          // ...filterParams,
          ..._filterParams,
          [`${filterPrefixParam}c_collection`]: _collectionId,
          [`${filterPrefixParam}ct_collection`]: tag,
          collection_scope: _collectionId,
        },
        {
          requestInstantlyHorizontal: true,
          collectionFromSearchPage: true,
        }
      );
    } else {
      // only apply for collection page
      //Fixme: Cover case collection multi level has multi tag
      _filterParams.tag = tag;

      // collection page
      // collection select or unSelected
      handleChangeFilterParams(_filterParams, {
        requestInstantlyHorizontal: true,
        ignoreSetQueryParams: true,
      });

      let collectionUrlPath = `/collections/${handle}${tag ? `/${slugify(tag)}` : ''}`;

      // in-collection search in collection page
      if (currentCollectionSearchParam) {
        collectionUrlPath += `?q=${currentCollectionSearchParam}`;
      }
      history.push(collectionUrlPath);
    }
  }, [currentCollectionSelected]);

  /**
   * callback function setLimit
   * */
  useUpdateEffect(() => {
    if (showLimitOnUrl) {
      setQueryParam('limit', String(limit), true);
    }

    getFilterData(
      {
        page: 1,
        filterParams,
        inCollectionSearchValue: currentCollectionSearchParam,
        limit,
      },
      { loadProductsBehavior: 'refresh' }
    );
  }, [limit]);

  /**
   * callback function setPage
   * */
  useUpdateEffect(() => {
    setQueryParam('page', String(page), true);
    getFilterData(
      {
        page,
        filterParams,
        inCollectionSearchValue: currentCollectionSearchParam,
        limit,
      },
      { loadProductsBehavior: 'refresh' }
    );
  }, [page]);

  /**
   * callback preview change default limit
   * */
  useUpdateEffect(() => {
    setLimit(defaultLimit);

    resetPaginationSessionStorage();
  }, [defaultLimit]);

  /**
   * callback function setSortBy
   * */
  useUpdateEffect(() => {
    getFilterData(
      {
        page,
        filterParams,
        inCollectionSearchValue: currentCollectionSearchParam,
        limit,
      },
      { loadProductsBehavior: 'refresh' }
    );
  }, [sortBy]);

  useEffect(() => {
    window.addEventListener('boost-sd-remove-filter-params', (e) => {
      const { optionId, value } = e.detail;

      removeFilterParams(optionId, value);
    });
  }, []);

  const getOtherParams = (
    _filterParams: FilterParams | undefined,
    collectionSelected?: CurrentCollectionFilterState
  ) => {
    if (!_filterParams) _filterParams = {};

    const otherParams: Dict = {};

    if (filterEverywhereCollectionId && !isCollectionPage() && !isSearchPage()) {
      otherParams.collectionId = filterEverywhereCollectionId;
    }

    if (filterPrefixParam !== 'pf_') otherParams.filterPrefixParam = filterPrefixParam;
    otherParams.zero_options = true;

    if (!availableAfterFiltering && !productAndVariantAvailable) {
      // show : availableAfterFiltering:false, productAndVariantAvailable: false
      otherParams.product_available = false;
      otherParams.variant_available = false;
    } else if (!availableAfterFiltering && productAndVariantAvailable) {
      //hide: availableAfterFiltering:false, productAndVariantAvailable:true
      otherParams.product_available = true;
      otherParams.variant_available = true;
    } else {
      //only hide:availableAfterFiltering: true  productAndVariantAvailable:false
      otherParams.product_available = checkExistFilterOptionParams(_filterParams);
      otherParams.variant_available = checkExistFilterOptionParams(_filterParams);
    }

    if (showOutOfStockOption) otherParams.zero_options = true;
    if (showVariantsAsProduct) otherParams.variants_as_products = true;

    if (priceMode !== '') otherParams.price_mode = priceMode;
    if (tagMode !== '') otherParams.tag_mode = tagMode;
    if (sortingAvailableFirst) otherParams.sort_first = 'available';
    if (isVendorPage() && currentCollectionSearchParam)
      otherParams[`${vendorParam}[]`] = currentCollectionSearchParam;
    if (isPageType() && currentCollectionSearchParam)
      otherParams[typeParam] = currentCollectionSearchParam;
    if (urlScheme && (isCollectionPage() || isSearchPage())) otherParams.urlScheme = urlScheme;

    if (
      !_filterParams?.collection_scope &&
      _filterParams?.collection_scope !== 0 &&
      _filterParams?.collection_scope !== '0'
    ) {
      otherParams.collection_scope = collectionSelected
        ? collectionSelected.collectionId
        : currentCollectionSelected.collectionId;
    } else {
      otherParams.collection_scope = _filterParams?.collection_scope;
    }

    return otherParams;
  };

  const getConditionFromBEReturn = () => {
    const addNewParamsConditionReturnFromBE: Dict = {};

    const options =
      filterData?.data?.filter?.options?.filter((option) => option.status === 'active') || [];

    if (options?.length > 0) {
      // build use some condition when change params
      options.forEach((option: FilterOptionType) => {
        // tag
        if (option?.useAndCondition && filterParams[option?.filterOptionId]) {
          const andConditionParam = `${option.filterOptionId}_and_condition`;
          // paramsCondition[andConditionParam] = true;
          addNewParamsConditionReturnFromBE[andConditionParam] = true;
        }

        // excludePriceFromValue && type list Apply for Price, Variants Price, percent ...
        if (
          option?.excludePriceFromValue &&
          option.displayType === FILTER_OPTION_DISPLAY_TYPE.LIST &&
          filterParams[option?.filterOptionId]
        ) {
          const excludePriceParam = `${option.filterOptionId}_exclude_from_value`;
          addNewParamsConditionReturnFromBE[excludePriceParam] = true;
        }
      });
    }

    return addNewParamsConditionReturnFromBE;
  };

  const getFilterData = async (
    payload: {
      filterParams?: FilterParams;
      page?: number;
      inCollectionSearchValue?: string;
      limit: number;
    },
    options: {
      loadProductsBehavior?: LoadProductsBehavior;
    } = { loadProductsBehavior: 'refresh' },
    collectionSelected?: CurrentCollectionFilterState
  ) => {
    const extraParamsByFilterDisplayType = Object.keys(payload.filterParams || filterParams).reduce(
      (params, optionId) => {
        const filterOptionData: FilterOptionType | undefined =
          filterData.data?.filter?.options?.find(
            ({ filterOptionId }) => filterOptionId === optionId
          );

        if (!filterOptionData) return params;

        return {
          ...params,
          ...getExtraParamsByFilterDisplayType(filterOptionData),
        };
      },
      {}
    );

    const page = payload.page || getFilterPageByDefault();
    const limit = payload.limit;

    const andConditionFilterOption = getQueryParamsState({
      filter: (key) => key.endsWith('_and_condition') || key.endsWith('_exclude_from_value'),
    });

    const finalFilterParams: Dict = {
      ...payload.filterParams,
      ...extraParamsByFilterDisplayType,
      ...getOtherParams(payload.filterParams, collectionSelected),
      ...andConditionFilterOption,
      ...getConditionFromBEReturn(),
    };

    // add params for in collection search
    if (payload.inCollectionSearchValue) {
      finalFilterParams[termKey] = payload.inCollectionSearchValue;

      if (isCollectionPage()) {
        finalFilterParams['incollection_search'] = true;
        finalFilterParams['event_type'] = 'incollection_search';
      }
    }

    if (!filterData.loading) {
      setFilterData((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }

    setSharedAdditionalElementData((prevState) => ({ ...prevState, loadingAdditional: true }));

    const getDataAction =
      payload.inCollectionSearchValue || isSearchPage()
        ? SearchAPI.searchInCollection
        : FilterAPI.get;

    return getDataAction(finalFilterParams, {
      page,
      limit,
      sort: getSortBy(),
      customizeSearchParams,
    }).then((res: any) => {
      const { loadProductsBehavior } = options || {};
      const currentPageParams = getFilterPageByDefault();

      if (currentPageParams !== page) {
        /**
         * Ticket: PFSN-48155
         * Target: Only push history when pagination type is page link, otherwise replace to avoid duplicated history stack
         */
        setQueryParam('page', String(page), paginationType !== 'default');
      }

      if (Math.ceil(res.total_product / limit) > 0 && Math.ceil(res.total_product / limit) < page) {
        /**
         * Target: check and reset if page params invalid
         */
        setPage(1);
        return;
      }

      setFilterData((prevState) => {
        let products = res.products;
        const prevProducts = prevState.data?.products;

        if (loadProductsBehavior === 'more' && prevProducts) {
          products = [...prevProducts, ...products];
        }

        if (loadProductsBehavior === 'previous' && prevProducts) {
          products.push(...prevProducts);
        }

        /** REMOVE DEFAULT OPTION */
        products = removeDefaultOptionsProducts(products);

        setSharedAdditionalElementData((prevState) => {
          let searchPanelState = {};
          if (payload.inCollectionSearchValue) {
            searchPanelState = {
              searchPanelTotalCollections: res.total_collection,
              searchPanelTotalPages: res.total_page,
            };
          }

          return {
            ...prevState,
            loadingAdditional: false,
            page: products.length === 0 ? 0 : page,
            limit,
            loadProductsBehavior,
            totalPages: Math.ceil(res.total_product / limit),
            totalProducts: res.total_product,
            currentProductCount: products.length,
            ...searchPanelState,
          };
        });

        return {
          data: {
            ...res,
            products,
          },
          limit,
          loading: false,
        };
      });

      return res;
    });
  };

  const removeFieldNotShowInURL = (fields: string[], filterParamsURL: FilterParams) => {
    if (fields?.length && filterParamsURL) {
      fields.forEach((field) => {
        filterParamsURL[field] = undefined;
      });
    }

    return filterParamsURL;
  };

  const handleChangeFilterParams = useCallback(
    (
      newFilterParams: FilterParams,
      option?: {
        preventSetQueryParams?: boolean;
        requestInstantlyHorizontal?: boolean;
        fromBack?: boolean;
        ignoreSetQueryParams?: boolean;
        collectionFromSearchPage?: boolean;
      }
    ) => {
      if (!option?.fromBack) {
        resetPaginationSessionStorage();
      }

      // hard diff copy, prevent object reference
      let _newParamsFormatURL = cloneDeep(newFilterParams); //JSON.parse(JSON.stringify(newFilterParams));

      // assign optionId undefine -> remove key in URL because dif copy remove value undefine
      Object.keys(newFilterParams)?.forEach((optionId) => {
        if (!newFilterParams[optionId]) {
          _newParamsFormatURL[optionId] = undefined;
        }
      });

      // shorten URL handle
      const isShortenURL = isShortenUrlParam && urlScheme !== 0;
      if (isShortenURL) {
        // Long to short
        longToShortFilterParams(_newParamsFormatURL);
      }

      // because convert delete key have value undefined -> should handle after longToShortFilterParams
      const isEmpty = Object.keys(_newParamsFormatURL).length === 0;
      if (isEmpty) {
        Object.keys(newFilterParams).forEach((key) => {
          if (newFilterParams[key] === undefined) {
            const _keyShort = shortParamsMap.get(key);

            _newParamsFormatURL[_keyShort] = undefined;
          }
        });
      }

      setFilterParams(newFilterParams);

      // apply for style horizontal and desktop and !requestInstantly
      if (
        !isMobileWidth() &&
        filterLayout === 'horizontal' &&
        !requestInstantly &&
        !option?.requestInstantlyHorizontal
      )
        return;

      if (!option?.preventSetQueryParams) {
        deduplicateFilterFromScopedParam(_newParamsFormatURL);

        // delete some field has in filterParams but not show in URL
        const fieldsNotShowURL = ['collection_scope'];

        _newParamsFormatURL = removeFieldNotShowInURL(fieldsNotShowURL, _newParamsFormatURL);

        if (!option?.ignoreSetQueryParams) {
          if (option?.collectionFromSearchPage) {
            Object.assign(_newParamsFormatURL, {
              q: getQueryParamByKey('q'),
            });
          }

          setQueryParams(
            {
              ..._newParamsFormatURL,
              /**
               * @description Reset page param when change filter params
               */
              page: undefined,
            },
            {
              /**
               * @description Force change URL is shortenURL when change filter params
               */
              isShortenURL: isShortenURL,
              force: option?.collectionFromSearchPage,
            }
          );
        }
      }

      if (option?.fromBack && filterLayout === 'horizontal') {
        window.dispatchEvent(
          new CustomEvent('boost-sd-click-back-button-horizontal', {
            detail: {
              filterParamsHorizontal: newFilterParams,
            },
          })
        );
      }

      const latestQueryStringInCollectionSearch = getQueryParamByKey(termKey) as string;
      const inputInCollectionSearch = document.querySelector(
        `.${clsNameMapInCollectionSearch.elm('input')}`
      ) as HTMLInputElement;

      if (inputInCollectionSearch) {
        inputInCollectionSearch.value = latestQueryStringInCollectionSearch;
      }

      return getFilterData({
        page: option?.fromBack ? undefined : 1,
        filterParams: newFilterParams,
        inCollectionSearchValue: latestQueryStringInCollectionSearch,
        limit,
      });
    },
    [getFilterData]
  );

  const deduplicateFilterFromScopedParam = (_newParamsFormatURL: Dict) => {
    /**
     * Prevent case both scoped and filter param has same value exists on URL
     * @example product_types=Dresses&pf_pt_product_type=Dresses
     */

    const collectionQueryVal = getQueryParamByKey('collections') as string;

    let field = '';
    let value = '';

    if (!scopedFilterType) return;

    if (scopedFilterParams[scopedFilterType] in _newParamsFormatURL) {
      field = scopedFilterParams[scopedFilterType];
      value = getQueryParamByKey(scopedFilterType) as string;
    }

    if (field && value) {
      // case "all" collection
      if (!_newParamsFormatURL[field] && collectionQueryVal) {
        removeQueryParam('collections');
        return;
      }

      /**
       * Prevent case duplicated value can happen when changing label name
       * @example
       * Label: Product Type -> New Type
       * FilterParam: pf_pt_product_type -> pf_pt_new_type
       * product_types=Dresses&pf_pt_new_type=Dresses
       */
      for (const key in _newParamsFormatURL) {
        if (
          key !== scopedFilterParams[scopedFilterType] &&
          Array.isArray(_newParamsFormatURL[key]) &&
          _newParamsFormatURL[key].includes(value)
        ) {
          delete _newParamsFormatURL[key];
        }
      }

      if (
        typeof _newParamsFormatURL[field] === 'string' ||
        typeof _newParamsFormatURL[field] === 'number'
      ) {
        if (String(_newParamsFormatURL[field]) === value) {
          delete _newParamsFormatURL[field];
        } else if (collectionQueryVal) {
          removeQueryParam('collections');
        }
      } else if (typeof _newParamsFormatURL[field] === 'object') {
        _newParamsFormatURL[field] = _newParamsFormatURL[field].filter(
          (param: string) => param !== value
        );
      }
    }
  };

  const syncStateWithQueryParams = () => {
    const queryState = getQueryParamsState({
      singleAsArray: true,
      filter: (key) => key.startsWith(filterPrefixParam),
      decodeValue: true,
      decodeKey: true,
    });

    if (isCollectionPage()) {
      const elements = window.location.pathname.split('/');

      const handleCollectionIndex = elements.indexOf('collections') + 1;
      const handle = elements[handleCollectionIndex];
      const tag = elements[handleCollectionIndex + 1];

      if (handle === 'all') {
        queryState.collection_scope = 0;
        // save new data for currentSelected -> show bold in store font
        setCurrentCollectionSelected({
          collectionId: 0,
          handle: 'all',
          tag: undefined,
          fromBack: true,
        });
      } else if (tag && historyCollection[tag] && historyCollection[tag].collectionId) {
        const rawTag = historyCollection[tag].tag;
        const currentCollection = historyCollection[tag];

        queryState.collection_scope = currentCollection.collectionId;
        queryState.tag = rawTag ? rawTag : undefined;

        // save new data for currentSelected -> show bold in store font
        setCurrentCollectionSelected({
          collectionId: currentCollection.collectionId,
          handle: currentCollection.handle,
          tag: rawTag ? rawTag : undefined,
          fromBack: true,
        });
      } else {
        for (const key in historyCollection || {}) {
          if (handle === historyCollection[key].handle && !historyCollection[key].tag) {
            const _collectionId = historyCollection[key].collectionId;
            queryState.collection_scope = _collectionId;
            queryState.tag = undefined;

            // save new data for currentSelected -> show bold in store font
            setCurrentCollectionSelected({
              collectionId: _collectionId,
              handle,
              tag: undefined,
              fromBack: true,
            });

            break;
          }
        }
      }
    }

    if (isSearchPage()) {
      const collectionId =
        getQueryParamByKey(`collection`) || getQueryParamByKey(`${filterPrefixParam}c_collection`);

      const tag = slugify(
        (getQueryParamByKey(`${filterPrefixParam}ct_collection`) as string) || ''
      );

      if (historyCollection[tag]) {
        queryState.collection_scope = collectionId;
        const currentCollection = historyCollection[tag];

        setCurrentCollectionSelected({
          collectionId: currentCollection.collectionId,
          handle: currentCollection.handle,
          tag: currentCollection.tag,
          fromBack: true,
        });
      } else {
        for (const key in historyCollection || {}) {
          if (collectionId === historyCollection[key].collectionId) {
            queryState.collection_scope = collectionId;

            const currentCollection = historyCollection[key];

            setCurrentCollectionSelected({
              collectionId: currentCollection.collectionId,
              handle: currentCollection.handle,
              tag: undefined,
              fromBack: true,
            });

            break;
          }
        }
      }

      if (!queryState.collection_scope) {
        queryState.collection_scope = 0;

        setCurrentCollectionSelected({
          collectionId: 0,
          handle: 'all',
          tag: undefined,
          fromBack: true,
        });
      }
    }

    setSortBy(getSortBy());
    return handleChangeFilterParams(queryState, {
      preventSetQueryParams: true,
      requestInstantlyHorizontal: true,
      fromBack: true,
    });
  };

  const handleOnPopHistory = () => {
    /**
     * Ticket: PFSN-48155
     * Target: Set correct pages for pagination when hits back button
     */
    const page = getQueryParamByKey('page');

    if (page) {
      setSessionStorage(INITIAL_PAGE, Number(page));
      setSessionStorage(PREV_PAGE, Number(page));
      setSessionStorage(NEXT_PAGE, Number(page));
    }

    syncStateWithQueryParams();
  };

  const handleOnReplaceHistory = () => {
    /**
     * Ticket: PFSN-48155
     * Target: Avoid Load more/Infinite scroll triggers weird loading behavior when paginating
     */
    const page = getQueryParamByKey('page');
    if (page) return;

    syncStateWithQueryParams();
  };

  useListenHistoryEvent({
    onPop: handleOnPopHistory,
    onReplace: handleOnReplaceHistory,
  });

  const addFilterParams = useCallback(
    (optionId: string, value: string, option?: { force?: boolean }) => {
      const currentValues = filterParams[optionId] || [];

      if (!currentValues.includes(value)) {
        const filterOptionData = filterData.data?.filter?.options?.find(
          ({ filterOptionId }) => filterOptionId === optionId
        );

        if (!filterOptionData) return;

        if (filterOptionData.selectType === 'single' || option?.force) {
          return handleChangeFilterParams({
            ...filterParams,
            [optionId]: [value],
          });
        }

        const newFilterOptionValues = [...currentValues, value];

        const newFilterParams = {
          ...filterParams,
          [optionId]: newFilterOptionValues,
        };

        return handleChangeFilterParams(newFilterParams);
      }
    },
    [filterParams, filterData.data?.filter?.options, handleChangeFilterParams]
  );

  const addInCollectionSearchParams = (value: string) => {
    let newFilterParams = cloneDeep(filterParams);

    const isShortenURL = isShortenUrlParam && urlScheme !== 0;
    if (isShortenURL) {
      // Long to short
      longToShortFilterParams(newFilterParams);
    }

    const fieldsNotShowURL = ['collection_scope'];
    newFilterParams = removeFieldNotShowInURL(fieldsNotShowURL, newFilterParams);
    setQueryParams({
      ...newFilterParams,
      [termKey]: stripHtml(value),
      /**
       * @description Reset page param when change search params
       */
      page: undefined,
    });

    resetPaginationSessionStorage();

    return getFilterData({
      filterParams: filterParams,
      inCollectionSearchValue: value,
      limit,
    });
  };

  const checkCollectionPageWhenRemoveParams = (
    hasCollection: boolean,
    newFilterParams: FilterParams
  ) => {
    if (hasCollection) {
      const elements = window.location.pathname.split('/');

      const handleCollectionIndex = elements.indexOf('collections') + 1;
      const handle = elements[handleCollectionIndex];
      const tag = elements[handleCollectionIndex + 1];

      if (handleCollectionIndex) {
        const collectionId =
          historyCollection[tag]?.collectionId || historyCollection[handle]?.collectionId
            ? historyCollection[tag]?.collectionId || historyCollection[handle]?.collectionId
            : getCurrentCollectionId();

        newFilterParams.collection_scope = collectionId;

        setCurrentCollectionSelected({
          collectionId,
          handle,
          tag: historyCollection[tag]?.tag,
          fromBack: true,
        });

        return newFilterParams;
      } else if (getQueryParamByKey('collections')) {
        return newFilterParams;
      }
    }

    newFilterParams.collection_scope = 0;

    setCurrentCollectionSelected({
      collectionId: 0,
      handle: 'all',
      tag: undefined,
      fromBack: true,
    });

    return newFilterParams;
  };

  const removeFilterParamsOption = (optionId: string, type?: 'all' | '') => {
    Object.entries(scopedFilterParams).forEach(([scopedType, scopedFilterParam]) => {
      if (type === 'all' || (scopedFilterParam === optionId && getQueryParamByKey(scopedType))) {
        checkAndRemoveScopedParam('', '', 'all');
      }
    });

    if (type === 'all') {
      let newFilterParams = filterParams;

      const hasCollection =
        filterParams['collection'] ||
        filterParams['collections'] ||
        filterParams[`${filterPrefixParam}c_collection`] ||
        isCollectionPage();
      Object.keys(filterParams).forEach((key: string) => {
        newFilterParams[key] = undefined;
      });

      newFilterParams = checkCollectionPageWhenRemoveParams(hasCollection, newFilterParams);

      return handleChangeFilterParams(newFilterParams, {
        requestInstantlyHorizontal: filterLayout === 'horizontal' ? true : false,
      });
    } else {
      const newFilterParams = filterParams;
      newFilterParams[optionId] = undefined;

      // if clear collection option then reset collection when in search page as trigger select collection all
      if (optionId.endsWith('c_collection')) {
        return setCurrentCollectionSelected({
          handle: 'all',
          collectionId: collection_id || 0,
          tag: undefined,
        });
      }

      return handleChangeFilterParams(newFilterParams, {
        requestInstantlyHorizontal: filterLayout === 'horizontal' ? true : false,
      });
    }
  };

  const checkAndRemoveScopedParam = (optionId: string, value: string, type?: 'all' | '') => {
    Object.entries(scopedFilterParams).forEach(([scopedType, scopedParam]) => {
      if (
        (type === 'all' && getQueryParamByKey(scopedType)) ||
        (optionId === scopedParam && value === getQueryParamByKey(scopedType))
      ) {
        removeQueryParam(scopedType);
        return;
      }
    });
  };

  const removeFilterParams = useCallback(
    (optionId: string, value: string | number, option?: { forceInstantly?: boolean }) => {
      if (typeof value !== 'string') value = value.toString();

      checkAndRemoveScopedParam(optionId, value?.replace('&', 'AND'));

      const currentValues = toItemArray(filterParams[optionId] || []);
      const indexValue = currentValues.indexOf(value);
      if (indexValue !== -1 && Array.isArray(currentValues)) {
        const nextValues = [
          ...currentValues.slice(0, indexValue),
          ...currentValues.slice(indexValue + 1),
        ];

        let newFilterParams = { ...filterParams };
        const hasCollection =
          newFilterParams['collection'] ||
          newFilterParams['collections'] ||
          newFilterParams[`${filterPrefixParam}c_collection`] ||
          isCollectionPage();

        newFilterParams = checkCollectionPageWhenRemoveParams(hasCollection, newFilterParams);

        if (nextValues.length) {
          newFilterParams[optionId] = [...nextValues];
        } else {
          newFilterParams[optionId] = undefined;
        }

        return handleChangeFilterParams(newFilterParams, {
          requestInstantlyHorizontal:
            filterLayout === 'horizontal' && option?.forceInstantly ? true : false,
        });
      }
    },
    [filterParams, handleChangeFilterParams]
  );

  const toggleFilterParams = useCallback(
    (optionId: string, value: string) => {
      if (scopedFilterType) {
        const scopedParam = scopedFilterParams[scopedFilterType];
        if (filterParams[scopedParam]?.includes(value)) {
          optionId = scopedParam;
        }
      }

      const currentValues = filterParams[optionId] || [];
      const indexValue = currentValues.indexOf(value);
      if (indexValue !== -1) {
        return removeFilterParams(optionId, value);
      } else {
        addFilterParams(optionId, value);
      }
    },
    [filterParams, addFilterParams, removeFilterParams]
  );

  const handleChangeFilterLimit = (limit: number) => {
    setLimit(limit);
    resetPaginationSessionStorage();
  };

  const handleChangeFilterSortBy = (sortBy: string) => {
    setQueryParams({
      sort: sortBy,
      page: undefined,
    });

    setSortBy(sortBy);
    resetPaginationSessionStorage();
  };

  const onProductPageChange = useCallback(
    (
      page: number,
      options?: {
        loadProductsBehavior?: LoadProductsBehavior;
      },
      collectionSelected?: CurrentCollectionFilterState
    ) => {
      return getFilterData(
        {
          page,
          filterParams: getFilterParams(),
          inCollectionSearchValue: getQueryParamByKey(termKey) as string,
          limit,
        },
        options,
        collectionSelected
      );
    },
    [page, limit, currentCollectionSelected, filterParams]
  );

  const providerValue: FilterStateValue = {
    filterParams,
    filterData,
    sharedAdditionalElementData,
    currentCollectionFilter: currentCollectionSelected.handle,
    addFilterParams,
    removeFilterParams,
    toggleFilterParams,
    removeFilterParamsOption,
    getFilterData,
    handleChangeFilterLimit,
    handleChangeFilterSortBy,
    addInCollectionSearchParams,
    handleChangeFilterParams,
    currentCollectionSelected,
    setCurrentCollectionSelected,
    filterOptionSelected,
    setFilterOptionSelected,
    onProductPageChange,
    historyCollection,
    setHistoryCollection,
    ajaxLoading,
    setAjaxLoading,
    limit,
    sortBy,
    scopedFilterType,
    collectionHeader,
  };

  useMountEffect(function initFilterData() {
    getFilterData({ filterParams, inCollectionSearchValue: currentCollectionSearchParam, limit });
  });

  return (
    <FilterStateContext.Provider value={providerValue}>{children}</FilterStateContext.Provider>
  );
};

export const FilterStateProvider = registryComponent(
  'FilterStateProvider',
  FilterStateProviderComponent
);

export const useFilterState = (): FilterStateValue => {
  const context = useContext(FilterStateContext);

  if (!context) {
    throw Error('Use useFilterState in FilterStateProvider');
  }

  return context;
};

export const useProductsFilterResult = () => {
  const {
    filterData,
    sharedAdditionalElementData,
    onProductPageChange,
    currentCollectionSelected,
  } = useFilterState();

  const products = useMemo(() => {
    return filterData.data?.products || [];
  }, [filterData]) as ProductItem[];

  const limit = sharedAdditionalElementData.limit;
  const totalProducts = filterData.data?.total_product || 0;
  const page = getFilterPageByDefault();
  const totalPages = Math.ceil(totalProducts / limit);

  return useStateAsExternalStore({
    products,
    totalProducts,
    fetchingData: filterData.loading,
    onProductPageChange,
    currentCollectionSelected,
    pagination: {
      page,
      totalPages,
      limit,
    },
  });
};

export const useProductsFilterResultState = () => {
  const {
    filterData,
    sharedAdditionalElementData,
    onProductPageChange,
    currentCollectionSelected,
  } = useFilterState();

  const products = useMemo(() => {
    return filterData.data?.products || [];
  }, [filterData]) as ProductItem[];

  const limit = sharedAdditionalElementData.limit;
  const totalProducts = filterData.data?.total_product || 0;
  const page = getFilterPageByDefault();
  const totalPages = Math.ceil(totalProducts / limit);

  return {
    products,
    totalProducts,
    fetchingData: filterData.loading,
    onProductPageChange,
    currentCollectionSelected,
    pagination: {
      page,
      totalPages,
      limit,
    },
  };
};

export const useAdditionalElementFilterResult = () => {
  const { sharedAdditionalElementData, handleChangeFilterLimit, handleChangeFilterSortBy } =
    useFilterState();

  const { totalProducts, currentProductCount, limit, totalPages } = sharedAdditionalElementData;
  const page = getFilterPageByDefault();

  const getFromProductIndex = (
    currentProductCount: number,
    loadProductsBehavior?: LoadProductsBehavior
  ): number => {
    if (currentProductCount === 0) return 0;

    if (loadProductsBehavior === 'previous') {
      return (getSessionStorage(PREV_PAGE) - 1) * limit + 1;
    }

    if (loadProductsBehavior === 'more') {
      return (getSessionStorage(PREV_PAGE) - 1) * limit + 1;
    }

    const max = Math.max(limit, currentProductCount);

    return page * limit - max + 1;
  };

  const getToProductIndex = (
    currentProductCount: number,
    totalProduct: number,
    loadProductsBehavior?: LoadProductsBehavior
  ): number => {
    if (page === totalPages) return totalProduct;

    if (loadProductsBehavior === 'previous') {
      if (getSessionStorage(NEXT_PAGE) === totalPages) return totalProduct;

      return getSessionStorage(NEXT_PAGE) * limit;
    }

    if (loadProductsBehavior === 'more') {
      return getSessionStorage(NEXT_PAGE) * limit;
    }

    return Math.min(page * limit, totalProduct);
  };

  const onChangeFilterLimit = useCallback((limit: number) => {
    return handleChangeFilterLimit(limit);
  }, []);

  const onChangeFilterSortBy = useCallback((sortBy: string) => {
    return handleChangeFilterSortBy(sortBy);
  }, []);

  const fromProductIndex = getFromProductIndex(
    currentProductCount,
    sharedAdditionalElementData.loadProductsBehavior
  );

  const toProductIndex = getToProductIndex(
    currentProductCount,
    totalProducts,
    sharedAdditionalElementData.loadProductsBehavior
  );

  return useStateAsExternalStore<SharedAdditionalElementFilterData>({
    ...sharedAdditionalElementData,
    fromProductIndex,
    toProductIndex,
    onChangeFilterLimit,
    onChangeFilterSortBy,
  });
};
