export const SCOPED_PRODUCT_TYPE = 'scoped_product_type';
export const SCOPED_VENDOR = 'scoped_vendor';
export const SCOPED_COLLECTION = 'scoped_collection';
export const SCOPED_CATEGORY = 'scoped_category';

export const scopedFilterParams = {
  [SCOPED_PRODUCT_TYPE]: 'pf_pt_product_type',
  [SCOPED_VENDOR]: 'pf_v_vendor',
  [SCOPED_COLLECTION]: `pf_c_collection`,
  [SCOPED_CATEGORY]: `pf_pc_product_category`,
};
