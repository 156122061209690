import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import type { AdditionalElementThemeSettings } from '@providers/ThemeProvider/types';
import { useMemo } from 'react';

export type ToolbarShowLimitListPropsMapSettings =
  AdditionalElementThemeSettings['toolbar']['showLimitList'];

export const useConnectToolbarShowLimitList = () => {
  const additionalElementSettings = useAdditionalElementThemeSettings();
  const { showLimitList } = additionalElementSettings.toolbar;

  const props: ToolbarShowLimitListPropsMapSettings = useMemo(() => {
    return showLimitList;
  }, [showLimitList]);

  return {
    props,
  };
};
