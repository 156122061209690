import './HeaderDescription.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import type { ModifiersTypeMap } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  modifiers: ['as-main-4'],
})('header-description');

export type HeaderDescriptionProps = {
  description?: string;
  descriptionModifiers?: ModifiersTypeMap<typeof clsNameMap>;
};

const HeaderDescription = ({ description, descriptionModifiers }: HeaderDescriptionProps) => {
  if (!description) return <></>;

  return (
    <InnerHTMLRenderer
      className={mapModifiers(clsNameMap, descriptionModifiers)}
      html={description}
    />
  );
};

export default registryComponent('HeaderDescription', HeaderDescription);
