import './filter.scss';

import { getGeneralSettingsFromAdmin, isCollectionPage, isSearchPage } from '@boost-sd/app';
import {
  createProductFilterCollectionPageBlock,
  createProductFilterSearchPageBlock,
} from '@boost-sd/theme/core/blocks/filter';

const filterBlockSelector = process.env.FILTER_BLOCK_SELECTOR || '#main';
const generalSettings = getGeneralSettingsFromAdmin();
if (
  isCollectionPage() ||
  (generalSettings.preview_mode && generalSettings.preview_path === '/collections')
) {
  const productFilterBlock = createProductFilterCollectionPageBlock({
    blockSelector: filterBlockSelector,
    collectionHeaderOnRight: true,
  });

  productFilterBlock.render();
}
if (
  isSearchPage() ||
  (generalSettings.preview_mode && generalSettings.preview_path === '/search')
) {
  const productFilterBlock = createProductFilterSearchPageBlock({
    blockSelector: filterBlockSelector,
  });

  productFilterBlock.render();
}
