import './CallToAction.scss';

import type { CTAButtonProps } from '@components/CallToActionButton';
import CTAButton from '@components/CallToActionButton';
import { createClsNameMap, mapModifiers } from '@utils';

export const clsNameMap = createClsNameMap({
  modifiers: ['column', 'full-width', 'hide'],
})('cta-buttons');

export type CallToActionProps = {
  showButtonOnHover?: boolean;
  layout?: 'vertical' | 'horizontal';
  buttons: Array<CTAButtonProps>;
  buttonPropsApplyAll?: CTAButtonProps;
};

const CallToAction = ({
  layout,
  buttons,
  showButtonOnHover,
  buttonPropsApplyAll,
}: CallToActionProps) => {
  return (
    <div
      className={mapModifiers(clsNameMap, {
        hide: showButtonOnHover,
        column: layout === 'vertical',
      })}
    >
      {buttons.map((item, index) => (
        <CTAButton key={index} {...item} {...buttonPropsApplyAll} />
      ))}
    </div>
  );
};

export default CallToAction;
