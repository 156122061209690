import { SESSION_KEY } from '@constants/trackingEvent';

import { generateUUID } from './generate';

export const getBoostPFSSectionId = () => {
  let value = localStorage.getItem(SESSION_KEY);
  if (!value) {
    value = generateUUID();
    localStorage.setItem(SESSION_KEY, value);
  }
  return value;
};
