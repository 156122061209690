import { memo, useEffect, useRef, useState, useTransition } from 'react';

import { useLatest } from './hooks';

export type NonBlockingListProps<Item> = {
  items: Item[];
  onItemRender: (item: Item, index: number) => React.ReactNode;
  afterComplete?: (items: Item[], nodes: React.ReactNode[]) => void;
  runOnceWhenItemsAvailable?: boolean;
  disableDeferredRender?: boolean;
  delay?: number;
  intersection?: {
    listElementRef: React.RefObject<HTMLElement>;
    minHeight?: number;
  };
};

function yieldToMain(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export const DeferredList = <Item,>({
  items,
  onItemRender,
  afterComplete,
  runOnceWhenItemsAvailable,
  disableDeferredRender,
  delay = 0,
}: NonBlockingListProps<Item>) => {
  const [status, setStatus] = useState<'rendering' | 'idle' | 'complete'>('idle');
  const [nodes, setNodes] = useState<React.ReactNode[]>([]);

  const [, startTransition] = useTransition();
  const afterCompleteRef = useLatest(afterComplete);
  const shouldFallbackNormalRendererRef = useRef(false);

  useEffect(
    function deferredRenderer() {
      if (disableDeferredRender) return;
      if (shouldFallbackNormalRendererRef.current) return;

      let effectHasInvoke = false;

      const nonBlockingRender = async () => {
        if (effectHasInvoke) return;

        setNodes([]);
        setStatus('rendering');

        if (items.length > 0) {
          const clonedItems = [...items];
          let index = 0;

          while (index < clonedItems.length) {
            if (effectHasInvoke) break;

            const itemProps = clonedItems[index];

            if (itemProps) {
              const itemNode = onItemRender(itemProps, index);

              // eslint-disable-next-line no-loop-func
              startTransition(() => {
                if (!effectHasInvoke) {
                  setNodes((prevNodes) => [...prevNodes, itemNode]);
                }
              });
            }

            await yieldToMain(delay);
            index += 1;
          }
        }

        if (runOnceWhenItemsAvailable && !shouldFallbackNormalRendererRef.current) {
          shouldFallbackNormalRendererRef.current = true;
        }

        startTransition(() => {
          setStatus('complete');
        });
      };

      nonBlockingRender();

      return () => {
        effectHasInvoke = true;
      };
    },
    [items, disableDeferredRender]
  );

  useEffect(() => {
    if (status === 'complete' && afterCompleteRef.current) {
      afterCompleteRef.current(items, nodes);
    }
  }, [status]);

  return (
    <>
      {shouldFallbackNormalRendererRef.current || disableDeferredRender
        ? items.map(onItemRender)
        : nodes}
    </>
  );
};

export const MemoizedDeferredList = memo(DeferredList, (prev, next) => {
  if (prev.items === next.items) return true;

  return false;
}) as <Item>(props: NonBlockingListProps<Item>) => React.JSX.Element;
