import './LoadMore.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { PaginationButtonProps } from '@components/PaginationButton';
import type { ProductCountProps } from '@components/ProductCount';
import type { ModifiersType } from '@utils';
import { createClsNameMap, mapModifiers } from '@utils';
import type { PropsWithChildren } from 'react';

const clsNameMap = createClsNameMap({
  elements: {
    item: createClsNameMap(),
    'product-count': createClsNameMap(),
    button: createClsNameMap({
      modifiers: ['rectangle', 'rounded-rectangle', 'left', 'center', 'right'],
    }),
  },
})('pagination-load-more-container');

export type PaginationLoadMoreProps = {
  totalPages: number;
  productCount?: React.ReactElement<ProductCountProps>;
  loadPreviousButton?: React.ReactElement<PaginationButtonProps>;
  loadMoreButton?: React.ReactElement<PaginationButtonProps>;
  productCountPosition: 'top' | 'bottom';
  alignmentModifier?: ModifiersType<(typeof clsNameMap)['button']>;
};

const PaginationLoadMore = ({
  totalPages,
  productCount,
  loadPreviousButton,
  loadMoreButton,
  productCountPosition,
  alignmentModifier,
  children,
}: PropsWithChildren<PaginationLoadMoreProps>) => {
  return (
    <div className={clsNameMap.root()}>
      {totalPages > 1 && loadPreviousButton && (
        <div className={mapModifiers(clsNameMap.button, alignmentModifier)}>
          {loadPreviousButton}
        </div>
      )}

      {children}

      {totalPages > 1 && loadMoreButton && (
        <div className={clsNameMap.elm('item')}>
          {productCountPosition === 'top' && (
            <div className={clsNameMap.elm('product-count')}>{productCount}</div>
          )}

          <div className={mapModifiers(clsNameMap.button, alignmentModifier)}>{loadMoreButton}</div>

          {productCountPosition === 'bottom' && (
            <div className={clsNameMap.elm('product-count')}>{productCount}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default registryComponent('PaginationLoadMore', PaginationLoadMore);
