import './FilterTreeVerticalPlaceholder.scss';

import PlaceholderItem from '@components/PlaceHolderItem';
import { appendClassNames, createClsNameMap } from '@utils';

export const clsNameMap = createClsNameMap({
  elements: {
    list: createClsNameMap({}),
    item: createClsNameMap({}),
  },
})('filter-tree-vertical-placeholder');

export type PlaceholderProps = {
  className?: string;
  rows?: number;
};

const FilterTreeVerticalPlaceholder = ({ className, rows = 3 }: PlaceholderProps) => {
  const arr = new Array(rows).fill('item');
  return (
    <div className={appendClassNames(clsNameMap.root(), className)}>
      <ul className={clsNameMap.elm('list')}>
        {arr.map((_, i) => {
          return (
            <li key={i} className={clsNameMap.elm('item')}>
              <PlaceholderItem modifiers={['width3']} />
              <PlaceholderItem modifiers={['width4']} />
              <PlaceholderItem modifiers={['width2']} />
              <PlaceholderItem modifiers={['width1']} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterTreeVerticalPlaceholder;
