import './index.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import FilterTreeVerticalPlaceholder from '@components/FilterTreeVerticalPlaceholder';
import useMountEffect from '@hooks/useMountEffect';
import { lazy } from '@loadable/component';
import { CurrencyProvider } from '@providers/CurrencyProvider';
import type { FilterOptionType } from '@providers/FilterProvider';
import { getFilterOptionShow, useFilterState } from '@providers/FilterProvider';
import { useFilterSettings } from '@providers/FilterProvider/Provider/FilterSettings';
import type { stickyType } from '@utils';
import {
  createClsNameMap,
  getTAEFeatureFromAdmin,
  isCollectionPage,
  isMobileWidth,
  isSearchPage,
  mapModifiers,
  setVariableRootCSS,
} from '@utils';
import { debounce } from 'lodash-es';
import { Suspense, useMemo, useRef, useState } from 'react';

import ScrollToTop from './ScrollToTop';

const FilterTreeVertical = lazy(
  () => import(/* webpackChunkName: "filter--filter-tree-vertical" */ '../FilterTreeVertical')
);
const FilterTreeHorizontal = lazy(
  () => import(/* webpackChunkName: "filter--filter-tree-horizontal" */ '../FilterTreeHorizontal')
);

let filterLayout: 'vertical' | 'horizontal' = 'vertical';

if (isCollectionPage()) {
  filterLayout = getTAEFeatureFromAdmin()?.filterLayout?.collection || 'vertical';
}

if (isSearchPage()) {
  filterLayout = getTAEFeatureFromAdmin()?.filterLayout?.search || 'vertical';
}

if (filterLayout === 'vertical' || isMobileWidth()) {
  FilterTreeVertical.preload();
} else {
  FilterTreeHorizontal.preload();
}

const clsNameMap = createClsNameMap({
  modifiers: ['vertical', 'horizontal'],
})('filter-tree-wrapper');

export type FilterTreeProps = {
  customizeSticky?: (stickyData: stickyType) => void;
  disableDeferredRender?: boolean;
};

const FilterTree = ({ customizeSticky }: FilterTreeProps) => {
  const {
    enableFilter,
    filterLayout,
    activeScrollToTop,
    style: {
      filterOptionTextColor,
      filterOptionFontSize,
      filterOptionFontFamily,
      filterTitleTextColor,
      filterTitleFontTransform,
      filterTitleFontWeight,
      filterTitleFontSize,
      filterTitleFontFamily,
    },
    showSingleOption,
    showOutOfStockOption,
    filterTreeVerticalStyle,
    stickyFilterOnDesktop,
    stickyFilterOnMobile,
    filterPrefixParam,
  } = useFilterSettings();

  const { filterData, filterParams } = useFilterState();

  const [isMobile, setIsMobile] = useState(() => {
    if (isMobileWidth()) return true;
    return false;
  });

  const addStyleToFirstLoadRef = useRef<boolean>(true);

  if (!filterData.loading && addStyleToFirstLoadRef.current) {
    addStyleToFirstLoadRef.current = false;
  }

  useMountEffect(() => {
    if (!enableFilter) return;

    const filterTreeVariable = {
      'filter-option-text-color': filterOptionTextColor?.replaceAll('||', ','),
      'filter-option-font-size': filterOptionFontSize
        ? filterOptionFontSize + 'px'
        : filterOptionFontSize,
      'filter-option-font-family': filterOptionFontFamily,
      'filter-title-text-color': filterTitleTextColor?.replaceAll('||', ','),
      'filter-title-font-transform': filterTitleFontTransform,
      'filter-title-font-weight': filterTitleFontWeight,
      'filter-title-font-size': filterTitleFontSize
        ? filterTitleFontSize + 'px'
        : filterTitleFontSize,
      'filter-title-font-family': filterTitleFontFamily,
    };

    setVariableRootCSS(
      filterTreeVariable,
      document.getElementById('boost-sd__filter-tree-wrapper')
    );

    if (document.getElementsByClassName('boost-sd__filter-tree-vertical-sticky-overlay')) {
      setVariableRootCSS(
        filterTreeVariable,
        document.getElementsByClassName('boost-sd__filter-tree-vertical-sticky-overlay')[0]
      );
    }

    const debounceDetectDevice = debounce(() => {
      // tips: fixed issue zoom out: PFSN-43986
      if (isMobileWidth() && screen.width < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      if (
        (stickyFilterOnDesktop && !isMobileWidth()) ||
        (stickyFilterOnMobile && isMobileWidth())
      ) {
        const resizeTriggerStickyEvent = new CustomEvent('resize-trigger-sticky-event');
        window.dispatchEvent(resizeTriggerStickyEvent);
      }
    }, 100);

    window.addEventListener('resize', debounceDetectDevice);
  });

  const renderFilterTree = (filterOptionsShow: FilterOptionType[]) => {
    // isMobile always is vertical
    if (isMobile || filterLayout === 'vertical') {
      return (
        <Suspense fallback={<FilterTreeVerticalPlaceholder />}>
          <CurrencyProvider>
            <FilterTreeVertical
              isMobile={isMobile}
              customizeSticky={customizeSticky}
              filterOptionsShow={filterOptionsShow}
            />
          </CurrencyProvider>
        </Suspense>
      );
    } else if (!isMobile && filterLayout === 'horizontal') {
      return (
        <CurrencyProvider>
          <FilterTreeHorizontal
            customizeSticky={customizeSticky}
            filterOptionsShow={filterOptionsShow}
          />
        </CurrencyProvider>
      );
    }

    return null;
  };

  // not show filter tree when all option not show
  const filterOptionsShow = useMemo(() => {
    return getFilterOptionShow(filterData, {
      showSingleOption,
      showOutOfStockOption,
    });
  }, [filterData, showSingleOption, showOutOfStockOption]);

  if (!enableFilter) return null;

  const isFiltering =
    Object.keys(filterParams || {}).filter(
      (key) => filterParams[key] && key.startsWith(filterPrefixParam)
    )?.length > 0;

  /*
   * FilterTree will not display if filterOptionShow has length = 0 when it is not filtering.
   * If it is filtering and filterOptionShow has length = 0, we will still show because filterTree will have refineBy if it is enabled.
   * Check is finished loading because when still loading isFiltering will be false and filterOptionsShow will be empty
   */
  if (!filterData.loading && !isFiltering && filterOptionsShow?.length === 0) {
    return null;
  }

  const getFilterTreeWrapperClsName = () => {
    if (
      filterLayout === 'vertical' &&
      filterTreeVerticalStyle === 'style-default' &&
      addStyleToFirstLoadRef.current
    ) {
      return 'vertical';
    }

    return null;
  };

  return (
    <div
      id='boost-sd__filter-tree-wrapper'
      className={mapModifiers(clsNameMap, { [`${getFilterTreeWrapperClsName()}`]: true })}
    >
      {renderFilterTree(filterOptionsShow)}
      {activeScrollToTop && <ScrollToTop />}
    </div>
  );
};

export default registryComponent('FilterTree', FilterTree);
