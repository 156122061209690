import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import type { AdditionalElementThemeSettings } from '@providers/ThemeProvider/types';
import { useMemo } from 'react';

export type ToolbarElementsPropsMapSettings = AdditionalElementThemeSettings['toolbar']['elements'];

export const useConnectToolbarElements = () => {
  const additionalElementSettings = useAdditionalElementThemeSettings();
  const { elements } = additionalElementSettings.toolbar;

  const props: ToolbarElementsPropsMapSettings = useMemo(() => {
    return elements || {};
  }, [elements]);

  return {
    props,
  };
};
