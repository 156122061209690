import type { PropsWithChildren } from 'react';

import { FilterSettingsProvider } from './FilterSettings';
import { FilterStateProvider } from './FilterState';
import { FilterTreeProvider } from './FilterTree';

export type FilterProviderProps = PropsWithChildren<unknown>;

export const FilterProvider = ({ children }: FilterProviderProps) => {
  return (
    <FilterSettingsProvider>
      <FilterStateProvider>
        <FilterTreeProvider>{children}</FilterTreeProvider>
      </FilterStateProvider>
    </FilterSettingsProvider>
  );
};

export * from './FilterSettings';
export * from './FilterState';
export * from './FilterTree';
