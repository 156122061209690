import { useEffect, useState } from 'react';

export const BOOST_SD_SEARCH_RESULTS_CACHE = 'boostSdSearchResults';

const useLocalStorage = (key: string, defaultValue: string | object | undefined) => {
  const [value, setValue] = useState(
    localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '{}') : defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;
