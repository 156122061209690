export enum FILTER_EVENT_TYPE {
  INIT = 'init',
  FILTER = 'filter',
  COLLECTION = 'collection',
  PAGE = 'page',
}

export enum FILTER_OPTION_DISPLAY_TYPE {
  LIST = 'list',
  BOX = 'box',
  RANGE = 'range',
  SWATCH = 'swatch',
  RATING = 'rating',
  SUB_CATEGORY = 'sub_category',
  MULTI_LEVEL_COLLECTIONS = 'multi_level_collections',
  MULTI_LEVEL_TAG = 'multi_level_tag',
}

export enum FILTER_OPTION_TYPE {
  COLLECTION = 'collection',
  VENDOR = 'vendor',
  PRODUCT_TYPE = 'product_type',
  TITLE = 'title',
  PRICE = 'price',
  VARIANTS_PRICE = 'variants_price',
  PERCENT_SALE = 'percent_sale',
  REVIEW_RATINGS = 'review_ratings',
  WEIGHT = 'weight',
  STOCK = 'stock',
  TAG = 'tag',
  MULTI_LEVEL_TAG = 'multi_level_tag',
  LOCATION = 'location',
  COLOR = 'opt_color',
  SIZE = 'opt_size',
  PRODUCT_CATEGORY = 'product_category',
}

export enum FILTER_OPTION_SHOWMORE_TYPE {
  NONE = 'none',
  SCROLLBAR = 'scrollbar',
  VIEWMORE = 'viewmore',
  VIEWMORE_SCROLLBAR = 'viewmore_scrollbar',
}

export enum FILTER_OPTION_ITEM_SWATCH_TYPE {
  ONE_COLOR = 'one_color',
  TWO_COLORS = 'two_colors',
  IMAGE = 'image',
}

export enum FILTER_OPTION_SORT_TYPE {
  KEY_ASCENDING = 'key-asc',
  KEY_DESCENDING = 'key-desc',
  DOC_COUNT_ASCENDING = 'doc_count-asc',
  DOC_COUNT_DESCENDING = 'doc_count-desc',
}

export enum FILTER_OPTION_SELECT_TYPE {
  SINGLE = 'single',
}
