export const enableProductFilterFallback = () => {
  if (window.boostSDFallback) {
    window.boostSDFallback.enableProductFilterFallbackOnLoad = true;
  }

  const enableEvent = new CustomEvent('boost-sd-enable-product-filter-fallback');

  window.dispatchEvent(enableEvent);
};

export const shopifyFormatMoney = (price: string | number, format?: string) => {
  if (typeof price == 'string') {
    price = price.replace('.', '');
  }
  let value;
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;

  const defaultOption = (opt: string | number | undefined, def: string | number) => {
    return typeof opt == 'undefined' ? def : opt;
  };

  const formatWithDelimiters = (
    number: number,
    precision: number,
    thousands?: string | number,
    decimal?: string | number
  ) => {
    precision = defaultOption(precision, 2) as number;
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');

    if (isNaN(number) || number == null) {
      return 0;
    }

    const numberToString = (number / 100.0).toFixed(precision);

    const parts = numberToString.split('.'),
      dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
      cents = parts[1] ? decimal + parts[1] : '';

    return dollars + cents;
  };

  switch (format?.match(placeholderRegex)?.[1]) {
    case 'amount':
      value = formatWithDelimiters(Number(price), 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(Number(price), 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(Number(price), 2, '.', ',');
      break;
    case 'amount_with_space_separator':
      value = formatWithDelimiters(Number(price), 2, ' ', ',');
      break;
    case 'amount_with_period_and_space_separator':
      value = formatWithDelimiters(Number(price), 2, ' ', '.');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(Number(price), 0, '.', ',');
      break;
    case 'amount_no_decimals_with_space_separator':
      value = formatWithDelimiters(Number(price), 0, '.', '');
      break;
    case 'amount_with_apostrophe_separator':
      value = formatWithDelimiters(Number(price), 2, "'", '.');
  }
  return format?.replace(placeholderRegex, value?.toString() || '');
};
