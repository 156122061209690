import type { ExternalStore } from '@hooks/useStateAsExternalStore';
import type { CurrentCollectionFilterState } from '@providers/FilterProvider';
import { useProductsFilterResultState } from '@providers/FilterProvider';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';

import type { ProductListItem, ProductListPaginationState, ProductListStateValue } from '../types';

const ProductListStateContext = createContext<ProductListStateValue | null>(null);

export type ProductListStateProviderProps = PropsWithChildren<{
  productsStore: ExternalStore<{
    onProductPageChange?: ProductListStateValue['changeProductPage'];
    fetchingData: boolean;
    products: ProductListItem[];
    totalProducts?: number;
    pagination?: ProductListPaginationState;
    currentCollectionSelected?: CurrentCollectionFilterState;
  }>;
}>;

export const ProductListStateProvider = ({
  productsStore,
  children,
}: ProductListStateProviderProps) => {
  const {
    products,
    totalProducts = 0,
    pagination,
    fetchingData,
    onProductPageChange,
    currentCollectionSelected,
  } = useProductsFilterResultState();

  const changeProductPage: ProductListStateValue['changeProductPage'] = useCallback(
    (page, options, currentCollectionSelected) => {
      if (onProductPageChange) {
        return onProductPageChange(page, options, currentCollectionSelected);
      }
    },
    [onProductPageChange]
  );

  const providerValue: ProductListStateValue = {
    products,
    totalProducts,
    pagination,
    changeProductPage,
    loading: fetchingData,
    currentCollectionSelected,
  };

  return (
    <ProductListStateContext.Provider value={providerValue}>
      {children}
    </ProductListStateContext.Provider>
  );
};

export const useProductListState = (): ProductListStateValue => {
  const context = useContext(ProductListStateContext);

  if (!context) {
    throw Error('Use useProductListState in ProductListStateProvider');
  }

  return context;
};
