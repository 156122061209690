import { registryModule } from '@boost-sd/components-registry/registry';
import { buildUrlWithLocalization } from '@utils';

const PATH = 'collections';

export const CollectionAPI = registryModule('CollectionAPI', {
  get: async (handle: string) => {
    const response = await fetch(buildUrlWithLocalization(`${PATH}/${handle}.json`), {
      method: 'GET',
    });
    return await response.json();
  },
});
