import { CollectionAPI } from '@apis/collection';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { Dict } from '@boost-sd/core-js';
import { useComponentStatus } from '@boost-sd/core-js';
import Breadcrumb from '@components/Breadcrumb';
import HeaderDescription from '@components/Header/HeaderDescription';
import HeaderImage from '@components/Header/HeaderImage';
import HeaderTitle from '@components/Header/HeaderTitle';
import HeaderMain1 from '@components/Header/Main1';
import HeaderMain2 from '@components/Header/Main2';
import HeaderMain3 from '@components/Header/Main3';
import HeaderMain4 from '@components/Header/Main4';
import ProductCount from '@components/ProductCount';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import type { CollectionHeader as CollectionHeaderType } from '@providers/FilterProvider';
import { useFilterState } from '@providers/FilterProvider';
import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import {
  createClsNameMap,
  getLocalStorage,
  isCollectionPage,
  objectKeys,
  setLocalStorage,
} from '@utils';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const clsNameMap = createClsNameMap({})('collection-header');

export type Positions =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'middle-left'
  | 'middle-center'
  | 'middle-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

type CollectionHeaderProps = {
  defaultHeaderImage?: string;
  defaultHeaderDescription?: string;
};

const CollectionHeader = ({
  defaultHeaderImage,
  defaultHeaderDescription,
}: CollectionHeaderProps) => {
  const { t } = useTranslation();

  const contentArea = useRef<HTMLDivElement>(null);
  const collectionHeaderRef = useRef<HTMLDivElement>(null);

  const [isChangePosition, setChangePosition] = useState(false);
  const [collection, setCollection] = useState<CollectionHeaderType | null>(
    getLocalStorage('boostSDCollection')?.collection
  );

  const { totalProducts, fromProductIndex, toProductIndex, totalPages, page } =
    useAdditionalElementState();
  const { currentCollectionSelected } = useFilterState();
  const { status } = useComponentStatus();

  const {
    generalSettings: { collection_id, collection_handle },
  } = useGeneralSettings();
  const { collectionHeader } = useAdditionalElementThemeSettings();

  const collectionImage = collection?.image?.src || defaultHeaderImage;
  const collectionDescription = collection?.description || defaultHeaderDescription;
  const size = collectionHeader.elements?.collectionImage?.size;

  useLayoutEffect(() => {
    if (!size || size === 'original') {
      return;
    }

    if (contentArea.current) {
      const imageHeight = getHeaderImageHeight();

      // This take long time to render and cause window to calculate clientHeight incorrectly
      const longLoadedElements = contentArea.current.querySelectorAll('img, iframe, video') || [];
      let extraHeight = 0;
      longLoadedElements.forEach(() => {
        extraHeight += 300;
      });

      if (contentArea.current.clientHeight + extraHeight >= imageHeight) {
        setChangePosition(true);
      }
    }
  }, [collection, contentArea.current]);

  useEffect(() => {
    /**
     * Handles the fallback scenario when preloaded collection data is not available
     * and reflects changes when the selected collection filter changed
     */
    if (!collection || status === 're-rendering') {
      const handle = currentCollectionSelected.handle || collection_handle;
      if (
        (collection_id && handle) ||
        (collection_id === 0 && handle !== 'all' && isCollectionPage())
      ) {
        CollectionAPI.get(handle)
          .then(({ collection }: Dict) => {
            setCollection(collection);
          })
          .catch(() => {
            setCollection(null);
          });
      } else {
        setCollection(null);
      }
    }
  }, [currentCollectionSelected.handle, collection_handle, collection_id]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => setLocalStorage('boostSDCollection', null));

    return () => {
      window.removeEventListener('beforeunload', () => setLocalStorage('boostSDCollection', null));
    };
  }, []);

  const getHeaderImageHeight = () => {
    // get variable defined, can be either vh or px
    const sizeVariable = getComputedStyle(document.documentElement).getPropertyValue(
      `--boostsd-header-image-size-${size}`
    );

    if (sizeVariable.includes('vh')) {
      /**
       * vh -> px
       * for ex: document.documentElement.clientHeight = 2560px
       * 30vh = 2560 * 0.3 = 768px
       */
      const vh = parseFloat(sizeVariable.replace('vh', ''));
      const pxValue = document.documentElement.clientHeight * (vh / 100);

      return pxValue;
    }

    return parseFloat(sizeVariable.replace('px', ''));
  };

  const renderBreadCrumb = () => {
    const alignment =
      collectionHeader.elements?.breadCrumb?.placement === 'above-title'
        ? (`middle-${collectionHeader.elements?.collectionTitle?.textAlign}` as Positions)
        : collectionHeader.elements?.breadCrumb?.alignment;

    return collectionHeader.elements?.breadCrumb ? (
      <Breadcrumb
        {...collectionHeader.elements?.breadCrumb}
        alignment={alignment}
        totalPages={totalPages}
        page={page}
        collectionTitle={collection?.title}
      />
    ) : (
      <></>
    );
  };

  const renderProductCount = () => {
    return collectionHeader.elements?.productCount ? (
      <ProductCount
        {...collectionHeader.elements?.productCount}
        textAlign={collectionHeader.elements?.collectionTitle?.textAlign}
        totalProducts={totalProducts}
        fromProductIndex={fromProductIndex}
        toProductIndex={toProductIndex}
        locatedIn='CollectionHeader'
      />
    ) : (
      <></>
    );
  };

  const renderHeaderTitle = () => {
    return (
      <>
        {collectionHeader.elements?.breadCrumb?.placement === 'above-title' && renderBreadCrumb()}

        {collectionHeader.elements?.collectionTitle ? (
          <HeaderTitle
            {...collectionHeader.elements?.collectionTitle}
            title={collection?.title ?? t('collectionHeader.collectionAllProduct')}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderHeaderImage = (imageSizeModifiers?: Dict) => {
    const headerImageProps = {
      image: collectionImage,
      altImage: collection?.image?.alt,
      ...collectionHeader.elements?.collectionImage,
    };

    const sizeModifiers = {
      [headerImageProps.size as string]: true,
      ...imageSizeModifiers,
    };

    const collectionHeaderWidth = collectionHeaderRef?.current?.offsetWidth || window.innerWidth;

    const size =
      collectionHeader.layout === 1
        ? collectionHeaderWidth / window.devicePixelRatio / 2
        : collectionHeaderWidth / window.devicePixelRatio || 1;

    return collectionHeader.elements?.collectionImage ? (
      <HeaderImage {...headerImageProps} imageSizeModifiers={sizeModifiers} size={size} />
    ) : (
      <></>
    );
  };

  const renderHeaderDescription = (descriptionModifiers?: Dict) => {
    return collectionHeader.elements?.collectionDescription ? (
      <HeaderDescription
        description={collectionDescription}
        descriptionModifiers={descriptionModifiers}
      />
    ) : (
      <></>
    );
  };

  const renderCollectionHeader = () => {
    switch (collectionHeader.layout) {
      case 1:
        return (
          <HeaderMain1
            {...collectionHeader}
            elements={{
              headerTitle: renderHeaderTitle(),
              productCount: renderProductCount(),
              headerDescription: renderHeaderDescription(),
              headerImage: renderHeaderImage({ 'as-main-1': true }),
            }}
          />
        );
      case 2:
        return (
          <HeaderMain2
            {...collectionHeader}
            elements={{
              headerTitle: renderHeaderTitle(),
              productCount: renderProductCount(),
              headerDescription: renderHeaderDescription(),
              headerImage: renderHeaderImage({ absolute: isChangePosition }),
            }}
            boxCollectionTitleColor={
              collectionHeader.elements?.collectionTitle?.boxCollectionTitleColor
            }
            isChangePosition={isChangePosition}
            contentArea={contentArea}
            hasCollectionImage={Boolean(
              collectionImage && collectionHeader.elements?.collectionImage
            )}
          />
        );
      case 3:
        return (
          <HeaderMain3
            {...collectionHeader}
            elements={{
              headerTitle: renderHeaderTitle(),
              productCount: renderProductCount(),
              headerDescription: renderHeaderDescription(),
              headerImage: renderHeaderImage({ 'as-main-3': true }),
            }}
          />
        );
      case 4:
        return (
          <HeaderMain4
            {...collectionHeader}
            elements={{
              headerTitle: renderHeaderTitle(),
              productCount: renderProductCount(),
              headerDescription: renderHeaderDescription({ 'as-main-4': true }),
              headerImage: renderHeaderImage(),
            }}
            hasCollectionImage={Boolean(
              collectionImage && collectionHeader.elements?.collectionImage
            )}
          />
        );
      default:
        return <></>;
    }
  };

  if (!collectionHeader.elements || objectKeys(collectionHeader.elements).length === 0) {
    return <></>;
  }

  return (
    <div className={clsNameMap.root()} ref={collectionHeaderRef}>
      {collectionHeader.elements?.breadCrumb?.placement === 'on-top' && renderBreadCrumb()}
      {renderCollectionHeader()}
    </div>
  );
};

export default registryComponent('CollectionHeader', CollectionHeader);
