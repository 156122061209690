import { getQueryParamByKey } from '@boost-sd/core-js/history';
import { getOnClickRecentSearches } from '@constants/instantSearchClick';
import { SCOPED_CATEGORY, scopedFilterParams } from '@constants/scopedSuggestion';
import type { FilterData, FilterParams, ScopedFilter } from '@providers/FilterProvider';
import { FILTER_OPTION_DISPLAY_TYPE, FILTER_OPTION_TYPE } from '@providers/FilterProvider';
import type { Dict } from '@types';
import { isSearchPage } from '@utils';
import {
  buildNumberLabel,
  formatMoney,
  formatPercentSaleLabel,
  formatPriceLabel,
  formatRatingValue,
  roundAmount,
  stripHtml,
} from '@utils/format';
import { isArray } from 'lodash-es';

export const optionIdsOfRefineBy = (_filterParams: FilterParams, filterPrefixParam = 'pf_') => {
  const isArrayHasValue = (optionId: string) => {
    return isArray(_filterParams[optionId]) && _filterParams[optionId]?.length > 0;
  };

  return Object.keys(_filterParams).filter((optionId) => {
    if (optionId.endsWith('c_collection') && isSearchPage()) {
      if (
        isArrayHasValue(optionId) || // for case horizontal
        (_filterParams[optionId] && !isArray(_filterParams[optionId])) // for case vertical
      )
        return true;

      return false;
    }

    // allow param endWith ct_collection is show in refine By on search page
    if (optionId.endsWith('ct_collection') && isSearchPage() && _filterParams[optionId]) {
      return true;
    }

    return (
      _filterParams[optionId] &&
      optionId.startsWith(filterPrefixParam) &&
      isArrayHasValue(optionId) &&
      !optionId.startsWith('collection_scope') &&
      !optionId.endsWith('_and_condition') &&
      !optionId.endsWith('_exclude_from_value')
    );
  });
};

export const getFilterOptionByOptionId = (
  filterData: FilterData,
  optionId: string,
  value: string,
  rateCurrency: number,
  unitCurrency: string,
  formatPipsRange: {
    node: number;
    symbol: string;
    fix: number;
    suffix: boolean;
  }[],
  removePriceDecimal: boolean,
  otherSettings?: Dict
) => {
  const removePrefixAndStripHtmlValue = (value?: string, prefix?: string) => {
    if (!value) return '';

    if (prefix) {
      // remove prefix save in database
      prefix = prefix.replace(/\\/g, '');

      return stripHtml(value?.replace(prefix, ''));
    }

    return stripHtml(value);
  };

  if (optionId.endsWith('ct_collection')) {
    return {
      refineByValue: value?.replace(/.+:/gm, ''),
      refineByLabel: 'CollectionTag',
    };
  }

  //cover case filter is meta field
  optionId = optionId.replaceAll('%3A', ':');

  const _option = filterData.data?.filter.options.filter((option) => {
    const { scoped_vendor, scoped_product_type, scoped_category, scoped_collection } =
      scopedFilterParams;
    const { VENDOR, PRODUCT_TYPE, PRODUCT_CATEGORY, COLLECTION } = FILTER_OPTION_TYPE;

    return (
      option.filterOptionId === optionId ||
      (optionId === scoped_vendor && option.filterType === VENDOR) ||
      (optionId === scoped_product_type && option.filterType === PRODUCT_TYPE) ||
      (optionId === scoped_category && option.filterType === PRODUCT_CATEGORY) ||
      (optionId === scoped_collection && option.filterType === COLLECTION)
    );
  })[0];

  // if value or _option not existed return empty
  if (!_option || !value) {
    return {
      refineByValue: value,
      refineByLabel: '',
    };
  }

  // set default value and label
  _option.refineByValue = value;
  _option.refineByLabel = stripHtml(_option.label);

  // filterType Collection
  if (_option?.filterType === FILTER_OPTION_TYPE.COLLECTION) {
    const collectionItem = _option.values?.filter(
      (item) => item?.key?.toString() === value.toString()
    );

    const scopedCollectionValue = getQueryParamByKey('collections') as string;
    const scopedCollection = getOnClickRecentSearches(10).find((recentSearch) => {
      return recentSearch?.extraParam?.split('=')?.[1] === scopedCollectionValue;
    });

    if (collectionItem) {
      _option.refineByValue = collectionItem[0]?.displayName || collectionItem[0]?.label;
    } else if (scopedCollection && scopedCollectionValue) {
      _option.refineByValue = stripHtml(scopedCollection.scope);
    }

    _option.refineByLabel = stripHtml(_option.label);

    // filterType Rating
  } else if (_option.filterType === FILTER_OPTION_TYPE.REVIEW_RATINGS) {
    _option.refineByValue = formatRatingValue(Number(value), _option?.showExactRating);

    // filterType Percent sale
  } else if (_option.filterType === FILTER_OPTION_TYPE.PERCENT_SALE) {
    const [from, to] = (value || []).toString().split(':');
    _option.refineByValue = formatPercentSaleLabel(Number(to), Number(from));

    // filterType price & variants
  } else if (
    [FILTER_OPTION_TYPE.PRICE, FILTER_OPTION_TYPE.VARIANTS_PRICE].includes(_option.filterType)
  ) {
    let [from, to] = (value || []).toString().split(':');

    from = `${roundAmount(+from * rateCurrency, 2)}`;
    to = `${roundAmount(+to * rateCurrency, 2)}`;

    _option.refineByLabel = _option.label + ` (${formatMoney('', unitCurrency)})`;

    // cover case range with from = 0
    const isUseZeroFrom = _option.displayType === FILTER_OPTION_DISPLAY_TYPE.RANGE;

    _option.refineByValue = formatPriceLabel(Number(to), Number(from), unitCurrency, isUseZeroFrom);

    // displayType RANGE
  } else if (_option.displayType === FILTER_OPTION_DISPLAY_TYPE.RANGE) {
    let [from, to] = (value || []).toString().split(':');
    const precision = otherSettings?.precisionFormatLabelSlider || 0;

    from = `${roundAmount(+from * rateCurrency, precision)}`;
    to = `${roundAmount(+to * rateCurrency, precision)}`;

    if (Number(from) !== Number(to)) {
      _option.refineByValue =
        buildNumberLabel(
          Number(from),
          _option?.shortenPipsRange || false,
          _option,
          formatPipsRange,
          removePriceDecimal,
          precision
        ) +
        ' - ' +
        buildNumberLabel(
          Number(to),
          _option?.shortenPipsRange || false,
          _option,
          formatPipsRange,
          removePriceDecimal,
          precision
        );
    } else {
      _option.refineByValue = buildNumberLabel(
        Number(from),
        _option?.shortenPipsRange || false,
        _option,
        formatPipsRange,
        removePriceDecimal,
        precision
      );
    }

    // Else and Value is Array
  } else if (isArray(_option.values)) {
    // cover type is multi level tag or any option is tag
    const optionItem = (_option.values as any[])?.filter(
      (item) => item.key === value || item.tag === value
    )[0];

    _option.refineByValue = optionItem?.label || optionItem?.displayName || value || '';

    // Cover case BE not return out of stock
    if (_option.refineByValue === 'out-of-stock') {
      _option.refineByValue = 'Out Of Stock';
    }

    // Cover case BE not return in stock
    if (_option.refineByValue === 'in-stock') {
      _option.refineByValue = 'In Stock';
    }
  }

  // remove prefix and strip html value
  _option.refineByValue = removePrefixAndStripHtmlValue(_option.refineByValue, _option.prefix);

  return _option;
};

export const getFilterOptionShow = (
  filterData: FilterData,
  filterSettings: { showSingleOption: boolean; showOutOfStockOption: boolean }
) => {
  const { showSingleOption, showOutOfStockOption } = filterSettings;

  const filterOptionShow = filterData.data?.filter?.options?.filter((option) => {
    if (
      option.status === 'disabled' ||
      !option?.values ||
      (isArray(option.values) && option.values.length === 0) ||
      (Array.isArray(option?.values) && option?.values?.length === 0)
    )
      return false;

    // remove all option values is invalid doc_count when !showOutOfStockOption && !option.keepValuesStatic
    if (
      (isArray(option.values) || Array.isArray(option?.values)) &&
      !showOutOfStockOption &&
      !option.keepValuesStatic
    ) {
      option.values = option.values.filter((value) => value.doc_count && value.doc_count > 0);

      if (option.values?.length === 0) return false;
    }

    // The setting name is "showSingleOption" but if set to "true", it will "Hide filter options with only one filter option value".
    const hideSingleOptionValue = showSingleOption;
    const isMultiLevel =
      option.displayType === FILTER_OPTION_DISPLAY_TYPE.MULTI_LEVEL_COLLECTIONS ||
      option.filterType === FILTER_OPTION_TYPE.MULTI_LEVEL_TAG;

    if (hideSingleOptionValue) {
      // apply for array
      if (isArray(option.values) && option.values.length === 1) {
        // multilevel special case
        if (isMultiLevel) {
          let numberFilterOptionValue = 1;

          if (
            option.displayType === FILTER_OPTION_DISPLAY_TYPE.MULTI_LEVEL_COLLECTIONS &&
            option.values[0]?.tags?.length > 0
          ) {
            numberFilterOptionValue += option.values[0]?.tags?.length;
          }

          if (
            option.filterType === FILTER_OPTION_TYPE.MULTI_LEVEL_TAG &&
            option.values[0]?.subTags &&
            option.values[0]?.subTags?.length > 0
          ) {
            numberFilterOptionValue += option.values[0]?.subTags?.length;
          }

          if (numberFilterOptionValue === 1) return false;
        } else {
          return false;
        }
      }
    }

    if (
      !Array.isArray(option?.values) &&
      typeof option?.values === 'object' &&
      option?.values?.min === option?.values?.max
    ) {
      return false;
    }

    return true;
  });

  if (filterOptionShow && filterOptionShow?.length > 0) {
    return filterOptionShow;
  }

  return [];
};

/**
 * Avoid showing up refine by of scoped suggestion when param field option doesn't available in API data
 * @param {filterData}
 * @param {filterParams}
 */
export const hasScopeFilterParam = (
  filterData: FilterData,
  filterParams: FilterParams,
  scopedFilterType?: ScopedFilter | null
) => {
  let param = '';
  let type = '';

  if (scopedFilterType) {
    param = scopedFilterParams[scopedFilterType];
    type =
      scopedFilterType === SCOPED_CATEGORY
        ? 'product_category'
        : scopedFilterType.slice(0, scopedFilterType.length - 1);

    const filterOption = filterData.data?.filter?.options.find((option) => {
      return option.filterOptionId === param || option.filterType === type;
    });

    const filterParamsLength = Object.values(filterParams).filter((val) => {
      return Boolean(val);
    }).length;

    /**
     * If scoped exists in URL, but no filter option is found (no data in API) and don't have other filterParams then hide Refine By
     */
    if (type && !filterOption && filterParamsLength === 1) {
      return false;
    }
  }

  return true;
};
