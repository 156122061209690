import './HeaderMain3.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ProductCountProps } from '@components/ProductCount';
import { createClsNameMap, mergeModifiers } from '@utils/classnames';

import type { Positions } from '@/widgets/CollectionHeader';

import type { HeaderDescriptionProps } from '../HeaderDescription';
import type { HeaderImageProps } from '../HeaderImage';
import type { HeaderTitleProps } from '../HeaderTitle';

export const clsNameMap = createClsNameMap({
  elements: {
    content: createClsNameMap({
      modifiers: ['middle-left', 'middle-center', 'middle-right'],
    }),
    image: createClsNameMap(),
  },
})('header-main-3');

export type HeaderMain3Props = {
  elements?: {
    productCount?: React.ReactElement<ProductCountProps>;
    headerTitle?: React.ReactElement<HeaderTitleProps>;
    headerDescription?: React.ReactElement<HeaderDescriptionProps>;
    headerImage?: React.ReactElement<HeaderImageProps>;
  };
  backgroundColor?: string;
  contentPosition?: Positions;
};

const HeaderMain3 = ({ elements, backgroundColor, contentPosition }: HeaderMain3Props) => {
  return (
    <div className={clsNameMap.root()} style={{ backgroundColor }}>
      {elements?.headerImage}
      <div
        className={mergeModifiers(clsNameMap.content, [
          {
            [contentPosition as string]: true,
          },
        ])}
      >
        {elements?.headerTitle}
        {elements?.productCount}
        {elements?.headerDescription}
      </div>
    </div>
  );
};

export default registryComponent('HeaderMain3', HeaderMain3);
