import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import type { AdditionalElementThemeSettings } from '@providers/ThemeProvider/types';
import { useMemo } from 'react';

export type ToolbarLayoutPropsMapSettings = AdditionalElementThemeSettings['toolbar']['layout'];

export const useConnectToolbarLayout = () => {
  const additionalElementSettings = useAdditionalElementThemeSettings();
  const { layout } = additionalElementSettings.toolbar;

  const props: ToolbarLayoutPropsMapSettings = useMemo(() => {
    return layout;
  }, [layout]);

  return {
    props,
  };
};
