import './HeaderMain2.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ProductCountProps } from '@components/ProductCount';
import { createClsNameMap, mergeModifiers } from '@utils';

import type { Positions } from '@/widgets/CollectionHeader';

import type { HeaderDescriptionProps } from '../HeaderDescription';
import type { HeaderImageProps } from '../HeaderImage';
import type { HeaderTitleProps } from '../HeaderTitle';

export const clsNameMap = createClsNameMap({
  elements: {
    content: createClsNameMap({
      modifiers: ['relative', 'full-width'],
    }),
    image: createClsNameMap(),
  },
  modifiers: [
    'top-left',
    'top-center',
    'top-right',
    'middle-left',
    'middle-center',
    'middle-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
  ],
})('header-main-2');

export type HeaderMain2Props = {
  elements?: {
    productCount?: React.ReactElement<ProductCountProps>;
    headerTitle?: React.ReactElement<HeaderTitleProps>;
    headerDescription?: React.ReactElement<HeaderDescriptionProps>;
    headerImage?: React.ReactElement<HeaderImageProps>;
  };
  backgroundColor?: string;
  boxCollectionTitleColor?: string;
  contentPosition?: Positions;
  isChangePosition?: boolean;
  hasCollectionImage?: boolean;
  contentArea: React.RefObject<HTMLDivElement>;
  isPreview?: boolean;
};

const HeaderMain2 = ({
  backgroundColor,
  boxCollectionTitleColor,
  contentPosition,
  isChangePosition,
  hasCollectionImage,
  contentArea,
  elements,
}: HeaderMain2Props) => {
  return (
    <div
      className={mergeModifiers(clsNameMap, [
        {
          [contentPosition as string]: true,
        },
      ])}
      style={{ backgroundColor }}
    >
      <div
        className={mergeModifiers(clsNameMap.content, [
          {
            relative: !hasCollectionImage || isChangePosition === true,
          },
        ])}
        style={{
          backgroundColor: hasCollectionImage ? boxCollectionTitleColor : 'transparent',
        }}
        ref={contentArea}
      >
        {elements?.headerTitle}
        {elements?.productCount}
        {elements?.headerDescription}
      </div>

      {elements?.headerImage}
    </div>
  );
};

export default registryComponent('HeaderMain2', HeaderMain2);
