import {
  AdditionalElementProvider,
  CallToActionForOtherPage,
  CollectionHeaderWidget,
  FilterProvider,
  FilterTreeWidget,
  getGeneralSettingsFromAdmin,
  InCollectionSearch,
  isCollectionPage,
  isMobileWidth,
  ProductListProvider,
  ProductListWidget,
  rebuildProductQuickViewDataFromShopifyData,
  ThemeProvider,
  ToolbarWidget,
  useAdditionalElementFilterResult,
  useFilterSettings,
  useProductsFilterResult,
} from '@boost-sd/app';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import type { Block } from '../types';

export type ProductFilterCollectionPage = Block<{
  blockSelector: string;
  productItemsSelector?: string;
  productImageSelector?: string;
  collectionHeaderOnRight?: boolean;
}>;

const defaultConfig = {
  blockSelector: process.env.FILTER_BLOCK_SELECTOR || '',
  productItemsSelector: '.grid__item .product-card-wrapper',
  productImageSelector: '.card__inner',
  cartContainerClassName: 'cart-container',
  collectionHeaderOnRight: false,
} as const;

export const createProductFilterCollectionPageBlock = (
  config: ProductFilterCollectionPage['config'] = defaultConfig
): ProductFilterCollectionPage => {
  return {
    config: {
      ...defaultConfig,
      ...config,
    },
    render() {
      const { blockSelector, productImageSelector, productItemsSelector, collectionHeaderOnRight } =
        {
          ...defaultConfig,
          ...config,
        };

      const generalSettings = getGeneralSettingsFromAdmin();

      const CollectionPage = () => {
        const productsSource = useProductsFilterResult();

        const additionalElementSource = useAdditionalElementFilterResult();

        const {
          filterLayout,
          filterTreeMobileStyle,
          filterTreeHorizontalStyle,
          filterTreeVerticalStyle,
        } = useFilterSettings();

        const isMobileExpandStyle = filterTreeMobileStyle === 'style1' && isMobileWidth();

        const isFilterVertical = filterLayout === 'vertical';
        const isFilterHorizontal = filterLayout === 'horizontal';
        const isFilterHorizontalStyleExpand =
          isFilterHorizontal && filterTreeHorizontalStyle === 'style-expand';
        const isFilterVerticalStyleExpand =
          isFilterVertical && filterTreeVerticalStyle === 'style-expand';
        const isFilterVerticalStyleOffCanvas =
          isFilterVertical && filterTreeVerticalStyle === 'style-off-canvas';
        const isFilterStyleExpand =
          !isMobileWidth() && (isFilterVerticalStyleExpand || isFilterHorizontalStyleExpand);
        const isFilterVerticalStyleDefault =
          isFilterVertical && filterTreeVerticalStyle === 'style-default';

        const filterLayoutStyle =
          (isFilterVertical && filterTreeVerticalStyle) ||
          (isFilterHorizontal && filterTreeHorizontalStyle);

        const isShowCollectionSearchOnTop =
          generalSettings.isShowCollectionSearchOnTop && isFilterVertical;

        return (
          <div className={`boost-sd-container`}>
            <AdditionalElementProvider additionalElementStore={additionalElementSource}>
              {(!collectionHeaderOnRight || !isFilterVerticalStyleDefault) && (
                <CollectionHeaderWidget />
              )}

              {(isShowCollectionSearchOnTop ||
                isFilterHorizontal ||
                isFilterVerticalStyleExpand ||
                isFilterVerticalStyleOffCanvas) && (
                <InCollectionSearch layoutModifiers={{ horizontal: true }} />
              )}

              {isFilterStyleExpand && (
                <ToolbarWidget
                  filterTree={isMobileExpandStyle ? <FilterTreeWidget /> : undefined}
                />
              )}

              <div
                className={`boost-sd-layout boost-sd-layout--has-filter-${filterLayout} boost-sd-layout--has-${filterLayout}-${filterLayoutStyle}`}
              >
                <div className='boost-sd-left'>
                  {!isMobileExpandStyle ? <FilterTreeWidget /> : undefined}
                </div>
                <div className='boost-sd-right'>
                  {collectionHeaderOnRight && isFilterVerticalStyleDefault && (
                    <CollectionHeaderWidget />
                  )}
                  {isFilterVertical &&
                    !isFilterVerticalStyleExpand &&
                    !isFilterVerticalStyleOffCanvas && (
                      <InCollectionSearch
                        layoutModifiers={{ vertical: true }}
                        isHide={isShowCollectionSearchOnTop}
                      />
                    )}

                  {!isFilterStyleExpand && (
                    <ToolbarWidget
                      filterTree={isMobileExpandStyle ? <FilterTreeWidget /> : undefined}
                    />
                  )}
                  <ProductListProvider productsStore={productsSource}>
                    <ProductListWidget />
                  </ProductListProvider>
                </div>
              </div>
            </AdditionalElementProvider>
          </div>
        );
      };

      const hasCallToActionInOtherPages = !!document.querySelector('.boost-pfs-product-data-json');

      const initCallToActionForProductItem = () => {
        const productItemEls = document.querySelectorAll(productItemsSelector);

        const listCTAComponents: React.ReactElement[] = [];

        productItemEls.forEach((item, index) => {
          const productImageEl = item.querySelector(productImageSelector);

          const scriptData = item.querySelector('.boost-pfs-product-data-json');

          if (!scriptData || !productImageEl) return;

          const productData = JSON.parse(scriptData.innerHTML);

          const quickViewData = rebuildProductQuickViewDataFromShopifyData(productData);

          listCTAComponents.push(
            <CallToActionForOtherPage
              key={index}
              productData={quickViewData}
              containerEl={productImageEl}
            />
          );
        });

        const cartContainerEl = document.createElement('div');
        cartContainerEl.className = 'cta-container';
        document.body.append(cartContainerEl);

        const root = createRoot(cartContainerEl);
        root.render(listCTAComponents);
      };

      if (
        isCollectionPage() ||
        (generalSettings.preview_mode && generalSettings.preview_path === '/collections')
      ) {
        const elm = document.querySelector(blockSelector);

        if (elm) {
          const root = createRoot(elm);

          root.render(
            <ThemeProvider>
              <FilterProvider>
                <CollectionPage />
              </FilterProvider>
            </ThemeProvider>
          );
        }
      } else if (hasCallToActionInOtherPages) {
        initCallToActionForProductItem();
      }
    },
  };
};
