import './HeaderMain4.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ProductCountProps } from '@components/ProductCount';
import { createClsNameMap, mergeModifiers } from '@utils/classnames';

import type { Positions } from '@/widgets/CollectionHeader';

import type { HeaderDescriptionProps } from '../HeaderDescription';
import type { HeaderImageProps } from '../HeaderImage';
import type { HeaderTitleProps } from '../HeaderTitle';

export const clsNameMap = createClsNameMap({
  elements: {
    wrapper: createClsNameMap({
      modifiers: [
        'top-left',
        'top-center',
        'top-right',
        'middle-left',
        'middle-center',
        'middle-right',
        'bottom-left',
        'bottom-center',
        'bottom-right',
      ],
    }),
    content: createClsNameMap({
      modifiers: ['relative', 'full-width'],
    }),
    image: createClsNameMap(),
    description: createClsNameMap(),
  },
})('header-main-4');

export type HeaderMain4Props = {
  elements?: {
    productCount?: React.ReactElement<ProductCountProps>;
    headerTitle?: React.ReactElement<HeaderTitleProps>;
    headerDescription?: React.ReactElement<HeaderDescriptionProps>;
    headerImage?: React.ReactElement<HeaderImageProps>;
  };
  backgroundColor?: string;
  boxCollectionTitleColor?: string;
  contentPosition?: Positions;
  hasCollectionImage?: boolean;
};

const HeaderMain4 = ({
  elements,
  backgroundColor,
  boxCollectionTitleColor,
  contentPosition,
  hasCollectionImage,
}: HeaderMain4Props) => {
  return (
    <div className={clsNameMap.root()} style={{ backgroundColor }}>
      <div
        className={mergeModifiers(clsNameMap.wrapper, [
          {
            [contentPosition as string]: true,
          },
        ])}
      >
        <div
          className={mergeModifiers(clsNameMap.content, [
            {
              relative: !hasCollectionImage,
            },
          ])}
          style={{
            backgroundColor: hasCollectionImage ? boxCollectionTitleColor : 'transparent',
          }}
        >
          {elements?.headerTitle}
          {elements?.productCount}
        </div>

        {elements?.headerImage}
      </div>

      {elements?.headerDescription}
    </div>
  );
};

export default registryComponent('HeaderMain4', HeaderMain4);
