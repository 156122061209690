import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useImperativeHandle, useState } from 'react';

import type { TypeCurrencyState } from '../type';

export const CurrencyState = createContext<TypeCurrencyState | null>(null);

export type CurrencyProviderProps = RegistryComponentProps<
  {
    getRateCurrency?: (currency?: string) => number;
    getUnitCurrency?: (currency?: string) => string;
  },
  {
    setInfoCurrency?: (event: any) => void;
  }
>;

export const CurrencyProvider = registryComponent(
  'CurrencyProvider',
  ({
    children,
    getRateCurrency,
    getUnitCurrency,
    helpersRef,
  }: PropsWithChildren<CurrencyProviderProps>) => {
    const [value, setValue] = useState<TypeCurrencyState>({
      rateCurrency: 1,
      unitCurrency: '',
      currency: '',
    });

    const handleChangeCurrency = (event: any) => {
      if (!getRateCurrency || !getUnitCurrency) return;

      const currency = event.target.dataset.value;

      const rateCurrency = getRateCurrency(currency);
      const unitCurrency = getUnitCurrency(currency);

      setValue(() => ({ rateCurrency, unitCurrency, currency }));
    };

    useImperativeHandle(helpersRef, () => ({
      setInfoCurrency(event: any) {
        handleChangeCurrency(event);
      },
    }));

    return <CurrencyState.Provider value={value}>{children}</CurrencyState.Provider>;
  }
);

export const useCurrency = (): TypeCurrencyState => {
  const context = useContext(CurrencyState);

  if (!context) {
    throw Error('Use useCurrency in CurrencyProvider');
  }

  return context;
};
