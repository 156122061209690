import './PaginationButton.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { InnerHTMLRenderer } from '@components/InnerHTMLRenderer';
import type { AdditionalElementThemeSettings } from '@providers/ThemeProvider/types';
import type { Dict } from '@types';
import type { ModifiersType } from '@utils';
import { createClsNameMap, isMobileWidth, mapModifiers, mergeModifiers } from '@utils';
import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';

const clsNameMap = createClsNameMap({
  elements: {
    icon: createClsNameMap({
      modifiers: ['left', 'right'],
    }),
    text: createClsNameMap(),
  },
  modifiers: ['square', 'circle', 'rectangle', 'rounded-rectangle', 'load-more', 'prev', 'next'],
})('pagination-button');

export type PaginationButtonProps = {
  className?: string;
  onClick: () => void;
  icon?: string;
  iconPosition: 'left' | 'right';
  text?: string;
  modifiers?: ModifiersType<typeof clsNameMap, true>;
  paginationType?: AdditionalElementThemeSettings['pagination']['paginationType'];
  shape?: 'rectangle' | 'rounded-rectangle' | 'square' | 'circle';
  buttonType?: 'text-only' | 'text-with-icon' | 'icon-only';
  color?: string;
  colorOnHover?: string;
  backgroundColor?: string;
  backgroundColorOnHover?: string;
  borderColor?: string;
  fontSize?: string | number;
  fontStyle?: string;
  fontWeight?: string | number;
  fontFamily?: string;
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
};

const PaginationButton = ({
  onClick,
  iconPosition,
  icon,
  text,
  modifiers,
  paginationType = 'default',
  fontFamily,
  fontSize,
  borderColor,
  color,
  colorOnHover,
  backgroundColor,
  backgroundColorOnHover,
  shape = 'rectangle',
  buttonType = 'icon-only',
  fontStyle,
  fontWeight,
  textTransform,
}: PaginationButtonProps) => {
  const sharedStyles = {
    fontFamily,
    fontSize: isMobileWidth() ? '10px' : fontSize || 'inherit',
    fontStyle,
    fontWeight,
    textTransform,
    borderColor: borderColor || 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
  };

  const stylesOfButton = {
    default: {
      color,
      backgroundColor,
      ...sharedStyles,
    },
    hover: {
      color: colorOnHover || color,
      backgroundColor: backgroundColorOnHover || backgroundColor,
      ...sharedStyles,
    },
  };

  const [buttonStyle, setButtonStyle] = useState<CSSProperties>(stylesOfButton.default);

  useEffect(() => {
    setButtonStyle(stylesOfButton.default);
  }, [
    color,
    colorOnHover,
    backgroundColor,
    backgroundColorOnHover,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    textTransform,
    borderColor,
  ]);

  const handleHoverButton = () => {
    setButtonStyle(stylesOfButton.hover);
  };

  const handleBlurButton = () => {
    setButtonStyle(stylesOfButton.default);
  };

  const getButtonModifiers = () => {
    const shapeModifier: Dict = {};

    if (buttonType !== 'icon-only') {
      shapeModifier.rectangle = true;
    } else {
      shapeModifier[shape] = true;
    }

    return {
      ...shapeModifier,
      ...modifiers,
    };
  };

  const getButtonText = () => {
    if (paginationType === 'default' && buttonType === 'icon-only') {
      return '';
    } else {
      return sharedStyles.textTransform === 'none' ? text : text?.toLowerCase();
    }
  };

  return (
    <button
      type='button'
      className={mergeModifiers(clsNameMap, [getButtonModifiers()])}
      style={buttonStyle}
      onMouseEnter={handleHoverButton}
      onMouseLeave={handleBlurButton}
      onClick={onClick}
    >
      {icon && buttonType !== 'text-only' && (
        <InnerHTMLRenderer
          className={mapModifiers(clsNameMap.icon, [iconPosition])}
          as='span'
          html={icon}
        />
      )}
      {getButtonText() && <span className={clsNameMap.elm('text')}>{getButtonText()}</span>}
    </button>
  );
};

export default registryComponent('PaginationButton', PaginationButton);
