import type { PropsWithChildren } from 'react';

import { SearchSettingsProvider } from './SearchSettings';
import { SearchStateProvider } from './SearchState';

export type SearchProviderProps = PropsWithChildren<unknown>;

export const SearchProvider = ({ children }: SearchProviderProps) => {
  return (
    <SearchSettingsProvider>
      <SearchStateProvider>{children}</SearchStateProvider>
    </SearchSettingsProvider>
  );
};

export * from './SearchSettings';
export * from './SearchState';
