import { SELECTED_PRODUCT_ID, SELECTED_PRODUCT_PAGE } from '@constants/pagination';
import type { ProductItem } from '@providers/ProductProvider';
import { getSessionStorage, setSessionStorage } from '@utils';
import { useRef } from 'react';

import { clsNameMap as recommendationCls } from '@/widgets/Recommendation';

const useKeepPositionOnBack = (
  products: ProductItem[],
  {
    enableKeepScrollbackPosition,
    scrollBackOffset = 0,
    scrollBackAwait = 100,
  }: {
    enableKeepScrollbackPosition?: boolean;
    scrollBackOffset?: number;
    scrollBackAwait?: number;
  }
) => {
  const hasRestorePosition = useRef(false);

  const scrollToProductAndCleanUpSession = () => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    const productId = getSessionStorage(SELECTED_PRODUCT_ID);

    if (productId) {
      const el = document.getElementById(productId);

      if (el && !el.closest(`.${recommendationCls.root()}`)) {
        setTimeout(() => {
          window.scrollTo({
            top: el.getBoundingClientRect().top + scrollBackOffset,
            behavior: 'smooth',
          });
        }, scrollBackAwait);
      }

      setSessionStorage(SELECTED_PRODUCT_ID, null);
      setSessionStorage(SELECTED_PRODUCT_PAGE, null);
    }
  };

  const performScrollRestoration = () => {
    if (!enableKeepScrollbackPosition) {
      setSessionStorage(SELECTED_PRODUCT_ID, null);
      setSessionStorage(SELECTED_PRODUCT_PAGE, null);
      return;
    }

    if (products.length > 0 && hasRestorePosition.current === false) {
      scrollToProductAndCleanUpSession();
      hasRestorePosition.current = true;
    }

    // register event in productlist
    const bindProductListChangeEvent = new CustomEvent('product-list-change');
    window.dispatchEvent(bindProductListChangeEvent);
  };

  return { performScrollRestoration };
};

export default useKeepPositionOnBack;
