import Button from '@components/Button';
import { useFilterSettings } from '@providers/FilterProvider';
import { debounce } from 'lodash-es';
import { useEffect, useState } from 'react';

const ScrollToTop = () => {
  const { activeScrollToTop, styleScrollToTop } = useFilterSettings();

  const [isScrollToTop, setIsScrollToTop] = useState<boolean>(false);

  const scrollOnTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const debounceScrollPage = debounce(() => {
      if (window.scrollY > 100) {
        !isScrollToTop && setIsScrollToTop(true);
      } else {
        setIsScrollToTop(false);
      }
    }, 50);

    activeScrollToTop && window.addEventListener('scroll', debounceScrollPage);
  }, []);

  if (!isScrollToTop) return <></>;

  return (
    <Button
      aria-label={'Back to top'}
      onClick={() => scrollOnTop()}
      buttonModifiers={[
        'scroll-to-top',
        styleScrollToTop === 'style2' ? 'scroll-to-top-style2' : 'scroll-to-top-style1',
        'visible',
      ]}
    ></Button>
  );
};

export default ScrollToTop;
