import './Breadcrumb.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import PlaceholderItem from '@components/PlaceHolderItem';
import { createClsNameMap, mapModifiers, mergeModifiers } from '@utils/classnames';
import { Trans, useTranslation } from 'react-i18next';

import type { Positions } from '@/widgets/CollectionHeader';

export const clsNameMap = createClsNameMap({
  elements: {
    container: createClsNameMap(),
    link: createClsNameMap({
      modifiers: ['no-hover'],
    }),
    pagination: createClsNameMap(),
  },
  modifiers: [
    'top-left',
    'top-center',
    'top-right',
    'middle-left',
    'middle-center',
    'middle-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
    'on-top',
  ],
})('breadcrumb');

export type BreadcrumbProps = {
  showPagination?: boolean;
  showCollectionList?: boolean;
  showCollectionTitle?: boolean;
  alignment?: Positions;
  placement?: 'on-top' | 'above-title';
  collectionLink?: string;
  totalPages?: number;
  page?: number;
  collectionTitle?: string;
  isLoading?: boolean;
};

const Breadcrumb = ({
  showPagination = false,
  showCollectionList = false,
  showCollectionTitle = false,
  alignment = 'middle-center',
  placement = 'on-top',
  collectionLink = '/collections',
  totalPages,
  page,
  collectionTitle,
  isLoading,
}: BreadcrumbProps) => {
  const { t } = useTranslation();

  return (
    <nav
      className={mergeModifiers(clsNameMap, [
        {
          [alignment as string]: true,
          'on-top': placement === 'on-top',
        },
      ])}
    >
      <div className={clsNameMap.elm('container')}>
        <a href='/' className={clsNameMap.elm('link')} title={t('breadcrumb.toFrontPage')}>
          {isLoading ? <PlaceholderItem /> : t('breadcrumb.home')}
        </a>

        {showCollectionList && (
          <a href={collectionLink} className={clsNameMap.elm('link')}>
            {isLoading ? <PlaceholderItem /> : t('breadcrumb.collections')}
          </a>
        )}

        {showCollectionTitle && (
          <div className={mapModifiers(clsNameMap.link, ['no-hover'])}>
            {isLoading ? <PlaceholderItem /> : collectionTitle || 'All'}
          </div>
        )}

        {showPagination && totalPages !== null && page !== null ? (
          <div className={mapModifiers(clsNameMap.link, ['no-hover'])}>
            {isLoading ? (
              <PlaceholderItem />
            ) : (
              <Trans i18nKey={'breadcrumb.pagination'} values={{ page, totalPages }} />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </nav>
  );
};

export default registryComponent('Breadcrumb', Breadcrumb);
