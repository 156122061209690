import { useEffect, useRef } from 'react';

export const useUpdateEffect: typeof useEffect = (effect, deps = []) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (!isFirst.current) {
      return effect();
    } else {
      isFirst.current = false;
    }
  }, deps);
};

export default useUpdateEffect;
