import './ProductListPagination.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import { getQueryParamByKey } from '@boost-sd/core-js/history';
import PaginationButton from '@components/PaginationButton';
import PaginationInfiniteScroll from '@components/PaginationInfiniteScroll';
import PaginationLoadMore from '@components/PaginationLoadMore';
import PaginationPageLink from '@components/PaginationPageLink';
import ProductCount from '@components/ProductCount';
import {
  DEFAULT,
  INFINITE,
  INITIAL_PAGE,
  LOAD_MORE,
  NEXT_PAGE,
  PREV_PAGE,
} from '@constants/pagination';
import useTranslation from '@hooks/useTranslation';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import type { CurrentCollectionFilterState } from '@providers/FilterProvider';
import type {
  LoadProductsBehavior,
  ProductListItem,
  ProductListPaginationState,
} from '@providers/ProductListProvider';
import { useAdditionalElementThemeSettings } from '@providers/ThemeProvider/Provider/AdditionalElementThemeSettings';
import { createClsNameMap, isSearchPage, setSessionStorage } from '@utils';
import type { MutableRefObject, PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';

export type ProductListPaginationProps = PropsWithChildren<{
  enable?: boolean;
  products: ProductListItem[];
  totalProducts: number;
  paginationState: ProductListPaginationState | undefined;
  productListElementRef: MutableRefObject<HTMLDivElement | null>;
  scrollToTopContainerOnLoadProducts?: {
    enable: boolean;
    behavior?: ScrollBehavior;
    target?: 'product-list' | 'window' | HTMLElement;
  };
  changeProductPage: (
    page: number,
    options?:
      | {
          loadProductsBehavior?: LoadProductsBehavior | undefined;
        }
      | undefined,
    currentCollectionSelected?: CurrentCollectionFilterState
  ) => unknown;
  hasShowLimitList?: boolean;
  currentCollectionSelected?: CurrentCollectionFilterState;
}>;

const clsNameMap = createClsNameMap({
  elements: {
    'in-collection-search-empty-message': createClsNameMap(),
  },
})('product-list-pagination');

export const pageLinkPrevIcon =
  '<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.80474 0.528514C6.54439 0.268165 6.12228 0.268165 5.86193 0.528514L0.861929 5.52851C0.601579 5.78886 0.601579 6.21097 0.861929 6.47132L5.86193 11.4713C6.12228 11.7317 6.54439 11.7317 6.80474 11.4713C7.06509 11.211 7.06509 10.7889 6.80474 10.5285L2.27614 5.99992L6.80474 1.47132C7.06509 1.21097 7.06509 0.788864 6.80474 0.528514Z"/></svg>';

export const pageLinkNextIcon =
  '<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 0.528514C0.455612 0.268165 0.877722 0.268165 1.13807 0.528514L6.13807 5.52851C6.39842 5.78886 6.39842 6.21097 6.13807 6.47132L1.13807 11.4713C0.877722 11.7317 0.455612 11.7317 0.195262 11.4713C-0.0650874 11.211 -0.0650874 10.7889 0.195262 10.5285L4.72386 5.99992L0.195262 1.47132C-0.0650874 1.21097 -0.0650874 0.788864 0.195262 0.528514Z"/></svg>';

const ProductListPagination = ({
  enable = true,
  totalProducts,
  paginationState,
  productListElementRef,
  scrollToTopContainerOnLoadProducts = { enable: true, behavior: 'smooth', target: 'product-list' },
  changeProductPage,
  currentCollectionSelected,
  children,
}: ProductListPaginationProps) => {
  const { t } = useTranslation();

  const { pagination } = useAdditionalElementThemeSettings();
  const {
    alignment = 'center',
    button: buttonSettings = {},
    number: numberSettings = {},
    paginationType = 'default',
    productCount = {
      showProductCount: false,
      position: 'top',
    },
  } = pagination;

  const { loadingAdditional, fromProductIndex, toProductIndex } = useAdditionalElementState();

  if (!enable || !paginationState) return <>{children}</>;

  useEffect(() => {
    const page = getQueryParamByKey('page');
    const initialPage = page ? Number(page) : 1;

    if (paginationType !== DEFAULT) {
      setSessionStorage(PREV_PAGE, initialPage);
      setSessionStorage(NEXT_PAGE, initialPage);
    }

    setSessionStorage(INITIAL_PAGE, initialPage);
  }, []);

  const latestActionRef = useRef<'init' | 'load-more' | 'load-prev'>('init');

  if (
    !isSearchPage() && // not show when in no search result
    (!toProductIndex || !fromProductIndex || totalProducts === 0) &&
    !loadingAdditional
  ) {
    if (window.Shopify?.designMode) {
      const svgContent = `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 32C25.3224 32 32.5 24.8224 32.5 16C32.5 7.1776 25.3224 -7.71278e-07 16.5 0C7.6776 7.71279e-07 0.499999 7.1776 0.5 16C0.500001 24.8224 7.6776 32 16.5 32ZM18.1 22.4C18.1 23.2837 17.3837 24 16.5 24C15.6163 24 14.9 23.2837 14.9 22.4V16C14.9 15.1163 15.6163 14.4 16.5 14.4C17.3837 14.4 18.1 15.1163 18.1 16V22.4ZM16.5 8C15.6163 8 14.9 8.71634 14.9 9.6C14.9 10.4837 15.6163 11.2 16.5 11.2C17.3837 11.2 18.1 10.4837 18.1 9.6C18.1 8.71634 17.3837 8 16.5 8Z" fill="#5C5F62"/>
      </svg>
      `;
      return (
        <div
          style={{
            display: 'flex',
            width: '965px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '14px',
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: svgContent }}
            style={{ width: '32px', height: '32px' }}
          />
          <div
            style={{
              display: 'flex',
              height: '48px',
              padding: '0px 16px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '4px',
              alignSelf: 'stretch',
            }}
          >
            <span
              style={{
                color: 'var(--text-default, #202223)',
                textAlign: 'center',
                fontFamily: 'SF Pro Text',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '24px',
              }}
            >
              {t('error.noFilterResultPreview1')}
            </span>
            <span
              style={{
                color: 'var(--text-default, #202223)',
                textAlign: 'center',
                fontFamily: 'SF Pro Text',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              {t('error.noFilterResultPreview2')}
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className={clsNameMap.elm('in-collection-search-empty-message')}>
        <p>
          <em>{t('error.noFilterResult')}</em>
        </p>
      </div>
    );
  }
  const { totalPages, limit: pageSize } = paginationState;

  const couldLoadMore = toProductIndex < totalProducts;
  const couldLoadPrevious = fromProductIndex > 1;

  const handleLoadMore = () => {
    const nextPage = Math.ceil(toProductIndex / pageSize) + 1;
    if (nextPage > totalPages) return;

    latestActionRef.current = 'load-more';
    setSessionStorage(NEXT_PAGE, nextPage);
    return onChangeProductPage(nextPage);
  };

  const handleLoadPrevious = () => {
    const prevPage = Math.ceil(fromProductIndex / pageSize) - 1;
    if (prevPage < 1) return;

    latestActionRef.current = 'load-prev';
    setSessionStorage(PREV_PAGE, prevPage);
    return onChangeProductPage(prevPage);
  };

  const onChangeProductPage = (page: number, collectionSelected?: CurrentCollectionFilterState) => {
    if (!paginationState) return;

    let behavior: LoadProductsBehavior;

    switch (paginationType) {
      case LOAD_MORE:
      case INFINITE:
        behavior = page > paginationState.page ? 'more' : 'previous';
        break;

      default:
        behavior = 'refresh';
    }

    const productListElement = productListElementRef.current;

    if (behavior === 'refresh' && scrollToTopContainerOnLoadProducts.enable && productListElement) {
      let top = 0;
      const { target } = scrollToTopContainerOnLoadProducts;

      if (target === 'product-list') {
        top = productListElement.getBoundingClientRect().top;
      }

      if (target instanceof HTMLElement) {
        top = target.getBoundingClientRect().top;
      }

      window.scrollTo({
        top,
        behavior: scrollToTopContainerOnLoadProducts.behavior,
      });
    }

    return changeProductPage(page, { loadProductsBehavior: behavior }, collectionSelected);
  };

  const renderPrevPaginationButton = () => {
    if (!paginationState || paginationState.page === 1) return;

    return (
      <PaginationButton
        modifiers={{
          prev: true,
        }}
        onClick={handleLoadPrevious}
        icon={pageLinkPrevIcon}
        iconPosition='left'
        text={t('pagination.prevText')}
        {...buttonSettings}
      />
    );
  };

  const renderNextPaginationButton = () => {
    if (!paginationState || paginationState.page === paginationState.totalPages) return;

    return (
      <PaginationButton
        modifiers={{
          next: true,
        }}
        icon={pageLinkNextIcon}
        onClick={handleLoadMore}
        iconPosition='right'
        text={t('pagination.nextText')}
        {...buttonSettings}
      />
    );
  };

  const renderProductCount = () => {
    if (!productCount.showProductCount || totalProducts <= pageSize) return <></>;

    return (
      <ProductCount
        locatedIn='Pagination'
        totalProducts={totalProducts}
        fromProductIndex={fromProductIndex}
        toProductIndex={toProductIndex}
        textAlign={alignment}
      />
    );
  };

  switch (paginationType) {
    case LOAD_MORE: {
      return (
        <PaginationLoadMore
          totalPages={paginationState.totalPages}
          productCount={renderProductCount()}
          productCountPosition={productCount.position}
          alignmentModifier={[alignment]}
          loadPreviousButton={
            couldLoadPrevious ? (
              <PaginationButton
                onClick={handleLoadPrevious}
                iconPosition='left'
                text={t('pagination.loadPreviousText')}
                paginationType={paginationType}
                modifiers={{
                  'load-more': true,
                }}
                {...buttonSettings}
              />
            ) : undefined
          }
          loadMoreButton={
            couldLoadMore ? (
              <PaginationButton
                onClick={handleLoadMore}
                iconPosition='left'
                text={t('pagination.loadMoreText')}
                paginationType={paginationType}
                modifiers={{
                  'load-more': true,
                }}
                {...buttonSettings}
              />
            ) : undefined
          }
        >
          {children}
        </PaginationLoadMore>
      );
    }

    case INFINITE: {
      return (
        <PaginationInfiniteScroll
          totalPage={paginationState.totalPages}
          onChangePage={onChangeProductPage}
          currentCollectionSelected={currentCollectionSelected}
          productCount={renderProductCount()}
          loadPreviousButton={
            couldLoadPrevious ? (
              <PaginationButton
                onClick={handleLoadPrevious}
                iconPosition='left'
                text={t('pagination.loadPreviousInfiniteText')}
                paginationType={paginationType}
                modifiers={{ 'load-more': true }}
                {...buttonSettings}
              />
            ) : undefined
          }
        >
          {children}
        </PaginationInfiniteScroll>
      );
    }

    default: {
      return (
        <>
          {children}
          {productCount.position === 'top' && renderProductCount()}
          <PaginationPageLink
            totalPages={paginationState.totalPages}
            currentPage={paginationState.page}
            onChangePage={onChangeProductPage}
            prevButton={renderPrevPaginationButton()}
            nextButton={renderNextPaginationButton()}
            alignmentModifier={[alignment]}
            {...numberSettings}
          />
          {productCount.position === 'bottom' && renderProductCount()}
        </>
      );
    }
  }
};

export default registryComponent('ProductListPagination', ProductListPagination);
